<div class="primary-column" [class.mobile]="isMobile()">
  <div class="primary-content">
    <div class="header">
      <button class="btn-horizontal-expander size-40 size-40-expander" aria-label="Expand" (click)="goBack()">
        <mat-icon class="expanded">navigate_next</mat-icon>
      </button>
      <span class="spotlight-blue title" translate>Instruction.DeliverToAlternateAddress</span>
    </div>

    <form [formGroup]="form">
      <div class="container">
        <div class="alternate-address-form">
          <!-- Name1 -->
          <div class="form-group" [class.mobile]="isMobile()" [class.is-invalid]="isInvalidControl('name1')">
            <div class="input form-field-icon-holder form-control_icon-info invalid-value-missing">
              <mat-form-field [floatLabel]="'always'" class="input">
                <mat-label>{{ 'Instruction.AlternateAddress.Name' | translate }}</mat-label>
                <input matInput id="name1" type="text" formControlName="name1" class="form-control" maxlength="30" />
              </mat-form-field>
            </div>
            <ng-container *ngIf="isInvalidControl('name1')">
              <div
                *ngIf="isControlError('name1', 'required')"
                class="feedback invalid-value-missing"
                [translateParams]="{ fieldName: ('Instruction.AlternateAddress.Name' | translate) }"
                translate
              >
                Validations.Required.Field
              </div>
            </ng-container>
          </div>

          <!-- Name2 -->
          <div class="form-group" [class.mobile]="isMobile()">
            <div class="input form-field-icon-holder form-control_icon-info invalid-value-missing">
              <mat-form-field [floatLabel]="'always'" class="input">
                <mat-label>{{ 'Instruction.AlternateAddress.Name2' | translate }}</mat-label>
                <input matInput id="name2" type="text" formControlName="name2" class="form-control" maxlength="30" />
              </mat-form-field>
            </div>
          </div>

          <!-- Contact person -->
          <div class="form-group" [class.mobile]="isMobile()">
            <div class="input form-field-icon-holder form-control_icon-info invalid-value-missing">
              <mat-form-field [floatLabel]="'always'" class="input">
                <mat-label>{{ 'Instruction.AlternateAddress.ContactPerson' | translate }}</mat-label>
                <input
                  matInput
                  id="contactPerson"
                  type="text"
                  formControlName="contact"
                  class="form-control"
                  maxlength="30"
                />
              </mat-form-field>
            </div>
          </div>

          <!-- Email -->
          <div class="form-group" [class.mobile]="isMobile()" [class.is-invalid]="isInvalidControl('email')">
            <div class="input form-field-icon-holder form-control_icon-info invalid-value-missing">
              <mat-form-field [floatLabel]="'always'" class="input">
                <mat-label>{{ 'Input.Email' | translate }}</mat-label>
                <input
                  matInput
                  id="email"
                  type="text"
                  formControlName="email"
                  class="form-control"
                  autocomplete="email"
                  maxlength="320"
                />
              </mat-form-field>
            </div>
            <ng-container *ngIf="isInvalidControl('email')">
              <div
                *ngIf="isControlError('email', 'required')"
                class="feedback invalid-value-missing"
                [translateParams]="{ fieldName: ('Input.Email' | translate) }"
                translate
              >
                Validations.Required.Field
              </div>
              <div *ngIf="isControlError('email', 'pattern')" class="feedback invalid-value-missing" translate>
                Validations.Required.EmailPattern
              </div>
            </ng-container>
          </div>

          <!-- Phone -->
          <div class="form-group" [class.mobile]="isMobile()" [class.is-invalid]="isInvalidControl('phoneNumber')">
            <div class="input form-field-icon-holder form-control_icon-info invalid-value-missing">
              <mat-form-field [floatLabel]="'always'" class="input">
                <mat-label>{{ 'Input.PhoneNumber' | translate }}</mat-label>
                <input
                  matInput
                  id="phoneNumber"
                  type="text"
                  formControlName="phoneNumber"
                  class="form-control"
                  autocomplete="phoneNumber"
                  maxlength="25"
                />
              </mat-form-field>
            </div>
            <ng-container *ngIf="isInvalidControl('phoneNumber')">
              <div *ngIf="isControlError('phoneNumber', 'pattern')" class="feedback invalid-value-missing" translate>
                Validations.Required.PhoneNumberPattern
              </div>
            </ng-container>
          </div>

          <!-- Zip code -->
          <div
            class="form-group"
            [class.mobile]="isMobile()"
            [class.is-invalid]="isInvalidControl('zipCode') || isPostOfficeBox"
          >
            <div class="input form-field-icon-holder form-control_icon-info invalid-value-missing">
              <mat-form-field [floatLabel]="'always'" class="input">
                <mat-label>{{ 'Instruction.AlternateAddress.ZipCode' | translate }}</mat-label>
                <input
                  matInput
                  id="zipCode"
                  type="text"
                  formControlName="zipCode"
                  class="form-control"
                  (keyup)="onAddressChanged()"
                  maxlength="10"
                />
              </mat-form-field>
            </div>
            <ng-container *ngIf="isInvalidControl('zipCode') || isPostOfficeBox">
              <div
                *ngIf="isControlError('zipCode', 'required')"
                class="feedback invalid-value-missing"
                [translateParams]="{ fieldName: ('Instruction.AlternateAddress.ZipCode' | translate) }"
                translate
              >
                Validations.Required.Field
              </div>
              <div *ngIf="isPostOfficeBox" class="feedback invalid-value-missing" translate>
                Validations.Forbidden.PostalOffice
              </div>
            </ng-container>
          </div>

          <!-- House number -->
          <div class="form-group" [class.mobile]="isMobile()" [class.is-invalid]="isInvalidControl('houseNo')">
            <div class="input form-field-icon-holder form-control_icon-info invalid-value-missing">
              <mat-form-field [floatLabel]="'always'" class="input">
                <mat-label>{{ 'Instruction.AlternateAddress.HouseNumber' | translate }}</mat-label>
                <input
                  matInput
                  id="houseNo"
                  type="text"
                  formControlName="houseNo"
                  class="form-control"
                  (keyup)="onAddressChanged()"
                  maxlength="5"
                />
              </mat-form-field>
            </div>
            <ng-container *ngIf="isInvalidControl('houseNo')">
              <div
                *ngIf="isControlError('houseNo', 'required')"
                class="feedback invalid-value-missing"
                [translateParams]="{
                  fieldName: ('Instruction.AlternateAddress.HouseNumber' | translate)
                }"
                translate
              >
                Validations.Required.Field
              </div>
            </ng-container>
            <ng-container *ngIf="isInvalidAddress">
              <div class="feedback invalid-value-missing" translate>Validations.Address.Unknown</div>
            </ng-container>
          </div>

          <!-- House number addition -->
          <div class="form-group" [class.mobile]="isMobile()">
            <div class="input form-field-icon-holder form-control_icon-info invalid-value-missing">
              <mat-form-field [floatLabel]="'always'" class="input">
                <mat-label>{{ 'Instruction.AlternateAddress.HouseNumberAddition' | translate }}</mat-label>
                <input
                  matInput
                  id="houseNoAdd"
                  type="text"
                  formControlName="houseNoAdd"
                  class="form-control"
                  maxlength="5"
                />
              </mat-form-field>
            </div>
          </div>

          <!-- Street -->
          <div class="form-group" [class.mobile]="isMobile()">
            <label class="label" for="street">{{ 'Instruction.AlternateAddress.Street' | translate }}</label>
            <div>{{ street }}</div>
          </div>

          <!-- City -->
          <div class="form-group" [class.mobile]="isMobile()">
            <label class="label" for="city">{{ 'Instruction.AlternateAddress.City' | translate }}</label>
            <div>{{ city }}</div>
          </div>

          <!-- Date -->
          <div class="form-group" [class.mobile]="isMobile()" [class.is-invalid]="isInvalidControl('name1')">
            <label class="label" for="name1">{{ 'Instruction.AlternateAddress.DeliveryDate' | translate }}</label>
            <div>
              <div class="input form-field-icon-holder form-control_icon-info invalid-value-missing">
                <mat-select formControlName="date" class="combo">
                  <mat-option *ngFor="let date of availableDates" [value]="date">
                    {{ renderFullDate(date) }}
                  </mat-option>
                </mat-select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="primary-footer" [class.mobile]="isMobile()">
    <button mat-button class="btn btn-primary fullwidth" (click)="confirm()">
      {{ 'Instruction.ContinueWithNextStep' | translate }}
    </button>
  </div>
</div>
