var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __rest = this && this.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Geo } from "@aws-amplify/geo";
import MaplibreGeocoder from "@maplibre/maplibre-gl-geocoder";
import maplibregl from "maplibre-gl";
import { createDefaultIcon } from "./createDefaultIcon";
export const AmplifyGeocoderAPI = {
  forwardGeocode: config => __awaiter(void 0, void 0, void 0, function* () {
    const features = [];
    try {
      const data = yield Geo.searchByText(config.query, {
        biasPosition: config.bbox ? undefined : config.proximity,
        searchAreaConstraints: config.bbox,
        countries: config.countries,
        maxResults: config.limit
      });
      if (data) {
        data.forEach(result => {
          const {
              geometry
            } = result,
            otherResults = __rest(result, ["geometry"]);
          features.push({
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: geometry.point
            },
            properties: Object.assign({}, otherResults),
            place_name: otherResults.label,
            text: otherResults.label,
            center: geometry.point
          });
        });
      }
    } catch (e) {
      console.error(`Failed to forwardGeocode with error: ${e}`);
    }
    return {
      features
    };
  }),
  reverseGeocode: config => __awaiter(void 0, void 0, void 0, function* () {
    const features = [];
    try {
      const data = yield Geo.searchByCoordinates(config.query, {
        maxResults: config.limit
      });
      if (data && data.geometry) {
        const {
            geometry
          } = data,
          otherResults = __rest(data, ["geometry"]);
        features.push({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: geometry.point
          },
          properties: Object.assign({}, otherResults),
          place_name: otherResults.label,
          text: otherResults.label,
          center: geometry.point
        });
      }
    } catch (e) {
      console.error(`Failed to reverseGeocode with error: ${e}`);
    }
    return {
      features
    };
  }),
  getSuggestions: config => __awaiter(void 0, void 0, void 0, function* () {
    const suggestions = [];
    try {
      const response = yield Geo.searchForSuggestions(config.query, {
        biasPosition: config.proximity,
        searchAreaConstraints: config.bbox,
        countries: config.countries,
        maxResults: config.limit
      });
      suggestions.push(...response);
    } catch (e) {
      console.error(`Failed to get suggestions with error: ${e}`);
    }
    return {
      suggestions
    };
  }),
  searchByPlaceId: config => __awaiter(void 0, void 0, void 0, function* () {
    let feature = undefined;
    try {
      const place = yield Geo.searchByPlaceId(config.query);
      if (place) {
        const {
            geometry
          } = place,
          otherResults = __rest(place, ["geometry"]);
        feature = {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: geometry.point
          },
          properties: Object.assign({}, otherResults),
          place_name: otherResults.label,
          text: otherResults.label,
          center: geometry.point
        };
      }
      ;
    } catch (e) {
      console.error(`Failed to get place with error: ${e}`);
    }
    return {
      place: feature
    };
  })
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createAmplifyGeocoder(options) {
  return new MaplibreGeocoder(AmplifyGeocoderAPI, Object.assign({
    maplibregl: maplibregl,
    showResultMarkers: {
      element: createDefaultIcon()
    },
    marker: {
      element: createDefaultIcon()
    },
    // autocomplete temporarily disabled by default until CLI is updated
    showResultsWhileTyping: options === null || options === void 0 ? void 0 : options.autocomplete
  }, options));
}