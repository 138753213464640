export const Translations_nl = {
  Common: {
    Translate: 'Common vertaald',
    Feedback: 'Feedback',
  },
  Languages: {
    nl: {
      a2: 'NL',
      full: 'Nederlands',
    },
    en: {
      a2: 'EN',
      full: 'Engels',
    },
  },
};
