import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { returnToSearchParcelWidget } from '../routing/routing.utils';
import { Router } from '@angular/router';;

@Component({
  selector: 'app-gls-selector',
  templateUrl: './gls-selector.component.html',
  styleUrl: './gls-selector.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlsSelectorComponent {
  constructor(private readonly store: Store, private readonly router: Router) {}

  returnToSearch = () => returnToSearchParcelWidget(this.store, this.router);
}
