import { ChangeDetectionStrategy, Component, Signal } from '@angular/core';
import { WindowResizeService } from '../../services/window-resize.service';
import { WindowDimension } from '../../models/window.model';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-windowsize',
  templateUrl: './windowsize.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WindowsizeComponent {
  dimension: Signal<WindowDimension | undefined>;

  constructor(private windowResizeService: WindowResizeService) {
    this.dimension = toSignal(this.windowResizeService.dimensions$);
  }
}
