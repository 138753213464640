import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component, computed, signal, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from '../../../models/constants';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReCaptchaV3Service } from 'ng-recaptcha-2';
import { AddressFormSettings } from '../../../services/addressFormsSettings';
import { InstructionClientService } from '../../../clients/instruction.client';
import { ActionType, InstructionType, ValidationResultCode } from '../../../models/gls-info.model';
import { BaseTrackAndTraceComponent } from '../../track-and-trace/summary/base-track-and-trace.component';
import { getCaptchaTokenForParcelInfo, getLocale } from '../../../services/spotlight.utils';
import { Store } from '@ngxs/store';
import {
  GetParcelDetailsAction,
  SetInstructionValidationRequestAction,
  SetInstructionValidationResultAction,
} from '../../../state/app.actions';
import { WidgetType } from '../../../state/app.model';

@Component({
  selector: 'app-instruction-aci-enter-email',
  templateUrl: './instruction-aci-enter-email.component.html',
  styleUrls: ['./instruction-aci-enter-email.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InstructionAciEnterEmailComponent extends BaseTrackAndTraceComponent {
  form: FormGroup;
  notFound = signal(false);

  breakpoint = toSignal(this.breakpointObserver.observe([Constants.BreakPointMobile]));

  isMobile: Signal<boolean> = computed(() => this.breakpoint()?.matches ?? false);

  get placeholderEmail(): string {
    return this.translateService.instant('Instruction.Email');
  }

  constructor(
    store: Store,
    public readonly fb: FormBuilder,
    private readonly translateService: TranslateService,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly recaptchaService: ReCaptchaV3Service,
    private readonly instructionClient: InstructionClientService
  ) {
    super(store);
    this.form = this.fb.group({
      email: [
        '',
        [
          Validators.minLength(4),
          Validators.maxLength(AddressFormSettings.Email.MaxLength),
          Validators.required,
          Validators.pattern(AddressFormSettings.Email.Regex),
        ],
      ],
    });
  }

  isInvalidControl = (name: string): boolean => {
    const control = this.form.controls[name];
    return control.invalid && (control.dirty || control.touched);
  };

  isControlError = (name: string, error: string): boolean => {
    const control = this.form.controls[name];
    return control.errors && control.errors[error];
  };

  async onSubmit() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.notFound.set(false);
    const captcha = (await getCaptchaTokenForParcelInfo(this.recaptchaService)) ?? '';

    const result = await this.instructionClient.validateAci(
      {
        parcelNumber: this.instructionValidateRequest()?.code ?? '',
        email: this.form.controls['email'].value,
      },
      captcha
    );

    if (!result || result?.resultCode !== ValidationResultCode.Valid) {
      this.notFound.set(true);
      return;
    }
    const request = {
      validateEntireShipment: false,
      email: this.form.controls['email'].value,
      action: ActionType.Instruction,
      instructionType: InstructionType.ACI,
      code: result?.parcels[0]?.nlpclNo ?? '',
      date: '',
      custNo: '',
      zipCode: result?.leadingAddress?.zipCode ?? '',
      shipment: '',
      depotNumber: '',
      username: '',
      userType: '',
      reference: '',
      checksum: '',
      csiData: '',
      iddeeplink: '',
    };

    this.store.dispatch(new SetInstructionValidationRequestAction(request));
    this.store.dispatch(new SetInstructionValidationResultAction(result));
    const culture = getLocale(this.translateService);
    const newCaptcha = (await getCaptchaTokenForParcelInfo(this.recaptchaService)) ?? '';
    this.store.dispatch(
      new GetParcelDetailsAction(
        WidgetType.InstructionSelectDeliveryOption,
        request.code,
        request.zipCode,
        culture,
        newCaptcha
      )
    );
  }
}
