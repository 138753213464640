<ul class="properties">
  <li>
    <div class="address-blok">
      <app-address-component
        label="Search.Result.Sender"
        [address]="sender"
      ></app-address-component>
      <app-address-component
        label="Search.Result.DeliveryAt"
        [address]="deliveryAddress"
      ></app-address-component>
    </div>
  </li>
</ul>
