import { ActionTypes } from '../models/gls-info.model';
import { InstructionValidationResponse } from '../models/instruction-validation-response';
import { AllowedOptionType } from '../state/app.model';

export const isAllowedOption = (option: AllowedOptionType, options?: AllowedOptionType[]): boolean =>
  (options ?? []).reduce((acc, item) => {
    if (item === option) {
      return true;
    }
    return acc;
  }, false);

export const getOptions = (instructionValidationResult?: InstructionValidationResponse): AllowedOptionType[] => {
  let result: AllowedOptionType[] = [];

  const canDeliverToAplOption = canDeliverToApl(instructionValidationResult);
  const canDeliverToParcelShopOption = canDeliverToParcelShop(instructionValidationResult);
  const canDeliverToDepotOption = canDeliverToDepot(instructionValidationResult);
  const canDeliverToCollectionPointOption =
    canDeliverToAplOption || canDeliverToParcelShopOption || canDeliverToDepotOption;
  const canRefuseParcelOption = canRefuseParcel(instructionValidationResult);
  const canDeliverWithDepositPermissionOption = canDeliverWithDepositPermission(instructionValidationResult);
  const canDeliverAtOtherMomentOption = canDeliverAtOtherMoment(instructionValidationResult);
  const canDeliverAtNeighboursOption = canDeliverAtNeighbours(instructionValidationResult);
  const canDeliverToAlternateAddressOption = canDeliverToAlternateAddress(instructionValidationResult);

  if (canDeliverToCollectionPointOption) {
    result = [...result, AllowedOptionType.canDeliverToCollectionPoint];
  }
  if (canRefuseParcelOption) {
    result = [...result, AllowedOptionType.canRefuseParcel];
  }
  if (canDeliverWithDepositPermissionOption) {
    result = [...result, AllowedOptionType.canDeliverWithDepositPermission];
  }
  if (canDeliverAtOtherMomentOption) {
    result = [...result, AllowedOptionType.canDeliverAtOtherMoment];
  }
  if (canDeliverAtNeighboursOption) {
    result = [...result, AllowedOptionType.canDeliverAtNeighbours];
  }
  if (canDeliverToAlternateAddressOption) {
    result = [...result, AllowedOptionType.canDeliverToAlternateAddress];
  }
  return result;
};

function canDeliverToApl(instructionValidateResult?: InstructionValidationResponse) {
  const allowedActions: ActionTypes | number = instructionValidateResult?.allowedActions ?? 0;
  return (allowedActions & ActionTypes.CollectAtApl) === ActionTypes.CollectAtApl;
}

function canDeliverToParcelShop(instructionValidateResult?: InstructionValidationResponse) {
  const allowedActions: ActionTypes | number = instructionValidateResult?.allowedActions ?? 0;
  return (allowedActions & ActionTypes.CollectAtParcelShop) === ActionTypes.CollectAtParcelShop;
}

function canDeliverToDepot(instructionValidateResult?: InstructionValidationResponse) {
  const allowedActions: ActionTypes | number = instructionValidateResult?.allowedActions ?? 0;
  return (allowedActions & ActionTypes.CollectAtDepot) === ActionTypes.CollectAtDepot;
}

function canRefuseParcel(instructionValidateResult?: InstructionValidationResponse) {
  const allowedActions: ActionTypes | number = instructionValidateResult?.allowedActions ?? 0;
  return (allowedActions & ActionTypes.RefusePackage) === ActionTypes.RefusePackage;
}

function canDeliverWithDepositPermission(instructionValidateResult?: InstructionValidationResponse) {
  const allowedActions: ActionTypes | number = instructionValidateResult?.allowedActions ?? 0;
  return (
    (allowedActions & ActionTypes.RetryDeliveryWithDepositPermission) === ActionTypes.RetryDeliveryWithDepositPermission
  );
}

function canDeliverAtOtherMoment(instructionValidateResult?: InstructionValidationResponse) {
  const allowedActions: ActionTypes | number = instructionValidateResult?.allowedActions ?? 0;
  return (allowedActions & ActionTypes.RetryDelivery) === ActionTypes.RetryDelivery;
}

function canDeliverAtNeighbours(instructionValidateResult?: InstructionValidationResponse) {
  const allowedActions: ActionTypes | number = instructionValidateResult?.allowedActions ?? 0;
  return (allowedActions & ActionTypes.DeliverAtNeighbours) === ActionTypes.DeliverAtNeighbours;
}

function canDeliverToAlternateAddress(instructionValidateResult?: InstructionValidationResponse) {
  const allowedActions: ActionTypes | number = instructionValidateResult?.allowedActions ?? 0;
  return (allowedActions & ActionTypes.DeliverToAlternateAddress) == ActionTypes.DeliverToAlternateAddress;
}
