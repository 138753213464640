<div class="background">
  <app-spotlight></app-spotlight>
  <app-toolbar-widget></app-toolbar-widget>
  <ng-container *ngIf="!isMobile()">
    <app-main-widget></app-main-widget>
  </ng-container>
  <ng-container *ngIf="isMobile()">
    <app-bottom-sheet></app-bottom-sheet>
  </ng-container>
</div>
