import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RecaptchaV3Module } from 'ng-recaptcha-2';
import { AdvertisementComponent } from './advertisement.component';
import { BottomSheetComponent } from './components/bottom-sheet/bottom-sheet.component';
import { MainWidgetComponent } from './widgets/main-widget/main-widget.component';
import { ToolbarWidgetComponent } from './widgets/toolbar-widget/toolbar-widget.component';
import { HomeComponent } from './components/home/home.component';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { importProvidersFrom } from '@angular/core';
import { provideRouter, UrlSerializer } from '@angular/router';
import { appRoutes, LowerCaseUrlSerializer } from './app.routes';
import { LOCALE_ID } from '@angular/core';
import { provideHttpClient } from '@angular/common/http';
import { VERSION } from '../app/environments/version';
import { HttpClient } from '@angular/common/http';
import {
  TranslateLoader,
  TranslateService,
  TranslateStore,
  TranslateParser,
  TranslateDefaultParser,
  TranslateCompiler,
  TranslateFakeCompiler,
  MissingTranslationHandler,
  FakeMissingTranslationHandler,
  USE_DEFAULT_LANG,
  USE_STORE,
  USE_EXTEND,
  DEFAULT_LANGUAGE,
  TranslateModule,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LanguageModule, LanguageSettings } from '@nx/language';
import { NgxsModule } from '@ngxs/store';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { AppState } from './state/app.state';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { RECAPTCHA_SETTINGS, RECAPTCHA_V3_SITE_KEY, RecaptchaSettings } from 'ng-recaptcha-2';
import { environment } from './environments/environment';

import { registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';
import localeEnGb from '@angular/common/locales/en-GB';
import { AwsMapsModule } from '@root/libs/aws-maps/src';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { GlsSelectorComponent } from './components/gls-selector/gls-selector.component';
import { InstructionAciConfirmWidgetComponent } from './components/instructions/instruction-aci-confirm-widget/instruction-aci-confirm.component';
import { InstructionAciEnterEmailComponent } from './components/instructions/instruction-aci-enter-email/instruction-aci-enter-email.component';
import { InstructionAlternateAddressComponent } from './components/instructions/instruction-alternate-address/instruction-alternate-address.component';
import { InstructionCollectionpointComponent } from './components/instructions/instruction-collectionpoint/instruction-collectionpoint.component';
import { InstructionSelectCollectionpointComponent } from './components/instructions/instruction-collectionpoint/instruction-select-collectionpoint/instruction-select-collectionpoint.component';
import { InstructionConfirmComponent } from './components/instructions/instruction-confirm/instruction-confirm.component';
import { InstructionDeliveryOptionsComponent } from './components/instructions/instruction-delivery-options/instruction-delivery-options.component';
import { InstructionDepositpermissionComponent } from './components/instructions/instruction-depositpermission/instruction-depositpermission.component';
import { InstructionEnterZipcodeComponent } from './components/instructions/instruction-enter-zipcode/instruction-enter-zipcode.component';
import { InstructionRefuseComponent } from './components/instructions/instruction-refuse/instruction-refuse.component';
import { InstructionResultComponent } from './components/instructions/instruction-result/instruction-result.component';
import { InstructionValidationFailedWidgetComponent } from './components/instructions/instruction-validation-failed-widget/instruction-validation-failed-widget.component';
import { WebnotificationDetailsComponent } from './components/notifications/webnotification-details.component';
import { WebnotificationComponent } from './components/notifications/webnotification.component';
import { RouteTrackAndTraceComponent } from './components/routing/route-track-and-trace.component';
import { ContextDeliveredComponent } from './components/track-and-trace/context/delivered/context-delivered.component';
import { ContextInTransitComponent } from './components/track-and-trace/context/in-transit/context-in-transit.component';
import { ContextNotDeliveredComponent } from './components/track-and-trace/context/not-delivered/context-not-delivered.component';
import { ContextReturnedComponent } from './components/track-and-trace/context/returned/context-returned/context-returned.component';
import { SearchParcelResultComponent } from './components/track-and-trace/search-parcel-result.component';
import { SearchParcelComponent } from './components/track-and-trace/search-parcel.component';
import { SecundaryColumnComponent } from './components/track-and-trace/secundary-column.component';
import { SkeletonParcelSearchComponent } from './components/track-and-trace/skeleton/skeleton-parcel-search/skeleton-parcel-search.component';
import { AddressBlockComponent } from './components/track-and-trace/summary/address/address-block.component';
import { AddressComponentComponent } from './components/track-and-trace/summary/address/address-component.component';
import { ParcelCollectionpointComponent } from './components/track-and-trace/summary/collection-point/parcel-collection-point.component';
import { DeliveryInfoComponent } from './components/track-and-trace/summary/delivery-info/delivery-info.component';
import { DeliveryNoteComponent } from './components/track-and-trace/summary/delivery-info/delivery-note.component';
import { DeliverySustainableComponent } from './components/track-and-trace/summary/delivery-info/delivery-sustainable.component';
import { DepositLocationComponent } from './components/track-and-trace/summary/deposit-location/deposit-location.component';
import { TrackAndTraceDetailsComponent } from './components/track-and-trace/summary/details/track-and-trace-details.component';
import { TrackAndTracePropertyComponent } from './components/track-and-trace/summary/details/track-and-trace-property.component';
import { DeliveryDateComponent } from './components/track-and-trace/summary/eta/delivery-date.component';
import { EtaLiveTrackingMapComponent } from './components/track-and-trace/summary/eta/eta-live-tracking-map.component';
import { TrackAndTraceTitleComponent } from './components/track-and-trace/summary/track-and-trace-title.component';
import { EtaStopsLeftComponent } from './components/track-and-trace/summary/eta/eta-stops-left.component';
import { ParcelProgressComponent } from './components/track-and-trace/summary/progress/parcel-progress/parcel-progress.component';
import { ScanDetailsComponentComponent } from './components/track-and-trace/summary/scans/scan-details-component.component';
import { ScansComponentComponent } from './components/track-and-trace/summary/scans/scans-component.component';
import { TraceAndTraceParcelnoComponent } from './components/track-and-trace/summary/titles/trace-and-trace-parcelno.component';
import { TrackAndTraceDateTitleComponent } from './components/track-and-trace/summary/titles/track-and-trace-date-title.component';
import { TrackAndTraceStatusTitleComponent } from './components/track-and-trace/summary/titles/track-and-trace-status-title.component';
import { WindowsizeComponent } from './components/window/windowsize.component';
import { MainWidgetContentComponent } from './widgets/main-widget/content/main-widget-content.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { BrowserModule } from '@angular/platform-browser';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { InstructionDeliveryOtherTimeComponent } from './components/instructions/instruction-deliver-other-moment/instruction-deliver-other-moment.component';
import { InstructionDeliveryAtNeighbours } from './components/instructions/instruction-deliver-at-neigbours/instruction-deliver-at-neigbours.component';
import { OtherParcelsInShipmentsComponent } from './components/track-and-trace/summary/other-parcels-in-shipment/other-parcels-in-shipment.component';
import { ShopLocatorComponent } from './components/shop-locator/shop-locator.component';
import { InstructionOptionsComponent } from './components/instructions/instruction-options/instruction-options.component';
registerLocaleData(localeNl);
registerLocaleData(localeEnGb);

export function appInitializerFactory(translate: TranslateService) {
  return () => translate.use('nl').toPromise(); // Change 'nl-NL' to your default language if needed
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?v=' + VERSION.hash);
}
@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AwsMapsModule,
    MatCheckboxModule,
    MatIconModule,
    MatSelectModule,
    MatButtonModule,
    MatMenuModule,
    MatDialogModule,
    MatSnackBarModule,
    LayoutModule,
    CommonModule,
    RecaptchaV3Module,
    RouterModule,
    TranslateModule,
    LanguageModule,
    MatFormFieldModule,
    MatInputModule,
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
  ],
  declarations: [
    AppComponent,
    HomeComponent,
    AdvertisementComponent,
    MainWidgetComponent,
    ToolbarWidgetComponent,
    BottomSheetComponent,
    AdvertisementComponent,
    GlsSelectorComponent,
    InstructionAciConfirmWidgetComponent,
    InstructionAciEnterEmailComponent,
    InstructionAlternateAddressComponent,
    InstructionCollectionpointComponent,
    InstructionSelectCollectionpointComponent,
    InstructionConfirmComponent,
    InstructionDeliveryOptionsComponent,
    InstructionOptionsComponent,
    InstructionDepositpermissionComponent,
    InstructionEnterZipcodeComponent,
    InstructionDeliveryOtherTimeComponent,
    InstructionRefuseComponent,
    InstructionResultComponent,
    InstructionValidationFailedWidgetComponent,
    WebnotificationDetailsComponent,
    WebnotificationComponent,
    RouteTrackAndTraceComponent,
    SearchParcelResultComponent,
    SearchParcelComponent,
    SecundaryColumnComponent,
    ContextDeliveredComponent,
    ContextInTransitComponent,
    ContextNotDeliveredComponent,
    ContextReturnedComponent,
    SkeletonParcelSearchComponent,
    TrackAndTraceTitleComponent,
    AddressBlockComponent,
    AddressComponentComponent,
    ParcelCollectionpointComponent,
    DeliveryInfoComponent,
    DeliveryNoteComponent,
    DeliverySustainableComponent,
    DepositLocationComponent,
    TrackAndTraceDetailsComponent,
    TrackAndTracePropertyComponent,
    DeliveryDateComponent,
    EtaLiveTrackingMapComponent,
    EtaStopsLeftComponent,
    ParcelProgressComponent,
    ScanDetailsComponentComponent,
    ScansComponentComponent,
    TraceAndTraceParcelnoComponent,
    TrackAndTraceDateTitleComponent,
    TrackAndTraceStatusTitleComponent,
    WindowsizeComponent,
    MainWidgetContentComponent,
    InstructionDeliveryAtNeighbours,
    OtherParcelsInShipmentsComponent,
    ShopLocatorComponent,
  ],
  providers: [
    provideRouter(appRoutes),
    importProvidersFrom(NgxsModule.forRoot([AppState])),
    provideHttpClient(),
    {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService],
      multi: true,
    },
    LanguageSettings,
    { provide: LOCALE_ID, useValue: 'nl' },
    TranslateService,
    TranslateStore,
    {
      provide: TranslateParser,
      useClass: TranslateDefaultParser,
    },
    {
      provide: TranslateCompiler,
      useClass: TranslateFakeCompiler,
    },
    {
      provide: MissingTranslationHandler,
      useClass: FakeMissingTranslationHandler,
    },
    {
      provide: USE_DEFAULT_LANG,
      useValue: true,
    },
    {
      provide: USE_STORE,
      useValue: true,
    },
    {
      provide: USE_EXTEND,
      useValue: true,
    },
    {
      provide: DEFAULT_LANGUAGE,
      useValue: 'nl',
    },
    provideAnimationsAsync(),
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptcha.siteKey },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptcha.siteKey,
      } as RecaptchaSettings,
    },
    {
      provide: UrlSerializer,
      useClass: LowerCaseUrlSerializer,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class SpotLightGlsInfoModule {}
