import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseTrackAndTraceComponent } from '../base-track-and-trace.component';

@Component({
  selector: 'app-delivery-info',
  templateUrl: './delivery-info.component.html',
  styleUrl: './delivery-info.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveryInfoComponent extends BaseTrackAndTraceComponent {
}
