<div class="icon">
  <mat-icon>info</mat-icon>
</div>
<div class="title">{{ title }}</div>
<div class="content">
  <div [innerHTML]="html"></div>
</div>
<div class="footer">
  <button
    mat-button
    class="btn btn-primary"
    [mat-dialog-close]="true"
  >
    <span translate>Notifications.Close</span>
  </button>
</div>
