import { Component } from '@angular/core';
import { CookiebotService } from './services/cookiebot.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'gls-spotlight';

  constructor(public cookiebotService: CookiebotService) {}
}
