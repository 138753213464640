import { Location } from '@angular/common';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component, computed, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { Constants } from '../../../models/constants';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
import { LinkManagerService } from '../../../services/linkmanager.service';
import { ReCaptchaV3Service } from 'ng-recaptcha-2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-instruction-enter-zipcode',
  templateUrl: './instruction-enter-zipcode.component.html',
  styleUrls: ['./instruction-enter-zipcode.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InstructionEnterZipcodeComponent {
  CodeRegex = '[a-zA-Z0-9]+'; // do not allow white spaces or other characters
  form: FormGroup;

  breakpoint = toSignal(this.breakpointObserver.observe([Constants.BreakPointMobile]));

  isMobile: Signal<boolean> = computed(() => this.breakpoint()?.matches ?? false);

  get placeholderZipCode(): string {
    return this.translateService.instant('Instruction.ZipCode');
  }

  constructor(
    public readonly fb: FormBuilder,
    private readonly route: ActivatedRoute,
    private readonly translateService: TranslateService,
    private readonly breakpointObserver: BreakpointObserver,
    private location: Location,
    private readonly linkManager: LinkManagerService,
    private readonly recaptchaService: ReCaptchaV3Service
  ) {
    this.form = this.fb.group({
      zipCode: [
        '',
        [Validators.minLength(4), Validators.maxLength(10), Validators.required, Validators.pattern(this.CodeRegex)],
      ],
    });
  }

  isInvalidControl = (name: string): boolean => {
    const control = this.form.controls[name];
    return control.invalid && (control.dirty || control.touched);
  };

  isControlError = (name: string, error: string): boolean => {
    const control = this.form.controls[name];
    return control.errors && control.errors[error];
  };

  async onSubmit() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    const params = new HttpParams({ fromObject: this.route.snapshot.queryParams }).appendAll({
      zipcode: this.form.controls['zipCode'].value,
    });

    this.location.replaceState(location.pathname, params.toString());
    await this.linkManager.processDeepLinksByHttpParams(params, this.recaptchaService);
  }
}
