import { ChangeDetectionStrategy, Component, computed, Signal } from '@angular/core';
import { Store } from '@ngxs/store';
import { AppState } from '../../../state/app.state';
import { ActionTypes, InstructionValidationResultModel, ValidationResultCode } from '../../../models/gls-info.model';
import { toSignal } from '@angular/core/rxjs-interop';
import { BreakpointObserver } from '@angular/cdk/layout';
import { returnToSearchParcelWidget } from '../../routing/routing.utils';
import { Constants } from '../../../models/constants';
import { Router } from '@angular/router';
import { SetActiveWidgetAction } from '../../../state/app.actions';
import { WidgetType } from '../../../state/app.model';

@Component({
  selector: 'app-instruction-validation-failed-widget',
  templateUrl: './instruction-validation-failed-widget.component.html',
  styleUrls: ['./instruction-validation-failed-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InstructionValidationFailedWidgetComponent {
  validationResult: Signal<InstructionValidationResultModel | undefined>;

  breakpoint = toSignal(this.breakpointObserver.observe([Constants.BreakPointMobile]));

  isMobile: Signal<boolean> = computed(() => this.breakpoint()?.matches ?? false);

  get description(): string {
    switch (this.validationResult()?.resultCode) {
      case ValidationResultCode.InvalidZipCode:
        return 'Instruction.Result.InvalidZipCode';

      case ValidationResultCode.Expired:
        return 'Instruction.Result.Expired';

      case ValidationResultCode.InstructionAlreadyExists:
        return 'Instruction.Result.InstructionAlreadyExists';

      case ValidationResultCode.NotFound:
        return 'Instruction.Result.NotFound';

      default:
        return 'Instruction.Result.NotFound';
    }
  }

  get canShowTrackAndTrace() {
    const allowedActions: ActionTypes | number = this.validationResult()?.allowedActions ?? 0;
    return (
      ((allowedActions & ActionTypes.TrackTrace) === ActionTypes.TrackTrace &&
        (this.validationResult()?.parcels?.length ?? 0) > 0)
    );
  }

  constructor(
    private store: Store,
    private readonly router: Router,
    private readonly breakpointObserver: BreakpointObserver
  ) {
    this.validationResult = toSignal(this.store.select(AppState.instructionValidationResult));
  }

  returnToSearch = () => returnToSearchParcelWidget(this.store, this.router);

  showParcelDetails() {
    this.store.dispatch(new SetActiveWidgetAction(WidgetType.ParcelDetails));
  }
}
