import { Injectable } from '@angular/core';
import { AwsMapsClientService } from '../clients/aws-maps-client.service';
import { CalculateRouteCommandOutput, SearchPlaceIndexForTextCommandOutput } from '@aws-sdk/client-location';
import * as maplibregl from 'maplibre-gl';

@Injectable({
  providedIn: 'root',
})
export class AwsMapsService {
  constructor(private readonly awsMapsClient: AwsMapsClientService) {}

  public createMap(options: any): Promise<maplibregl.Map> {
    return this.awsMapsClient.createMap(options);
  }

  public trackingDirections(
    originLat: number,
    originLng: number,
    destinationLat: number,
    destinationLng: number
  ): Promise<CalculateRouteCommandOutput> {
    return this.awsMapsClient.getDirections(originLat, originLng, destinationLat, destinationLng);
  }

  public locationByPostalCode(postalCode: string): Promise<SearchPlaceIndexForTextCommandOutput> {
    return this.awsMapsClient.searchLocationByPostalCode(postalCode);
  }
}
