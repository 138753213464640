import { __assign, __awaiter, __generator, __read, __spread } from "tslib";
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import camelcaseKeys from 'camelcase-keys';
import { ConsoleLogger as Logger, Credentials, getAmplifyUserAgentObject } from '@aws-amplify/core';
import { LocationClient, SearchPlaceIndexForTextCommand, SearchPlaceIndexForSuggestionsCommand, SearchPlaceIndexForPositionCommand, BatchPutGeofenceCommand, GetPlaceCommand, GetGeofenceCommand, ListGeofencesCommand, BatchDeleteGeofenceCommand } from '@aws-sdk/client-location';
import { mapSearchOptions, validateGeofenceId, validateGeofencesInput } from '../util';
var logger = new Logger('AmazonLocationServiceProvider');
var AmazonLocationServiceProvider = /** @class */function () {
  /**
   * Initialize Geo with AWS configurations
   * @param {Object} config - Configuration object for Geo
   */
  function AmazonLocationServiceProvider(config) {
    this._config = config ? config : {};
    logger.debug('Geo Options', this._config);
  }
  /**
   * get the category of the plugin
   * @returns {string} name of the category
   */
  AmazonLocationServiceProvider.prototype.getCategory = function () {
    return AmazonLocationServiceProvider.CATEGORY;
  };
  /**
   * get provider name of the plugin
   * @returns {string} name of the provider
   */
  AmazonLocationServiceProvider.prototype.getProviderName = function () {
    return AmazonLocationServiceProvider.PROVIDER_NAME;
  };
  /**
   * Configure Geo part with aws configuration
   * @param {Object} config - Configuration of the Geo
   * @return {Object} - Current configuration
   */
  AmazonLocationServiceProvider.prototype.configure = function (config) {
    logger.debug('configure Amazon Location Service Provider', config);
    if (!config) return this._config;
    this._config = Object.assign({}, this._config, config);
    return this._config;
  };
  /**
   * Get the map resources that are currently available through the provider
   * @returns {AmazonLocationServiceMapStyle[]}- Array of available map resources
   */
  AmazonLocationServiceProvider.prototype.getAvailableMaps = function () {
    this._verifyMapResources();
    var mapStyles = [];
    var availableMaps = this._config.maps.items;
    var region = this._config.region;
    for (var mapName in availableMaps) {
      var style = availableMaps[mapName].style;
      mapStyles.push({
        mapName: mapName,
        style: style,
        region: region
      });
    }
    return mapStyles;
  };
  /**
   * Get the map resource set as default in amplify config
   * @returns {AmazonLocationServiceMapStyle} - Map resource set as the default in amplify config
   */
  AmazonLocationServiceProvider.prototype.getDefaultMap = function () {
    this._verifyMapResources();
    var mapName = this._config.maps.default;
    var style = this._config.maps.items[mapName].style;
    var region = this._config.region;
    return {
      mapName: mapName,
      style: style,
      region: region
    };
  };
  /**
   * Search by text input with optional parameters
   * @param  {string} text - The text string that is to be searched for
   * @param  {SearchByTextOptions} options? - Optional parameters to the search
   * @returns {Promise<Place[]>} - Promise resolves to a list of Places that match search parameters
   */
  AmazonLocationServiceProvider.prototype.searchByText = function (text, options) {
    return __awaiter(this, void 0, void 0, function () {
      var credentialsOK, locationServiceInput, client, command, response, error_1, PascalResults, results;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4 /*yield*/, this._ensureCredentials()];
          case 1:
            credentialsOK = _a.sent();
            if (!credentialsOK) {
              throw new Error('No credentials');
            }
            this._verifySearchIndex(options === null || options === void 0 ? void 0 : options.searchIndexName);
            locationServiceInput = {
              Text: text,
              IndexName: this._config.search_indices.default
            };
            /**
             * Map search options to Amazon Location Service input object
             */
            if (options) {
              locationServiceInput = __assign(__assign({}, locationServiceInput), mapSearchOptions(options, locationServiceInput));
            }
            client = new LocationClient({
              credentials: this._config.credentials,
              region: this._config.region,
              customUserAgent: getAmplifyUserAgentObject()
            });
            command = new SearchPlaceIndexForTextCommand(locationServiceInput);
            _a.label = 2;
          case 2:
            _a.trys.push([2, 4,, 5]);
            return [4 /*yield*/, client.send(command)];
          case 3:
            response = _a.sent();
            return [3 /*break*/, 5];
          case 4:
            error_1 = _a.sent();
            logger.debug(error_1);
            throw error_1;
          case 5:
            PascalResults = response.Results.map(function (result) {
              return result.Place;
            });
            results = camelcaseKeys(PascalResults, {
              deep: true
            });
            return [2 /*return*/, results];
        }
      });
    });
  };
  /**
   * Search for suggestions based on the input text
   * @param  {string} text - The text string that is to be searched for
   * @param  {SearchByTextOptions} options? - Optional parameters to the search
   * @returns {Promise<SearchForSuggestionsResults>} - Resolves to an array of search suggestion strings
   */
  AmazonLocationServiceProvider.prototype.searchForSuggestions = function (text, options) {
    return __awaiter(this, void 0, void 0, function () {
      var credentialsOK, locationServiceInput, client, command, response, error_2, results;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4 /*yield*/, this._ensureCredentials()];
          case 1:
            credentialsOK = _a.sent();
            if (!credentialsOK) {
              throw new Error('No credentials');
            }
            this._verifySearchIndex(options === null || options === void 0 ? void 0 : options.searchIndexName);
            locationServiceInput = {
              Text: text,
              IndexName: this._config.search_indices.default
            };
            /**
             * Map search options to Amazon Location Service input object
             */
            if (options) {
              locationServiceInput = __assign(__assign({}, locationServiceInput), mapSearchOptions(options, locationServiceInput));
            }
            client = new LocationClient({
              credentials: this._config.credentials,
              region: this._config.region,
              customUserAgent: getAmplifyUserAgentObject()
            });
            command = new SearchPlaceIndexForSuggestionsCommand(locationServiceInput);
            _a.label = 2;
          case 2:
            _a.trys.push([2, 4,, 5]);
            return [4 /*yield*/, client.send(command)];
          case 3:
            response = _a.sent();
            return [3 /*break*/, 5];
          case 4:
            error_2 = _a.sent();
            logger.debug(error_2);
            throw error_2;
          case 5:
            results = response.Results.map(function (result) {
              return {
                text: result.Text,
                placeId: result.PlaceId
              };
            });
            return [2 /*return*/, results];
        }
      });
    });
  };
  AmazonLocationServiceProvider.prototype._verifyPlaceId = function (placeId) {
    if (placeId.length === 0) {
      var errorString = 'PlaceId cannot be an empty string.';
      logger.debug(errorString);
      throw new Error(errorString);
    }
  };
  AmazonLocationServiceProvider.prototype.searchByPlaceId = function (placeId, options) {
    return __awaiter(this, void 0, void 0, function () {
      var credentialsOK, client, searchByPlaceIdInput, command, response, error_3, place;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4 /*yield*/, this._ensureCredentials()];
          case 1:
            credentialsOK = _a.sent();
            if (!credentialsOK) {
              throw new Error('No credentials');
            }
            this._verifySearchIndex(options === null || options === void 0 ? void 0 : options.searchIndexName);
            this._verifyPlaceId(placeId);
            client = new LocationClient({
              credentials: this._config.credentials,
              region: this._config.region,
              customUserAgent: getAmplifyUserAgentObject()
            });
            searchByPlaceIdInput = {
              PlaceId: placeId,
              IndexName: (options === null || options === void 0 ? void 0 : options.searchIndexName) || this._config.search_indices.default
            };
            command = new GetPlaceCommand(searchByPlaceIdInput);
            _a.label = 2;
          case 2:
            _a.trys.push([2, 4,, 5]);
            return [4 /*yield*/, client.send(command)];
          case 3:
            response = _a.sent();
            return [3 /*break*/, 5];
          case 4:
            error_3 = _a.sent();
            logger.debug(error_3);
            throw error_3;
          case 5:
            place = response.Place;
            if (place) {
              return [2 /*return*/, camelcaseKeys(place, {
                deep: true
              })];
            }
            return [2 /*return*/];
        }
      });
    });
  };
  /**
   * Reverse geocoding search via a coordinate point on the map
   * @param coordinates - Coordinates array for the search input
   * @param options - Options parameters for the search
   * @returns {Promise<Place>} - Promise that resolves to a place matching search coordinates
   */
  AmazonLocationServiceProvider.prototype.searchByCoordinates = function (coordinates, options) {
    return __awaiter(this, void 0, void 0, function () {
      var credentialsOK, locationServiceInput, client, command, response, error_4, PascalResults, results;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4 /*yield*/, this._ensureCredentials()];
          case 1:
            credentialsOK = _a.sent();
            if (!credentialsOK) {
              throw new Error('No credentials');
            }
            this._verifySearchIndex(options === null || options === void 0 ? void 0 : options.searchIndexName);
            locationServiceInput = {
              Position: coordinates,
              IndexName: this._config.search_indices.default
            };
            if (options) {
              if (options.searchIndexName) {
                locationServiceInput.IndexName = options.searchIndexName;
              }
              locationServiceInput.MaxResults = options.maxResults;
            }
            client = new LocationClient({
              credentials: this._config.credentials,
              region: this._config.region,
              customUserAgent: getAmplifyUserAgentObject()
            });
            command = new SearchPlaceIndexForPositionCommand(locationServiceInput);
            _a.label = 2;
          case 2:
            _a.trys.push([2, 4,, 5]);
            return [4 /*yield*/, client.send(command)];
          case 3:
            response = _a.sent();
            return [3 /*break*/, 5];
          case 4:
            error_4 = _a.sent();
            logger.debug(error_4);
            throw error_4;
          case 5:
            PascalResults = response.Results.map(function (result) {
              return result.Place;
            });
            results = camelcaseKeys(PascalResults[0], {
              deep: true
            });
            return [2 /*return*/, results];
        }
      });
    });
  };
  /**
   * Create geofences inside of a geofence collection
   * @param geofences - Array of geofence objects to create
   * @param options? - Optional parameters for creating geofences
   * @returns {Promise<AmazonLocationServiceSaveGeofencesResults>} - Promise that resolves to an object with:
   *   successes: list of geofences successfully created
   *   errors: list of geofences that failed to create
   */
  AmazonLocationServiceProvider.prototype.saveGeofences = function (geofences, options) {
    return __awaiter(this, void 0, void 0, function () {
      var credentialsOK, PascalGeofences, results, geofenceBatches, apiLimit;
      var _this = this;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            if (geofences.length < 1) {
              throw new Error('Geofence input array is empty');
            }
            return [4 /*yield*/, this._ensureCredentials()];
          case 1:
            credentialsOK = _a.sent();
            if (!credentialsOK) {
              throw new Error('No credentials');
            }
            // Verify geofence collection exists in aws-config.js
            try {
              this._verifyGeofenceCollections(options === null || options === void 0 ? void 0 : options.collectionName);
            } catch (error) {
              logger.debug(error);
              throw error;
            }
            validateGeofencesInput(geofences);
            PascalGeofences = geofences.map(function (_a) {
              var geofenceId = _a.geofenceId,
                polygon = _a.geometry.polygon;
              return {
                GeofenceId: geofenceId,
                Geometry: {
                  Polygon: polygon
                }
              };
            });
            results = {
              successes: [],
              errors: []
            };
            geofenceBatches = [];
            while (PascalGeofences.length > 0) {
              apiLimit = 10;
              geofenceBatches.push(PascalGeofences.splice(0, apiLimit));
            }
            return [4 /*yield*/, Promise.all(geofenceBatches.map(function (batch) {
              return __awaiter(_this, void 0, void 0, function () {
                var response, error_5;
                return __generator(this, function (_a) {
                  switch (_a.label) {
                    case 0:
                      _a.trys.push([0, 2,, 3]);
                      return [4 /*yield*/, this._AmazonLocationServiceBatchPutGeofenceCall(batch, (options === null || options === void 0 ? void 0 : options.collectionName) || this._config.geofenceCollections.default)];
                    case 1:
                      response = _a.sent();
                      return [3 /*break*/, 3];
                    case 2:
                      error_5 = _a.sent();
                      // If the API call fails, add the geofences to the errors array and move to next batch
                      batch.forEach(function (geofence) {
                        results.errors.push({
                          geofenceId: geofence.GeofenceId,
                          error: {
                            code: 'APIConnectionError',
                            message: error_5.message
                          }
                        });
                      });
                      return [2 /*return*/];
                    case 3:
                      // Push all successes to results
                      response.Successes.forEach(function (success) {
                        var GeofenceId = success.GeofenceId,
                          CreateTime = success.CreateTime,
                          UpdateTime = success.UpdateTime;
                        results.successes.push({
                          geofenceId: GeofenceId,
                          createTime: CreateTime,
                          updateTime: UpdateTime
                        });
                      });
                      // Push all errors to results
                      response.Errors.forEach(function (error) {
                        var _a = error.Error,
                          Code = _a.Code,
                          Message = _a.Message,
                          GeofenceId = error.GeofenceId;
                        results.errors.push({
                          error: {
                            code: Code,
                            message: Message
                          },
                          geofenceId: GeofenceId
                        });
                      });
                      return [2 /*return*/];
                  }
                });
              });
            }))];
          case 2:
            _a.sent();
            return [2 /*return*/, results];
        }
      });
    });
  };
  /**
   * Get geofence from a geofence collection
   * @param geofenceId:string
   * @param options?: Optional parameters for getGeofence
   * @returns {Promise<AmazonLocationServiceGeofence>} - Promise that resolves to a geofence object
   */
  AmazonLocationServiceProvider.prototype.getGeofence = function (geofenceId, options) {
    return __awaiter(this, void 0, void 0, function () {
      var credentialsOK, client, commandInput, command, response, error_6, GeofenceId, CreateTime, UpdateTime, Status, Geometry, geofence;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4 /*yield*/, this._ensureCredentials()];
          case 1:
            credentialsOK = _a.sent();
            if (!credentialsOK) {
              throw new Error('No credentials');
            }
            // Verify geofence collection exists in aws-config.js
            try {
              this._verifyGeofenceCollections(options === null || options === void 0 ? void 0 : options.collectionName);
            } catch (error) {
              logger.debug(error);
              throw error;
            }
            validateGeofenceId(geofenceId);
            client = new LocationClient({
              credentials: this._config.credentials,
              region: this._config.region,
              customUserAgent: getAmplifyUserAgentObject()
            });
            commandInput = {
              GeofenceId: geofenceId,
              CollectionName: (options === null || options === void 0 ? void 0 : options.collectionName) || this._config.geofenceCollections.default
            };
            command = new GetGeofenceCommand(commandInput);
            _a.label = 2;
          case 2:
            _a.trys.push([2, 4,, 5]);
            return [4 /*yield*/, client.send(command)];
          case 3:
            response = _a.sent();
            return [3 /*break*/, 5];
          case 4:
            error_6 = _a.sent();
            logger.debug(error_6);
            throw error_6;
          case 5:
            GeofenceId = response.GeofenceId, CreateTime = response.CreateTime, UpdateTime = response.UpdateTime, Status = response.Status, Geometry = response.Geometry;
            geofence = {
              createTime: CreateTime,
              geofenceId: GeofenceId,
              geometry: {
                polygon: Geometry.Polygon
              },
              status: Status,
              updateTime: UpdateTime
            };
            return [2 /*return*/, geofence];
        }
      });
    });
  };
  /**
   * List geofences from a geofence collection
   * @param  options?: ListGeofenceOptions
   * @returns {Promise<ListGeofencesResults>} - Promise that resolves to an object with:
   *   entries: list of geofences - 100 geofences are listed per page
   *   nextToken: token for next page of geofences
   */
  AmazonLocationServiceProvider.prototype.listGeofences = function (options) {
    return __awaiter(this, void 0, void 0, function () {
      var credentialsOK, client, listGeofencesInput, command, response, error_7, NextToken, Entries, results;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4 /*yield*/, this._ensureCredentials()];
          case 1:
            credentialsOK = _a.sent();
            if (!credentialsOK) {
              throw new Error('No credentials');
            }
            // Verify geofence collection exists in aws-config.js
            try {
              this._verifyGeofenceCollections(options === null || options === void 0 ? void 0 : options.collectionName);
            } catch (error) {
              logger.debug(error);
              throw error;
            }
            client = new LocationClient({
              credentials: this._config.credentials,
              region: this._config.region,
              customUserAgent: getAmplifyUserAgentObject()
            });
            listGeofencesInput = {
              NextToken: options === null || options === void 0 ? void 0 : options.nextToken,
              CollectionName: (options === null || options === void 0 ? void 0 : options.collectionName) || this._config.geofenceCollections.default
            };
            command = new ListGeofencesCommand(listGeofencesInput);
            _a.label = 2;
          case 2:
            _a.trys.push([2, 4,, 5]);
            return [4 /*yield*/, client.send(command)];
          case 3:
            response = _a.sent();
            return [3 /*break*/, 5];
          case 4:
            error_7 = _a.sent();
            logger.debug(error_7);
            throw error_7;
          case 5:
            NextToken = response.NextToken, Entries = response.Entries;
            results = {
              entries: Entries.map(function (_a) {
                var GeofenceId = _a.GeofenceId,
                  CreateTime = _a.CreateTime,
                  UpdateTime = _a.UpdateTime,
                  Status = _a.Status,
                  Polygon = _a.Geometry.Polygon;
                return {
                  geofenceId: GeofenceId,
                  createTime: CreateTime,
                  updateTime: UpdateTime,
                  status: Status,
                  geometry: {
                    polygon: Polygon
                  }
                };
              }),
              nextToken: NextToken
            };
            return [2 /*return*/, results];
        }
      });
    });
  };
  /**
   * Delete geofences from a geofence collection
   * @param geofenceIds: string|string[]
   * @param options?: GeofenceOptions
   * @returns {Promise<DeleteGeofencesResults>} - Promise that resolves to an object with:
   *  successes: list of geofences successfully deleted
   *  errors: list of geofences that failed to delete
   */
  AmazonLocationServiceProvider.prototype.deleteGeofences = function (geofenceIds, options) {
    return __awaiter(this, void 0, void 0, function () {
      var credentialsOK, badGeofenceIds, results, geofenceIdBatches, count;
      var _this = this;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            if (geofenceIds.length < 1) {
              throw new Error('GeofenceId input array is empty');
            }
            return [4 /*yield*/, this._ensureCredentials()];
          case 1:
            credentialsOK = _a.sent();
            if (!credentialsOK) {
              throw new Error('No credentials');
            }
            this._verifyGeofenceCollections(options === null || options === void 0 ? void 0 : options.collectionName);
            badGeofenceIds = geofenceIds.filter(function (geofenceId) {
              try {
                validateGeofenceId(geofenceId);
              } catch (error) {
                return true;
              }
            });
            if (badGeofenceIds.length > 0) {
              throw new Error("Invalid geofence ids: " + badGeofenceIds.join(', '));
            }
            results = {
              successes: [],
              errors: []
            };
            geofenceIdBatches = [];
            count = 0;
            while (count < geofenceIds.length) {
              geofenceIdBatches.push(geofenceIds.slice(count, count += 10));
            }
            return [4 /*yield*/, Promise.all(geofenceIdBatches.map(function (batch) {
              return __awaiter(_this, void 0, void 0, function () {
                var response, error_8, badGeofenceIds;
                var _a;
                return __generator(this, function (_b) {
                  switch (_b.label) {
                    case 0:
                      _b.trys.push([0, 2,, 3]);
                      return [4 /*yield*/, this._AmazonLocationServiceBatchDeleteGeofenceCall(batch, (options === null || options === void 0 ? void 0 : options.collectionName) || this._config.geofenceCollections.default)];
                    case 1:
                      response = _b.sent();
                      return [3 /*break*/, 3];
                    case 2:
                      error_8 = _b.sent();
                      // If the API call fails, add the geofences to the errors array and move to next batch
                      batch.forEach(function (geofenceId) {
                        var errorObject = {
                          geofenceId: geofenceId,
                          error: {
                            code: error_8.message,
                            message: error_8.message
                          }
                        };
                        results.errors.push(errorObject);
                      });
                      return [2 /*return*/];
                    case 3:
                      badGeofenceIds = response.Errors.map(function (_a) {
                        var geofenceId = _a.geofenceId;
                        return geofenceId;
                      });
                      (_a = results.successes).push.apply(_a, __spread(batch.filter(function (Id) {
                        return !badGeofenceIds.includes(Id);
                      })));
                      return [2 /*return*/];
                  }
                });
              });
            }))];
          case 2:
            _a.sent();
            return [2 /*return*/, results];
        }
      });
    });
  };
  /**
   * @private
   */
  AmazonLocationServiceProvider.prototype._ensureCredentials = function () {
    return __awaiter(this, void 0, void 0, function () {
      var credentials, cred, error_9;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 2,, 3]);
            return [4 /*yield*/, Credentials.get()];
          case 1:
            credentials = _a.sent();
            if (!credentials) return [2 /*return*/, false];
            cred = Credentials.shear(credentials);
            logger.debug('Set credentials for storage. Credentials are:', cred);
            this._config.credentials = cred;
            return [2 /*return*/, true];
          case 2:
            error_9 = _a.sent();
            logger.debug('Ensure credentials error. Credentials are:', error_9);
            return [2 /*return*/, false];
          case 3:
            return [2 /*return*/];
        }
      });
    });
  };
  AmazonLocationServiceProvider.prototype._verifyMapResources = function () {
    if (!this._config.maps) {
      var errorString = "No map resources found in amplify config, run 'amplify add geo' to create one and run `amplify push` after";
      logger.debug(errorString);
      throw new Error(errorString);
    }
    if (!this._config.maps.default) {
      var errorString = "No default map resource found in amplify config, run 'amplify add geo' to create one and run `amplify push` after";
      logger.debug(errorString);
      throw new Error(errorString);
    }
  };
  AmazonLocationServiceProvider.prototype._verifySearchIndex = function (optionalSearchIndex) {
    if ((!this._config.search_indices || !this._config.search_indices.default) && !optionalSearchIndex) {
      var errorString = 'No Search Index found in amplify config, please run `amplify add geo` to create one and run `amplify push` after.';
      logger.debug(errorString);
      throw new Error(errorString);
    }
  };
  AmazonLocationServiceProvider.prototype._verifyGeofenceCollections = function (optionalGeofenceCollectionName) {
    if ((!this._config.geofenceCollections || !this._config.geofenceCollections.default) && !optionalGeofenceCollectionName) {
      var errorString = 'No Geofence Collections found, please run `amplify add geo` to create one and run `amplify push` after.';
      logger.debug(errorString);
      throw new Error(errorString);
    }
  };
  AmazonLocationServiceProvider.prototype._AmazonLocationServiceBatchPutGeofenceCall = function (PascalGeofences, collectionName) {
    return __awaiter(this, void 0, void 0, function () {
      var geofenceInput, client, command, response, error_10;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            geofenceInput = {
              Entries: PascalGeofences,
              CollectionName: collectionName || this._config.geofenceCollections.default
            };
            client = new LocationClient({
              credentials: this._config.credentials,
              region: this._config.region,
              customUserAgent: getAmplifyUserAgentObject()
            });
            command = new BatchPutGeofenceCommand(geofenceInput);
            _a.label = 1;
          case 1:
            _a.trys.push([1, 3,, 4]);
            return [4 /*yield*/, client.send(command)];
          case 2:
            response = _a.sent();
            return [3 /*break*/, 4];
          case 3:
            error_10 = _a.sent();
            throw error_10;
          case 4:
            return [2 /*return*/, response];
        }
      });
    });
  };
  AmazonLocationServiceProvider.prototype._AmazonLocationServiceBatchDeleteGeofenceCall = function (geofenceIds, collectionName) {
    return __awaiter(this, void 0, void 0, function () {
      var deleteGeofencesInput, client, command, response, error_11;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            deleteGeofencesInput = {
              GeofenceIds: geofenceIds,
              CollectionName: collectionName || this._config.geofenceCollections.default
            };
            client = new LocationClient({
              credentials: this._config.credentials,
              region: this._config.region,
              customUserAgent: getAmplifyUserAgentObject()
            });
            command = new BatchDeleteGeofenceCommand(deleteGeofencesInput);
            _a.label = 1;
          case 1:
            _a.trys.push([1, 3,, 4]);
            return [4 /*yield*/, client.send(command)];
          case 2:
            response = _a.sent();
            return [3 /*break*/, 4];
          case 3:
            error_11 = _a.sent();
            throw error_11;
          case 4:
            return [2 /*return*/, response];
        }
      });
    });
  };
  AmazonLocationServiceProvider.CATEGORY = 'Geo';
  AmazonLocationServiceProvider.PROVIDER_NAME = 'AmazonLocationService';
  return AmazonLocationServiceProvider;
}();
export { AmazonLocationServiceProvider };
