export const environment = {
  production: false,
  environmentName: 'test',
  recaptcha: {
    siteKey: '6LeReZgeAAAAAG2kvRhWB_d79vqdk8SxSvRTbhoh',
  },
  appRoot: 'gls-info',
  featureFlags: {
    feedback: true,
    otfi: true,
    apl: true
  },
};
