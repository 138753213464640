import { getEndpointPlugin } from "@smithy/middleware-endpoint";
import { getSerdePlugin } from "@smithy/middleware-serde";
import { Command as $Command } from "@smithy/smithy-client";
import { commonParams } from "../endpoint/EndpointParameters";
import { CalculateRouteRequestFilterSensitiveLog, CalculateRouteResponseFilterSensitiveLog } from "../models/models_0";
import { de_CalculateRouteCommand, se_CalculateRouteCommand } from "../protocols/Aws_restJson1";
export { $Command };
export class CalculateRouteCommand extends $Command.classBuilder().ep({
  ...commonParams
}).m(function (Command, cs, config, o) {
  return [getSerdePlugin(config, this.serialize, this.deserialize), getEndpointPlugin(config, Command.getEndpointParameterInstructions())];
}).s("LocationService", "CalculateRoute", {}).n("LocationClient", "CalculateRouteCommand").f(CalculateRouteRequestFilterSensitiveLog, CalculateRouteResponseFilterSensitiveLog).ser(se_CalculateRouteCommand).de(de_CalculateRouteCommand).build() {}