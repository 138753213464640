import { ChangeDetectionStrategy, Component, computed, OnInit, Signal } from '@angular/core';
import { Store } from '@ngxs/store';
import {
  RefreshParcelDetailsAction,
  SetActiveWidgetAction,
  ToggleWidgetExpansionAction,
} from '../../state/app.actions';
import { ParcelIdentifierModel, WidgetType } from '../../state/app.model';
import { ActivatedRoute } from '@angular/router';
import { BaseTrackAndTraceComponent } from './summary/base-track-and-trace.component';
import { copyToClipboard, getCaptchaTokenForParcelInfo, getLocale } from '../../services/spotlight.utils';
import { BreakpointObserver } from '@angular/cdk/layout';
import { toSignal } from '@angular/core/rxjs-interop';
import { Constants } from '../../models/constants';
import { ReCaptchaV3Service } from 'ng-recaptcha-2';
import { AppState } from '../../state/app.state';
import { hasAddress } from '../../services/track-and-trace.utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-search-parcel-result',
  templateUrl: './search-parcel-result.component.html',
  styleUrls: ['./search-parcel.component.scss', './search-parcel-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchParcelResultComponent extends BaseTrackAndTraceComponent implements OnInit {
  parcelIdentifier: Signal<ParcelIdentifierModel | undefined>;

  get parcelNumber(): string | undefined {
    return this.parcel()?.parcelNo;
  }

  get zipCode(): string | undefined {
    return this.parcel()?.zipcode;
  }

  get isExpanded(): boolean {
    return this.widget()?.isExpanded ?? false;
  }

  get etaWarning(): string | undefined {
    const details = this.parcel();
    if (!(details && hasAddress(details))) {
      return undefined;
    }
    const status = details.deliveryStatus?.status;
    if (status) {
      return ['IMPORT', 'IMPORTINNL', 'EXPORT', 'PREADVICE'].reduce((acc: string | undefined, item) => {
        if (status === item) {
          return `Eta.Warning.${item}`;
        }
        return acc;
      }, undefined);
    }
    return undefined;
  }

  breakpoint = toSignal(this.breakpointObserver.observe([Constants.BreakPointMobile]));

  isMobile: Signal<boolean> = computed(() => this.breakpoint()?.matches ?? false);

  constructor(
    private readonly breakpointObserver: BreakpointObserver,
    store: Store,
    private route: ActivatedRoute,
    private readonly translateService: TranslateService,
    private readonly reCaptchaService: ReCaptchaV3Service,
    private readonly snackBar: MatSnackBar
  ) {
    super(store);
    this.parcelIdentifier = toSignal(this.store.select(AppState.parcelIdentifier));
  }

  ngOnInit(): void {
    this.register(this.translateService.onLangChange.subscribe(() => this.refreshParcelDetails()));
    this.register(
      this.route.queryParams.subscribe((params) => {
        const language = params['lang'];
        if (language && language !== this.translateService.currentLang) {
          this.translateService.use(language.toLowerCase());
        }
      })
    );
  }

  changeDelivery() {
    this.store.dispatch(new SetActiveWidgetAction(this.rootWidget));
  }

  copyParcelNumber = () => {
    const text = this.parcelNumber;
    if (text) {
      copyToClipboard(text, this.snackBar, this.translateService);
    }
  };

  toggleExpansion = () => {
    this.store.dispatch(new ToggleWidgetExpansionAction());
  };

  refreshParcelDetails = async () => {
    const culture = getLocale(this.translateService);
    const captcha = (await getCaptchaTokenForParcelInfo(this.reCaptchaService)) ?? '';
    this.store.dispatch(new RefreshParcelDetailsAction(culture, captcha));
  };
}
