<app-skeleton-parcel-search *ngIf="showLoadingWidget()"></app-skeleton-parcel-search>

<div class="primary-column" [class.mobile]="isMobile()" *ngIf="!showLoadingWidget()">
  <div class="primary-content" [class.mobile]="isMobile()">
    <div class="header">
      <button class="btn-horizontal-expander size-40 size-40-expander" aria-label="Expand" (click)="goBack()">
        <mat-icon class="expanded">navigate_next</mat-icon>
      </button>
      <div class="spotlight-blue title" translate>Parcel.ParcelShopLocator</div>
    </div>

    <form [formGroup]="form" (keydown.enter)="onSubmit($event)">
      <div class="row1">
        <!-- ZipCode -->
        <div
          class="search-container form-group form-group_with_icon form-group_with_feedback"
          [class.is-invalid]="isInvalidControl('zipCode')"
        >
          <div class="invalid-value-missing">
            <input
              id="zipCode"
              data-cy="tbAddressZipCode"
              formControlName="zipCode"
              type="text"
              class="zipcode form-control"
              autocomplete="zipCode"
              [placeholder]="placeholderZipCode"
              appTrimInput
              #zip
              (input)="zip.value = zip.value.toUpperCase()"
              minlength="4"
              maxlength="10"
            />
          </div>

          <ng-container *ngIf="isInvalidControl('zipCode')">
            <div
              *ngIf="isControlError('zipCode', 'required')"
              class="feedback invalid-value-missing"
              [translateParams]="{
                fieldName: ('Instruction.ZipCode' | translate)
              }"
              translate
            >
              Validations.Required.Field
            </div>
            <div *ngIf="isControlError('zipCode', 'pattern')" class="feedback" translate>
              Validations.Required.ZipCodePattern
            </div>
            <div *ngIf="isControlError('zipCode', 'minlength')" class="feedback" translate>
              Validations.Required.ZipCodePattern
            </div>
          </ng-container>
        </div>

        <button mat-button class="btn search btn-primary" (click)="onSubmit($event)">
          {{ 'Instruction.Search' | translate }}
        </button>
      </div>
      <div class="row2">
        <mat-checkbox formControlName="showShops">{{ 'Parcel.ShowParcelShops' | translate }}</mat-checkbox>
        <mat-checkbox formControlName="showApls">{{ 'Parcel.ShowApls' | translate }}</mat-checkbox>
      </div>
    </form>

    <div class="container-collectionpoints">
      <app-parcel-collection-point
        *ngFor="let collectionPoint of collectionPoints"
        [collectionPoint]="collectionPoint"
        [showtime]="true"
        [showtitle]="false"
        [showPhoto]="true"
        [allowSelectCollectionPoint]="false"
        [selectCollectionPointTitle]="'Instruction.SelectCollectionPoint'"
      ></app-parcel-collection-point>
    </div>
  </div>
</div>
