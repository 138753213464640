import { ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, OnInit, signal, Signal } from '@angular/core';
import { Store } from '@ngxs/store';
import { BaseTrackAndTraceComponent } from '../../track-and-trace/summary/base-track-and-trace.component';
import { BreakpointObserver } from '@angular/cdk/layout';
import { toSignal } from '@angular/core/rxjs-interop';
import { Constants } from '../../../models/constants';
import { SetActiveWidgetAction, SetInstructionResultAction } from '../../../state/app.actions';
import { WidgetType } from '../../../state/app.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AddressFormSettings } from '../../../services/addressFormsSettings';
import { ActionTypes, Depot, InstructionResultCode, InstructionType } from '../../../models/gls-info.model';
import { InstructionClientService } from '../../../clients/instruction.client';
import { ReCaptchaV3Service } from 'ng-recaptcha-2';
import { getCaptchaTokenForParcelInfo } from '../../../services/spotlight.utils';
import { AppState } from '../../../state/app.state';

@Component({
  selector: 'app-instruction-confirm',
  templateUrl: './instruction-confirm.component.html',
  styleUrls: ['./instruction-confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InstructionConfirmComponent extends BaseTrackAndTraceComponent implements OnInit {
  form: FormGroup;
  emailRequired = false;
  isLoading = signal(false);
  phoneNumberRequired = false;
  houseNumberRequired = false;

  breakpoint = toSignal(this.breakpointObserver.observe([Constants.BreakPointMobile]));
  selectedCollectionPoint = toSignal(this.store.select(AppState.selectedCollectionPoint));
  isMobile: Signal<boolean> = computed(() => this.breakpoint()?.matches ?? false);

  isInvalidControl = (name: string): boolean => {
    const control = this.form.controls[name];
    return control.invalid && (control.dirty || control.touched);
  };

  isControlError = (name: string, error: string): boolean => {
    const control = this.form.controls[name];
    return control.errors && control.errors[error];
  };

  get isCsiInstruction(): boolean {
    return this.instructionValidateRequest()?.instructionType === InstructionType.CSI;
  }

  get depots(): Depot[] | undefined {
    return this.instructionValidateResult()?.depots;
  }

  constructor(
    store: Store,
    private readonly breakpointObserver: BreakpointObserver,
    public readonly fb: FormBuilder,
    private instructionClient: InstructionClientService,
    private cd: ChangeDetectorRef,
    private recaptchaService: ReCaptchaV3Service
  ) {
    super(store);
    this.form = this.fb.group({
      depot: [],
      entireShipment: true,
      email: [
        '',
        [
          Validators.maxLength(AddressFormSettings.Email.MaxLength),
          Validators.pattern(AddressFormSettings.Email.Regex),
          Validators.required,
        ],
      ],
      phoneNumber: [
        '',
        [
          Validators.maxLength(AddressFormSettings.PhoneNumber.MaxLength),
          Validators.pattern(AddressFormSettings.PhoneNumber.Regex),
          Validators.required,
        ],
      ],
      houseNumber: ['', [Validators.maxLength(AddressFormSettings.HouseNumber.MaxLength)]],
    });
  }

  ngOnInit() {
    if (this.isCsiInstruction) {
      this.form.patchValue({ depot: this.instructionValidateResult()?.leadingAddress?.locCode });
    }
    const address = this.instructionValidateResult()?.leadingAddress;
    const action = this.instruction()?.actionType ?? ActionTypes.RetryDelivery;
    this.emailRequired = address?.email === undefined || address?.email === '';
    const isPhoneNoEmpty = address?.phoneNo === undefined || address?.phoneNo === '';
    this.houseNumberRequired =
      (action === ActionTypes.RetryDeliveryWithDepositPermission || action === ActionTypes.RetryDelivery) &&
      (address?.houseNo === undefined || address?.houseNo === '');

    if (this.isCsiInstruction) {
      const phoneNumberRequired =
        (action === ActionTypes.CollectAtApl ||
          action === ActionTypes.CollectAtParcelShop ||
          action === ActionTypes.CollectAtDepot) &&
        this.selectedCollectionPoint()?.isApl === true;

      if (!phoneNumberRequired) {
        this.form.get('phoneNumber')?.removeValidators([Validators.required]);
        this.form.get('phoneNumber')?.updateValueAndValidity();
      }
    }

    if (!this.emailRequired) {
      this.form.patchValue({ email: address?.email });
    }
    if (!isPhoneNoEmpty) {
      this.form.patchValue({ phoneNumber: address?.phoneNo });
    }
    if (!this.houseNumberRequired) {
      this.form.patchValue({ houseNumber: '' });
    }else{
      this.form.get('houseNumber')?.addValidators([Validators.required]);
      this.form.get('houseNumber')?.updateValueAndValidity();}

    this.cd.detectChanges();
  }

  goBack() {
    this.store.dispatch(new SetActiveWidgetAction(WidgetType.InstructionSelectDeliveryOption));
  }

  async confirm() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const email = this.form.get('email')?.value ?? '';
    const phoneNumber = this.form.get('phoneNumber')?.value ?? '';
    const houseNumber = this.form.get('houseNumber')?.value ?? '';
    const depot = this.form.get('depot')?.value ?? '';
    const applyToEntireShipment = this.isCsiInstruction && this.form.get('entireShipment')?.value;

    const instruction = {
      ...this.instruction()!,
      contactEmail: email ? email : '',
      contactPhoneNo: phoneNumber ? phoneNumber : '',
      ownHouseNumber: houseNumber ? houseNumber : '',
      depot: this.isCsiInstruction ? depot : '',
      parcels: applyToEntireShipment
        ? this.getParcels(this.instructionValidateResult()!.parcels)
        : this.instruction()!.parcels,
    };

    this.isLoading.set(true);
    const captcha = (await getCaptchaTokenForParcelInfo(this.recaptchaService)) ?? '';
    const result = await this.instructionClient.createInstruction(instruction, captcha);
    this.isLoading.set(false);

    this.store.dispatch([
      new SetInstructionResultAction(
        result ?? {
          resultCode: InstructionResultCode.NotAllowed,
          errorCode: '',
        }
      ),
      new SetActiveWidgetAction(WidgetType.InstructionResult),
    ]);
  }
}
