import { ChangeDetectionStrategy, Component, computed, Signal } from '@angular/core';
import { Store } from '@ngxs/store';
import { SetActiveWidgetAction } from '../../../state/app.actions';
import { WidgetType } from '../../../state/app.model';
import { BreakpointObserver } from '@angular/cdk/layout';
import { toSignal } from '@angular/core/rxjs-interop';
import { Constants } from '../../../models/constants';

@Component({
  selector: 'app-instruction-aci-confirm',
  templateUrl: './instruction-aci-confirm.component.html',
  styleUrls: ['./instruction-aci-confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InstructionAciConfirmWidgetComponent {
  breakpoint = toSignal(this.breakpointObserver.observe([Constants.BreakPointMobile]));

  isMobile: Signal<boolean> = computed(() => this.breakpoint()?.matches ?? false);
  constructor(private store: Store, private readonly breakpointObserver: BreakpointObserver) {}

  confirm() {
    this.store.dispatch(new SetActiveWidgetAction(WidgetType.ParcelDetails));
  }
}
