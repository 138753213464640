<div class="search-container">
  <ng-container *ngIf="showSearchParcel">
    <app-search-parcel></app-search-parcel>
  </ng-container>
  
  <ng-container *ngIf="showParcelDetails">
    <app-search-parcel-result></app-search-parcel-result>
  </ng-container>
  
  <ng-container *ngIf="showLoadingSkeleton">
    <app-skeleton-parcel-search></app-skeleton-parcel-search>
  </ng-container>
  
  <ng-container *ngIf="showAciConfirmWidget">
    <app-instruction-aci-confirm></app-instruction-aci-confirm>
  </ng-container>
  
  <ng-container *ngIf="showAciEnterEmail">
    <app-instruction-aci-enter-email></app-instruction-aci-enter-email>
  </ng-container>

  <ng-container *ngIf="showValidationError">
    <app-instruction-validation-failed-widget></app-instruction-validation-failed-widget>
  </ng-container>

  <ng-container *ngIf="showEnterZipCode">
    <app-instruction-enter-zipcode></app-instruction-enter-zipcode>
  </ng-container>

  <ng-container *ngIf="showSelectedCollectionPoint">
    <app-instruction-collectionpoint></app-instruction-collectionpoint>
  </ng-container>

  <ng-container *ngIf="showSelectCollectionPoint">
    <app-instruction-select-collectionpoint></app-instruction-select-collectionpoint>
  </ng-container>

  <ng-container *ngIf="showDepostPermission">
    <app-instruction-depositpermission></app-instruction-depositpermission>
  </ng-container>

  <ng-container *ngIf="showDeliverAtOtherMoment">
    <app-instruction-other-moment></app-instruction-other-moment>
  </ng-container>

  <ng-container *ngIf="showDeliverAtNeighBours">
    <app-instruction-deliver-at-neigbours></app-instruction-deliver-at-neigbours>
  </ng-container>
  
  <ng-container *ngIf="showDeliverToAlternateAddress">
    <app-instruction-alternate-address></app-instruction-alternate-address>
  </ng-container>

  <ng-container *ngIf="showRefuseParcel">
    <app-instruction-refuse></app-instruction-refuse>
  </ng-container>

  <ng-container *ngIf="showSelectDeliveryOption">
    <app-instruction-delivery-options></app-instruction-delivery-options>
  </ng-container>

  <ng-container *ngIf="showConfirmInstruction">
    <app-instruction-confirm></app-instruction-confirm>
  </ng-container>

  <ng-container *ngIf="showInstructionResult">
    <app-instruction-result></app-instruction-result>
  </ng-container>

  <ng-container *ngIf="showShopLocator">
    <app-shop-locator></app-shop-locator>
  </ng-container>
</div>
