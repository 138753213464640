<div class="card-tt-details">
  <div class="widget-container">
    <div class="primary-column" [class.mobile]="isMobile()">
      <div class="primary-content" [class.desktop]="!isMobile()">
        <div class="title spotlight-blue" translate="">Instruction.FindMyParcel</div>
        <div class="card">
          <div class="spotlight-black" translate>Instruction.EnterZipCode</div>
          <form [formGroup]="form">
            <div class="input">
              <!-- ZipCode -->
              <div
                class="form-group form-group_with_icon form-group_with_feedback"
                [class.is-invalid]="isInvalidControl('zipCode')"
              >
                <div class="form-field-icon-holder invalid-value-missing">
                  <input
                    id="zipCode"
                    data-cy="tbAddressZipCode"
                    formControlName="zipCode"
                    type="text"
                    class="zipcode form-control"
                    autocomplete="zipCode"
                    [placeholder]="placeholderZipCode"
                    appTrimInput
                    #zip
                    (input)="zip.value = zip.value.toUpperCase()"
                    minlength="4"
                    maxlength="10"
                  />
                </div>
                <ng-container *ngIf="isInvalidControl('zipCode')">
                  <div
                    *ngIf="isControlError('zipCode', 'required')"
                    class="feedback invalid-value-missing"
                    [translateParams]="{
                      fieldName: ('Instruction.ZipCode' | translate)
                    }"
                    translate
                  >
                    Validations.Required.Field
                  </div>
                  <div *ngIf="isControlError('zipCode', 'pattern')" class="feedback" translate>
                    Validations.Required.ZipCodePattern
                  </div>
                  <div *ngIf="isControlError('zipCode', 'minlength')" class="feedback" translate>
                    Validations.Required.ZipCodePattern
                  </div>
                </ng-container>
                <button mat-button class="btn btn-primary" (click)="onSubmit()">
                  {{ 'Instruction.Submit' | translate }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
