import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseTrackAndTraceComponent } from '../../summary/base-track-and-trace.component';
import { ParcelState, ParcelSubState } from '../../../../models/track-and-trace.model';

@Component({
  selector: 'app-context-not-delivered',
  templateUrl: './context-not-delivered.component.html',
  styleUrls: ['./context-not-delivered.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContextNotDeliveredComponent extends BaseTrackAndTraceComponent {
  get notDeliveredReason(): string {
    if (
      this.details?.state === ParcelState.NotDelivered &&
      this.details.subState === ParcelSubState.DeliveryFailedNotAtHome
    ) {
      return 'TrackAndTrace.NotDelivered.NotAtHome';
    }

    if (
      this.details?.state === ParcelState.NotDelivered &&
      this.details.subState === ParcelSubState.DeliveryFailedRefused
    ) {
      return 'TrackAndTrace.NotDelivered.Refused';
    }

    return '';
  }
}
