import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AddressLookUpResponse } from '../models/gls-info.model';
import { lastValueFrom } from 'rxjs';
import { TtUiConfig } from '../environments/ttui/ttui.config';

@Injectable({
  providedIn: 'root',
})
export class AddressBookService {
  constructor(private readonly http: HttpClient) {}

  public async getAdressFromNLZipCode(
    postalCode: string,
    houseNumber: number
  ): Promise<AddressLookUpResponse | undefined> {
    try {
      const response = await lastValueFrom(
        this.http.post<AddressLookUpResponse>(
          `${TtUiConfig.ApiUrl}/api/utils/v1/address`,
          {
            postalCode,
            houseNumber,
          },
          { observe: 'response' }
        )
      );
      if (response.status === 200) {
        if (response.body) {
          return response.body;
        }
      }
      if (response.status === 204) {
        return undefined;
      }
    } catch (ex) {
      console.log(ex);
    }
    throw new Error('AddressBook.Api.Errors.getAdressFromNLZipCode');
  }
}
