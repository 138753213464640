import { ChangeDetectionStrategy, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import { AppState } from './state/app.state';
import { ParcelDetails } from './models/track-and-trace.model';
import { TranslateService } from '@ngx-translate/core';
import { WidgetStateModel, WidgetType } from './state/app.model';
import { BaseComponentComponent } from './base/base-component.component';

export enum AdType {
  None,
  Default,
  Commercial,
}

@Component({
  selector: 'app-spotlight',
  templateUrl: './advertisement.component.html',
  styleUrl: './advertisement.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdvertisementComponent extends BaseComponentComponent {
  @ViewChild('container', { static: true }) container!: ElementRef<HTMLDivElement>;
  consent?: string = undefined;
  parcel?: ParcelDetails = undefined;
  widget?: WidgetStateModel = undefined;
  currentAdType: AdType = AdType.None;
  currentTargetData?: string = undefined;
  currentConsent?: string = undefined;
  addedElement: HTMLElement | null = null;

  showAd() {
    const currentWidget = this.widget?.widgetType ?? WidgetType.LoadingSkeleton;
    if (
      this.consentTag &&
      this.targetingData &&
      currentWidget !== WidgetType.SearchParcel &&
      currentWidget !== WidgetType.EnterZipCode &&
      currentWidget !== WidgetType.LoadingSkeleton
    ) {
      if (
        this.currentTargetData !== this.targetingData ||
        this.currentConsent !== this.consent ||
        this.currentAdType !== AdType.Commercial
      ) {
        // show commercial ad
        this.currentTargetData = this.targetingData;
        this.currentConsent = this.consent;
        this.currentAdType = AdType.Commercial;
        this.removePreviousAd();
        this.addAd();
      }
    }

    if (this.widget?.widgetType === WidgetType.SearchParcel) {
      if (this.currentAdType !== AdType.Default) {
        this.currentAdType = AdType.Default;
        // show default ad
        this.removePreviousAd();
        this.addAd();
      }
    }
  }

  private removePreviousAd() {
    if (this.addedElement) {
      const container = this.container.nativeElement;
      this.renderer.removeChild(container, this.addedElement);
      this.addedElement = null;
    }
  }

  addAd() {
    if (!this.container) return;
    const container = this.container.nativeElement;
    if (this.currentAdType === AdType.Commercial) {
      this.addedElement = this.renderer.createElement('spotlight-widget');
      this.renderer.setAttribute(this.addedElement, 'origin', 'https://gls-group.com/NL/en/home/');
      this.renderer.setAttribute(this.addedElement, 'clientid', 'GLS_NL');
      this.renderer.setAttribute(this.addedElement, 'gdpr', '1');
      this.renderer.setAttribute(this.addedElement, 'tcstring', `${this.consentTag}`);
      this.renderer.setAttribute(this.addedElement, 'hash', `${this.targetingData}`);
      this.renderer.appendChild(container, this.addedElement);
    } else {
      this.addedElement = this.renderer.createElement('spotlight-widget');
      this.renderer.setAttribute(this.addedElement, 'origin', 'https://gls-group.com/NL/en/home/');
      this.renderer.setAttribute(this.addedElement, 'clientid', 'GLS_NL');
      this.renderer.setAttribute(this.addedElement, 'gdpr', '1');
      this.renderer.appendChild(container, this.addedElement);
    }
  }

  get consentTag(): string {
    return this.consent ?? '';
  }

  get targetingData(): string {
    return this.parcel?.sapNo ?? '';
  }

  get locale(): string {
    return this.translateService.currentLang.toLowerCase();
  }

  constructor(private store: Store, private readonly translateService: TranslateService, private renderer: Renderer2) {
    super();

    this.register(
      this.store.select(AppState.consent).subscribe((consent) => {
        this.consent = consent;
        this.showAd();
      })
    );

    this.register(
      this.store.select(AppState.parcelDetails).subscribe((parcelDetails) => {
        this.parcel = parcelDetails;
        this.showAd();
      })
    );

    this.register(
      this.store.select(AppState.widget).subscribe((widget) => {
        this.widget = widget;
        this.showAd();
      })
    );
  }
}
