import { Component, Signal } from '@angular/core';
import { ParcelDetails, ParcelState, ParcelSubState, Scan, ScanType } from '../../../models/track-and-trace.model';
import { toSignal } from '@angular/core/rxjs-interop';
import { Store } from '@ngxs/store';
import { AppState } from '../../../state/app.state';
import { BaseComponentComponent } from '../../../base/base-component.component';
import { AllowedOptionType, WidgetStateModel, WidgetType } from '../../../state/app.model';
import { InstructionValidationResponse } from '../../../models/instruction-validation-response';
import {
  ActionTypes,
  AddressCategoryType,
  CreateInstructionRequestModel,
  InstructionAddressType,
  InstructionType,
  ParcelIdentifierModel,
  ParcelModel,
} from '../../../models/gls-info.model';
import { InstructionValidationRequest } from '../../../models/instruction-validation-request';
import { lastOrDefault } from '../../../services/spotlight.utils';
import { isAllowedOption } from '../../../services/instruction.utils';

@Component({
  selector: 'app-base-track-and-trace-summary',
  template: '',
})
export abstract class BaseTrackAndTraceComponent extends BaseComponentComponent {
  protected parcel: Signal<ParcelDetails | undefined>;
  protected widget: Signal<WidgetStateModel | undefined>;
  protected instructionValidateResult: Signal<InstructionValidationResponse | undefined>;
  protected instructionValidateRequest: Signal<InstructionValidationRequest | undefined>;
  protected instruction: Signal<CreateInstructionRequestModel | undefined>;
  protected allowedOptions: Signal<AllowedOptionType[] | undefined>;

  get details(): ParcelDetails | undefined {
    return this.parcel();
  }

  get isImport(): boolean {
    return this.details?.addressInfo?.from?.countryCode !== 'NL';
  }

  get isAnnounced(): boolean {
    return this.parcel()?.state === ParcelState.Announced;
  }

  get isShipped(): boolean {
    return this.parcel()?.state === ParcelState.InRegion || this.parcel()?.state === ParcelState.Received;
  }

  get isExport(): boolean {
    return this.details?.addressInfo?.to?.countryCode !== 'NL';
  }

  get canShowOptions(): boolean {
    const maxOptionsOnScreen = 3;
    const numberOfOptions = this.allowedOptions()?.length ?? 0;
    return numberOfOptions > 0 && numberOfOptions < maxOptionsOnScreen;
  }

  get rootWidget(): WidgetType {
    return this.canShowOptions ? WidgetType.ParcelDetails : WidgetType.InstructionSelectDeliveryOption;
  }

  get isAddresseeOnly(): boolean {
    const services = this.details?.services ?? '';
    return services.includes('AddresseeOnlyService') || services.includes('ADO');
  }

  get signedBy(): string | undefined {
    return this.details?.subState !== ParcelSubState.DeliveredAtNeighbors
      ? this.details?.deliveryScanInfo.signedBy
      : undefined;
  }

  get isSignedByNeighbours(): string | undefined {
    return this.details?.subState === ParcelSubState.DeliveredAtNeighbors
      ? this.details?.deliveryScanInfo.signedBy
      : undefined;
  }

  get depositPermission(): boolean | undefined {
    return this.details?.deliveryScanInfo.name === 'Delivered with deposit permission';
  }

  get isDelivered(): boolean {
    return this.details?.state === ParcelState.Delivered;
  }

  get isNotDelivered(): boolean {
    return this.details?.state === ParcelState.NotDelivered;
  }

  get showDepositLocation(): boolean {
    return this.details?.state !== ParcelState.NotDelivered;
  }

  get lastPhysicalScan(): Scan | undefined {
    if (this.details?.scans) {
      return lastOrDefault(this.details?.scans, (scan) => scan?.scanType === ScanType.DeliveryAttempt, undefined);
    }
    return undefined;
  }

  get isDeliveredAtCollectionPoint() {
    return this.parcel()?.deliveryListInfo?.isAPL || this.parcel()?.deliveryListInfo?.isParcelShop;
  }

  get atParcelShop(): boolean {
    return this.details?.deliveryScanInfo.parcelShop !== null;
  }

  get atApl(): boolean {
    return this.parcel()?.deliveryListInfo?.isAPL === true;
  }

  get isDeliveredAtNeighbours(): boolean {
    return this.isDelivered && this.details?.subState === ParcelSubState.DeliveredAtNeighbors;
  }

  get isRetour(): boolean {
    return this.details?.isRetour === true;
  }

  get isReturnedToSender(): boolean {
    return this.details?.subState === ParcelSubState.ReturnedToSender;
  }

  get isOutForDelivery(): boolean {
    return this.details?.state === ParcelState.OutForDelivery && !this.isRetour;
  }

  get isSignedBy(): boolean {
    return (this.signedBy && this.signedBy.length > 0) as boolean;
  }

  get showSignedNote(): boolean {
    return (
      this.isDelivered &&
      !(
        this.depositLocation ||
        this.isRetour ||
        this.atParcelShop ||
        this.atApl ||
        this.isDeliveredAtNeighbours ||
        this.isDeliveredAtCollectionPoint ||
        this.details?.subState === ParcelSubState.CollectedFromParcelShop ||
        this.details?.subState === ParcelSubState.CollectedFromApl ||
        this.details?.subState === ParcelSubState.NotCollectedAtParcelShop ||
        this.details?.subState === ParcelSubState.NotCollectedFromApl ||
        this.details?.subState === ParcelSubState.DeliveredAtApl ||
        this.details?.subState === ParcelSubState.DeliveredAtParcelShop
      )
    );
  }

  get isSustainableDelivery(): boolean {
    return this.details?.state == ParcelState.OutForDelivery && this.details?.deliveryListInfo?.sustainableDelivery;
  }

  get isDeliveredSustainable(): boolean {
    return this.details?.state == ParcelState.Delivered && this.details?.deliveryListInfo?.sustainableDelivery;
  }

  get depositLocation() {
    return this.parcel()?.deliveryListInfo?.depositLocation;
  }

  get remainingStops(): number {
    return this.details?.deliveryStatus.remainingStops ?? 0;
  }

  get isRealtimeTracking(): boolean {
    return this.details?.deliveryStatus.isRealTimeTracking ?? false;
  }

  get notDelivered() {
    return this.parcel()?.state === ParcelState.NotDelivered;
  }

  get inTransit() {
    return (
      !this.parcel()?.isRetour &&
      (this.parcel()?.state === ParcelState.Announced ||
        this.parcel()?.state === ParcelState.Received ||
        this.parcel()?.state === ParcelState.InRegion ||
        this.parcel()?.state === ParcelState.OutForDelivery)
    );
  }

  get showEta(): boolean {
    return (this.parcel()?.showEta && !this.isDeliveredAtCollectionPoint) ?? false;
  }

  get canChangeDelivery() {
    const allowedActions: ActionTypes | number = this.instructionValidateResult()?.allowedActions ?? 0;
    return allowedActions > 0 && allowedActions !== ActionTypes.TrackTrace;
  }

  get canDeliverToApl() {
    const allowedActions: ActionTypes | number = this.instructionValidateResult()?.allowedActions ?? 0;
    return (allowedActions & ActionTypes.CollectAtApl) === ActionTypes.CollectAtApl;
  }

  get canDeliverToParcelShop() {
    const allowedActions: ActionTypes | number = this.instructionValidateResult()?.allowedActions ?? 0;
    return (allowedActions & ActionTypes.CollectAtParcelShop) === ActionTypes.CollectAtParcelShop;
  }

  get canDeliverToDepot() {
    const allowedActions: ActionTypes | number = this.instructionValidateResult()?.allowedActions ?? 0;
    return (allowedActions & ActionTypes.CollectAtDepot) === ActionTypes.CollectAtDepot;
  }

  get canDeliverToCollectionPoint() {
    return this.canDeliverToApl || this.canDeliverToParcelShop || this.canDeliverToDepot;
  }

  get canRefuseParcel() {
    return isAllowedOption(AllowedOptionType.canRefuseParcel, this.allowedOptions());
  }

  get canDeliverWithDepositPermission() {
    return isAllowedOption(AllowedOptionType.canDeliverWithDepositPermission, this.allowedOptions());
  }

  get isPickup() {
    const parcels = this.instructionValidateResult()?.parcels;
    if (parcels) {
      return parcels.find((e) => e.isPickup === true);
    }
    return false;
  }

  get canDeliverAtOtherMoment() {
    return isAllowedOption(AllowedOptionType.canDeliverAtOtherMoment, this.allowedOptions());
  }

  get canDeliverAtNeighbours() {
    return isAllowedOption(AllowedOptionType.canDeliverAtNeighbours, this.allowedOptions());
  }

  get canDeliverToAlternateAddress() {
    return isAllowedOption(AllowedOptionType.canDeliverToAlternateAddress, this.allowedOptions());
  }

  get hasMoreParcelsInShipment(): boolean {
    return (this.parcel()?.parcels?.length ?? 0) > 1;
  }

  constructor(protected readonly store: Store) {
    super();
    this.parcel = toSignal(this.store.select(AppState.parcelDetails));
    this.widget = toSignal(this.store.select(AppState.widget));
    this.instructionValidateResult = toSignal(this.store.select(AppState.instructionValidationResult));
    this.instructionValidateRequest = toSignal(this.store.select(AppState.instructionValidationRequest));
    this.instruction = toSignal(this.store.select(AppState.instruction));
    this.allowedOptions = toSignal(this.store.select(AppState.allowedOptions));
  }

  createInstructionModel(): CreateInstructionRequestModel | undefined {
    const instructionValidationResponse = this.instructionValidateResult();
    const instructionValidationRequest = this.instructionValidateRequest();
    if (!instructionValidationResponse || !instructionValidationRequest) return undefined;

    return {
      instructionType:
        instructionValidationResponse.instructionType ??
        instructionValidationRequest.instructionType ??
        InstructionType.FlexDelivery,
      actionType: ActionTypes.RetryDelivery,
      depositRemarks: '',
      allowDeposit: false,
      parcels: [this.getParcels(instructionValidationResponse.parcels)[0]],
      zipCode: instructionValidationResponse.leadingAddress.zipCode,
      houseNo: instructionValidationResponse.leadingAddress.houseNo ?? '',
      actionDate: new Date().toISOString().split('.')[0],
      psChosenCustNo: '',
      depot: '',
      allowNeighbours: false,
      neighboursHouseNo: '',
      alternateAddress: {
        addressType: InstructionAddressType.Alternative,
        addressCategory: AddressCategoryType.Private,
        addressFunction: '',
        name1: '',
        name2: '',
        name3: '',
        street1: '',
        street2: '',
        houseNo: '',
        houseNoAdd: '',
        zipCode: '',
        city: '',
        countryA2: '',
        contactPerson: '',
        phoneNo: '',
        email: '',
        locCode: '',
      },
      contactName: '',
      contactEmail: '',
      contactPhoneNo: '',
      timeSpanStart: '00:00',
      timeSpanEnd: '23:59',
      csiChecksum: instructionValidationRequest.checksum ?? '',
      csiData: instructionValidationRequest.csiData ?? '',
      deeplinkId: instructionValidationRequest.iddeeplink ?? '',
      note1: '',
      note2: '',
      aplId: '',
      ownHouseNumber: '',
      email: instructionValidationRequest.email ?? '',
      notCardId: instructionValidationRequest.ntbCode ?? '',
    };
  }

  getParcels(parcels: ParcelModel[]): ParcelIdentifierModel[] {
    return parcels.map((parcel) => ({
      nlPclNo: parcel.nlpclNo,
      jobDate: parcel.jobDate,
      uniqueNo: parcel.uniqueNo,
    }));
  }
}
