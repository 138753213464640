var geojsonCoords = require('@mapbox/geojson-coords'),
  traverse = require('traverse'),
  extent = require('@mapbox/extent');
var geojsonTypesByDataAttributes = {
  features: ['FeatureCollection'],
  coordinates: ['Point', 'MultiPoint', 'LineString', 'MultiLineString', 'Polygon', 'MultiPolygon'],
  geometry: ['Feature'],
  geometries: ['GeometryCollection']
};
var dataAttributes = Object.keys(geojsonTypesByDataAttributes);
module.exports = function (_) {
  return getExtent(_).bbox();
};
module.exports.polygon = function (_) {
  return getExtent(_).polygon();
};
module.exports.bboxify = function (_) {
  return traverse(_).map(function (value) {
    if (!value) return;
    var isValid = dataAttributes.some(function (attribute) {
      if (value[attribute]) {
        return geojsonTypesByDataAttributes[attribute].indexOf(value.type) !== -1;
      }
      return false;
    });
    if (isValid) {
      value.bbox = getExtent(value).bbox();
      this.update(value);
    }
  });
};
function getExtent(_) {
  var ext = extent(),
    coords = geojsonCoords(_);
  for (var i = 0; i < coords.length; i++) ext.include(coords[i]);
  return ext;
}