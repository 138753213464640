<div class="card-tt-details">
  <div class="widget-container">
    <div class="primary-column" [class.mobile]="isMobile()">
      <div class="primary-content" [class.desktop]="!isMobile()">
        <div class="title spotlight-blue" translate="">Instruction.Aci.InvalidAddress</div>
        <div class="card">
          <div class="spotlight-black" translate>Instruction.Aci.EnterEmail</div>
          <form [formGroup]="form">
            <div class="input">
              <!-- E-mail -->
              <div
                class="form-group form-group_with_icon form-group_with_feedback"
                [class.is-invalid]="isInvalidControl('email')"
              >
                <div class="form-field-icon-holder invalid-value-missing">
                  <input
                    id="email"
                    data-cy="tbAddressemail"
                    formControlName="email"
                    type="text"
                    class="email form-control"
                    autocomplete="email"
                    [placeholder]="placeholderEmail"
                    appTrimInput
                    #zip
                    minlength="4"
                    maxlength="320"
                  />
                </div>
                <ng-container *ngIf="isInvalidControl('email')">
                  <div
                    *ngIf="isControlError('email', 'required')"
                    class="feedback invalid-value-missing"
                    [translateParams]="{
                      fieldName: ('Instruction.Email' | translate)
                    }"
                    translate
                  >
                    Validations.Required.Field
                  </div>
                  <div *ngIf="isControlError('email', 'pattern')" class="feedback" translate>
                    Validations.Required.EmailPattern
                  </div>
                  <div *ngIf="isControlError('email', 'minlength')" class="feedback" translate>
                    Validations.Required.EmailPattern
                  </div>
                </ng-container>

                <ng-container *ngIf="notFound()">
                  <div class="feedback" translate>Instruction.Aci.NotFound</div>
                </ng-container>

                <button mat-button class="btn btn-primary" (click)="onSubmit()">
                  {{ 'Instruction.Submit' | translate }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
