import { Route } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { RouteTrackAndTraceComponent } from './components/routing/route-track-and-trace.component';
import { DefaultUrlSerializer, UrlTree } from '@angular/router';

export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
    override parse(url: string): UrlTree {
      const path = url.split('?')[0];
      const query = url.split('?')[1] || '';
      return super.parse(path.toLowerCase() + (query !== '' ? `?${query}`: ''));
    }
}

export const appRoutes: Route[] = [
  {
    path: '',
    component: HomeComponent,
  },
  { path: 'track-and-trace', component: RouteTrackAndTraceComponent },
  {
    path: 'instruction',
    component: HomeComponent,
  },
  {
    path: 'instruction/cslink',
    component: HomeComponent,
  },
  {
    path: 'instruction/recipient',
    component: HomeComponent,
  },
  {
    path: 'tracking',
    component: HomeComponent,
  },
  {
    path: 'tracking/ttlink',
    component: HomeComponent,
  },
  {
    path: 'tracking/deallink',
    component: HomeComponent,
  },
  {
    path: 'deeplink/:deeplink/:action',
    component: HomeComponent,
  },
  {
    path: 'ai',
    component: HomeComponent,
  },
  {
    path: 'ai/recipient',
    component: HomeComponent,
  },
  {
    path: 'ai/cslink',
    component: HomeComponent,
  },
  {
    path: 't',
    component: HomeComponent,
  },
  {
    path: 'locator/shop',
    component: HomeComponent,
  },
  {
    path: 'parcel-shop',
    component: HomeComponent,
  },
  /* TODO 
  {
    path: 'aci/confirm-address',
    component: InstructionAciComponent,
  },*/
  { path: '**', redirectTo: '' },
];
