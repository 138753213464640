import { ChangeDetectionStrategy, Component, computed, Input, Signal } from '@angular/core';
import { VERSION } from '../../environments/version';
import { environment } from '../../environments/environment';
import { Store } from '@ngxs/store';
import { toSignal } from '@angular/core/rxjs-interop';
import { AppState } from '../../state/app.state';
import { NotificationModel, WidgetType } from '../../state/app.model';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Constants } from '../../models/constants';
import { LanguageService } from '@nx/language';
import { SetActiveWidgetAction } from '../../state/app.actions';
import { MatDialog } from '@angular/material/dialog';
import { WebnotificationDetailsComponent } from '../../components/notifications/webnotification-details.component';
import { WebNotification } from '../../models/common.model';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs';

@Component({
  selector: 'app-toolbar-widget',
  templateUrl: './toolbar-widget.component.html',
  styleUrl: './toolbar-widget.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarWidgetComponent {
  @Input() top = 0;
  @Input() left = 0;

  breakpoint = toSignal(this.breakpointObserver.observe([Constants.BreakPointMobile]));

  isMobile: Signal<boolean> = computed(() => this.breakpoint()?.matches ?? false);

  notification: Signal<NotificationModel | undefined>;

  get currentLang(): string {
    return this.languageService.currentLanguage;
  }

  get version(): string {
    return VERSION.hash;
  }

  get environmentName(): string {
    return environment.environmentName;
  }

  get hideVersion(): boolean {
    return this.environmentName === 'prod';
  }

  get hasNotifications(): boolean {
    return (this.notification()?.notifications?.length ?? 0) > 0;
  }

  get showNotification(): boolean {
    return this.hasNotifications && (this.notification()?.visible ?? false);
  }

  get showVersion(): boolean {
    if (this.isMobile() && this.showNotification) {
      return false;
    }
    return true;
  }

  get showLanguageSelector(): boolean {
    if (this.isMobile() && this.showNotification) {
      return false;
    }
    return true;
  }

  webNotification: Signal<WebNotification | undefined> = computed(() => {
    const notifications = this.notification()?.notifications;
    const language = this.language() ?? this.translate.currentLang;
    if (notifications && notifications.length > 0) {
      return notifications.find((m) => m.culture.startsWith(language));
    }
    return undefined;
  });

  language = toSignal(
    this.translate.onLangChange.pipe(map(() => this.translate.currentLang))
  );

  get message(): string {
    return this.webNotification()?.title ?? '';
  }

  get details(): string {
    return this.webNotification()?.text ?? '';
  }

  constructor(
    private readonly store: Store,
    private readonly breakpointObserver: BreakpointObserver,
    private languageService: LanguageService,
    private translate: TranslateService,
    private readonly dialog: MatDialog
  ) {
    this.notification = toSignal(this.store.select(AppState.notification));
  }

  onDutch() {
    this.languageService.useLanguage('nl');
  }

  onEnglish() {
    this.languageService.useLanguage('en');
  }

  showShopLocator() {
    this.store.dispatch(new SetActiveWidgetAction(WidgetType.ShopLocator));
  }

  onShowNotification(){
    this.dialog.open(WebnotificationDetailsComponent, {
      data: {
        title: this.message,
        html: this.details,
      },
      autoFocus: false,
      width: '700px',
    });
  }
}
