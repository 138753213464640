import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseTrackAndTraceComponent } from '../base-track-and-trace.component';

@Component({
  selector: 'app-delivery-note',
  templateUrl: './delivery-note.component.html',
  styleUrl: './delivery-note.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveryNoteComponent extends BaseTrackAndTraceComponent {
}
