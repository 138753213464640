<div class="notification shadow-box">
  <div class="close-notification" (click)="hideNotification()">
    <mat-icon
      aria-hidden="false"
      aria-label="Close"
      fontIcon="cancel"
    ></mat-icon>
  </div>
  <span class="message">{{ message }}</span>
  <a href="#" class="read-more message" (click)="openDetails(); (false)" translate
    >Notifications.ReadMore</a
  >
</div>
