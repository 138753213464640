import { ChangeDetectionStrategy, Component, computed, Signal } from '@angular/core';
import { Store } from '@ngxs/store';
import { BaseTrackAndTraceComponent } from '../../track-and-trace/summary/base-track-and-trace.component';
import { BreakpointObserver } from '@angular/cdk/layout';
import { toSignal } from '@angular/core/rxjs-interop';
import { Constants } from '../../../models/constants';
import { SetActiveWidgetAction, SetInstructionAction } from '../../../state/app.actions';
import { WidgetType } from '../../../state/app.model';
import { ActionTypes } from '../../../models/gls-info.model';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-instruction-refuse',
  templateUrl: './instruction-refuse.component.html',
  styleUrls: ['./instruction-refuse.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InstructionRefuseComponent extends BaseTrackAndTraceComponent {
  form: FormGroup;

  breakpoint = toSignal(this.breakpointObserver.observe([Constants.BreakPointMobile]));

  isMobile: Signal<boolean> = computed(() => this.breakpoint()?.matches ?? false);

  constructor(store: Store, private readonly breakpointObserver: BreakpointObserver, public readonly fb: FormBuilder) {
    super(store);
    this.form = this.fb.group({
      reason: [''],
    });
  }

  goBack() {
    this.store.dispatch(new SetActiveWidgetAction(this.rootWidget));
  }

  confirm() {
    let instruction = this.createInstructionModel();
    if (!instruction) return;
    instruction.actionType = ActionTypes.RefusePackage;
    instruction.note1 = this.form.get('reason')?.value ?? '';
    this.store.dispatch([
      new SetInstructionAction(instruction),
      new SetActiveWidgetAction(WidgetType.InstructionConfirm),
    ]);
  }
}
