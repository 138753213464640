import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LanguageSettings } from './language-settings';
import { VERSION } from '../environments/version';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private readonly _defaultLanguageKey = 'Language';

  constructor(
    public readonly translate: TranslateService,
    public readonly title: Title,
    private readonly settings: LanguageSettings,
    private readonly http: HttpClient
  ) {
    translate.addLangs(settings.availableLanguages);

    this.setDefaultLanguage();
    this.setTitle();

    translate.onLangChange.subscribe(() => {
      this.setTitle();
    });
  }

  get currentLanguage(): string {
    return this.translate.currentLang;
  }

  get availableLanguages(): string[] {
    return this.translate.getLangs();
  }

  public getDefaultLanguage(): string {
    return this.translate.defaultLang;
  }

  public isValidLanguage(language: string): boolean {
    return this.availableLanguages.includes(language);
  }

  private setDefaultLanguage(): void {
    const defaultLang = localStorage.getItem(this._defaultLanguageKey) ?? 'nl';

    this.translate.setDefaultLang(defaultLang);
    this.useLanguage(defaultLang);
  }

  public setTitle(): void {
    this.translate.get('App.Title').subscribe((res: string) => {
      this.title.setTitle(res);
    });
  }

  public useLanguage(lang: string): void {
    localStorage.setItem(this._defaultLanguageKey, lang);

    this.translate.use(lang);
  }

  public addTranslation(lang: string, translations: any): void {
    this.translate.setTranslation(lang, translations, true);
  }

  public registerModule(moduleName: string, loader?: TranslateLoader): void {
    if (!loader) {
      const version = VERSION;
      loader = new TranslateHttpLoader(this.http, '/assets/i18n/' + moduleName + '/', '.json?v=' + version.hash);
    }

    this.settings.availableLanguages.forEach((language: string) => {
      loader?.getTranslation(language).subscribe((translation) => {
        this.addTranslation(language, translation);
        this.setTitle();
      });
    });
  }
}
