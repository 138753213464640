import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseTrackAndTraceComponent } from '../../base-track-and-trace.component';

@Component({
  selector: 'app-parcel-progress',
  templateUrl: './parcel-progress.component.html',
  styleUrls: ['./parcel-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParcelProgressComponent extends BaseTrackAndTraceComponent {
  get deliveredTitle(): string {
    if (this.isRetour) return "TrackAndTrace.Progress.Retour";
    if (this.isNotDelivered) return "TrackAndTrace.Progress.NotDelivered";
    return "TrackAndTrace.Progress.Delivered";
  }

}
