import { ParcelState, ParcelSubState, TrackAndTraceStep } from './track-and-trace.model';

export interface Address {
  zipcode: string;
}

export enum NtbRetryDeliveryAt {
  SameAddress,
  Neighbours,
}

export interface ExecutionContext {
  action: ActionType;
  instructionType?: InstructionType;
  code?: string;
  date?: string;
  custNo?: string;
  zipCode?: string;
  shipment?: string;
  depotNumber?: string;
  username?: string;
  userType?: string;
  reference?: string;
  checksum?: string;
  csiData?: string;
  iddeeplink?: string | null;
}

export interface InstructionConstraint {
  isDeeplinkMandatory: boolean;
}

export enum DeepLinkType {
  Aci = 'aci',
  AplNotDelivered = 'aplnotdelivered',
  Otfi = 'otfi',
  Nti = 'nti',
  Fdi = 'fdi',
}

export enum ActionType {
  Unknown = 'unknown',
  TrackAndTraceLink = 'ttlink',
  Instruction = 'Instruction',
}

export enum InstructionTypeString {
  ShopDelivery = 'shd',
  FlexDelivery = 'fld',
  ShopDeliveryPlus = 'sdp',
  Aci = 'aci',
  AplNotDelivered = 'aplnotdelivered',
}

export interface InstructionValidationResultModel {
  resultCode: ValidationResultCode;
  allowedActions: ActionTypes | number;
  hints: DeliveryStatusExplanation;
  targetAddress: AddressModel;
  leadingAddress: AddressModel;
  parcels: ParcelModel[];
  notAtHomeDate: string;
  deliverAtInfo: DeliveryInfo;
  expiryDate: string;
  depots: Depot[];
  instructionType: InstructionType | undefined | null;
  deepLinkId: string | undefined | null;
  isOutForDelivery: boolean | undefined | null;
}

export interface ParcelModel {
  nlpclNo: string;
  uniqueNo: string;
  jobDate: string;
  initialDate?: string;
  reference: string;
  isPickup: boolean;
  consignor: ConsignorModel;
}

export interface ConsignorModel {
  country: string;
  city: string;
  name: string;
}

export interface AddressModel {
  addressType: InstructionAddressType;
  addressCategory: AddressCategoryType;
  addressFunction: string;
  name1: string;
  name2: string;
  name3: string;
  street1: string;
  street2: string;
  houseNo: string;
  houseNoAdd: string;
  zipCode: string;
  city: string;
  countryA2: string;
  contactPerson: string;
  phoneNo: string;
  email: string;
  locCode: string;
}

export enum AddressCategoryType {
  Private,
  Business,
  Unknown,
  _0,
  _1,
  _2,
  _N,
}

export enum InstructionAddressType {
  Unknown,
  Alternative,
  Consumer,
  Delivery,
  Pickup,
  Requestor,
  Return,
  PtvAlternate,
  PtvDelivery,
  PtvPickup,
  PtvRequestor,
  PtvReturn,
}

export interface DeliveryInfo {
  eventInfo: string;
  eventValue: string;
}

export enum ValidationResultCode {
  Valid,
  Invalid,
  Expired,
  InstructionAlreadyExists,
  Delivered,
  RequestHouseNo,
  NotFound,
  InvalidZipCode,
  FeatureNotEnabled,
  DepotNotInPilot,
  ProductRuleMismatch,
  NoRttOrNotEnoughStops,
  NoActiveHandHeldSession,
  MoreThanOneShipment,
  AlreadySendMessageToDriver,
}

// Flags
export enum InstructionActions {
  Depot = 1,
  Refuse = 2,
  Retry = 4,
  ParcelShop = 8,
}

export enum ActionTypes {
  CollectAtDepot = 1,
  RefusePackage = 2,
  RetryDelivery = 4,
  CollectAtParcelShop = 8,
  DeliverToAlternateAddress = 16,
  TrackTrace = 32,
  RetryDeliveryWithDepositPermission = 64,
  CollectAtApl = 128,
  DeliverAtNeighbours = 256,
}

export enum DeliveryStatusExplanation {
  None = 0,
  NotAllowedZipCodeIsPoBox = 1,
  NotAllowedForPickUp = 2,
  NotAllowedBecauseNoDropOff = 4,
}

export enum InstructionOption {
  ParcelShop = 1,
  RetryWithDepositPermision = 2,
  Retry = 3,
  Refuse = 4,
  AlternateAddress = 5,
}

export interface SearchParcelShopsRequest {
  zipCode: string;
  houseNr: string;
  quantity: number;
  culture: string;
  instruction: boolean;
  date: string;
  filter: ParcelShopFilter;
  locatorFilter: LocatorFilter;
}

export interface SearchOpenParcelShopsRequest extends SearchParcelShopsRequest {
  originalZipCode: string;
}

export interface ParcelShopModel {
  id: string;
  custOpsNo: string;
  name: string;
  name1: string;
  name2: string;
  location: GlsInfoLocation;
  website: string;
  info1: string;
  info2: string;
  businessHours: BusinessHours[];
  vacations: Vacation[];
  available: boolean;
  isActive: boolean;
  nextOpenDate: string;
  isOnVacation: boolean;
  isDepot: boolean;
  is24X7Open?: boolean;
}

export interface ParcelCollectionPoint extends ParcelShopModel {
  isApl?: boolean;
}

export interface ParcelShopDialogData {
  title: string;
  isSelectable: boolean;
  filter: ParcelShopFilter;
  locatorFilter: LocatorFilter;
  canChooseApl: boolean;
  canFilterDepots: boolean;
}

export interface ParcelShopRouteDialogData {
  parcelCollectionPoint: ParcelCollectionPoint;
}

export interface BusinessHours {
  dayOfWeek: DayOfWeek;
  openTime: string;
  closeTime: string;
}

export enum DayOfWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export interface BusinessOpening {
  is24X7Open: boolean;
  isOpen: boolean;
  info: string;
}

export interface Vacation {
  end: string;
  noDropOffDaysBefore: number;
  start: string;
}

export interface GlsInfoLocation {
  street: string;
  houseNo: string;
  houseNoAddition: string;
  zipCode: string;
  city: string;
  country: string;
  gelocation: GeoLocation;
  distance: Distance;
}

export interface GeoLocation {
  latitude: number;
  longitude: number;
}

export interface Distance {
  kilometers: number;
  minutes: number;
}

export enum InstructionType {
  FlexDelivery = 0,
  ShopDelivery = 1,
  ShopDeliveryPlus = 2,
  NotAtHome = 3,
  CSI = 4,
  ACI = 5,
  ShopDepositReceipt = 6,
  OTFI = 7,
  AplNotDelivered = 8,
  AciAdresConfirmed = 99,
}

export interface ParcelIdentifierModel {
  nlPclNo: string;
  uniqueNo: string;
  jobDate: string;
}

export enum InstructionResultCode {
  Succeeded,
  NotAllowed,
}

export interface InstructionResult {
  resultCode: InstructionResultCode;
  errorCode: string;
}

export interface GlsInfoTrackAndTraceStep extends TrackAndTraceStep {
  subStatus: ParcelSubState;
}

export interface CreateInstructionRequestModel {
  instructionType: InstructionType;
  actionType: ActionTypes;
  parcels: ParcelIdentifierModel[];
  zipCode: string;
  houseNo: string;
  actionDate?: string;
  notCardId: string;
  psChosenCustNo: string;
  depot: string;
  allowNeighbours: boolean;
  neighboursHouseNo: string;
  alternateAddress: AddressModel;
  contactName: string;
  contactEmail: string;
  contactPhoneNo: string;
  ownHouseNumber: string;
  allowDeposit: boolean;
  depositRemarks: string;
  timeSpanStart: string;
  timeSpanEnd: string;
  note1: string;
  note2: string;
  csiChecksum: string;
  csiData: string;
  aplId: string;
  deeplinkId?: string;
  email?: string;
}

export interface SubmitCollectAtParcelCollectionPointConfirmationActionRequest {
  psChosenCustNo: string;
  contactName: string;
  contactEmail: string;
  contactPhoneNo: string;
  aplId?: string;
}

export interface SubmitCollectAtDepotConfirmationActionRequest {
  depot: string;
  psChosenCustNo: string;
  contactEmail: string;
  contactPhoneNo: string;
}

export interface SubmitRetryConfirmationActionRequest {
  allowNeighbours: boolean;
  neighboursHouseNo: string;
  actionDate?: string;
  timeSpanStart: string;
  timeSpanEnd: string;
  contactEmail: string;
  contactPhoneNo: string;
  ownHouseNumber: string;
  ownZipCode: string;
}

export interface SubmitRetryWithDepositPermissionConfirmationActionRequest {
  allowDeposit: boolean;
  depositRemarks: string;
  contactEmail: string;
  contactPhoneNo: string;
  ownHouseNumber: string;
  ownZipCode: string;
}

export interface SubmitRefusalConfirmationActionRequest {
  reason: string;
  contactEmail: string;
  contactPhoneNo: string;
}

export interface SubmitAlternateAddressConfirmationActionRequest {
  contactEmail: string;
  contactPhoneNo: string;
  actionDate: string;
  alternateAddress: AddressModel;
}

export interface DateAndTimeFrames {
  date: string;
  timeFrames: TimeFrame[];
}

export interface TimeFrame {
  startTime: string;
  endTime: string;
}

export interface DateTimeFrame {
  date: string;
  timeFrame: TimeFrame;
}

export interface Depot {
  locCode: string;
  name: string;
}

export enum InstructionScope {
  Parcel = 0,
  Shipment = 1,
}

export interface CsiRequest {
  code: string;
  csiChecksum: string;
  csiData: string;
  validateEntireShipment: boolean;
}

export enum ScanEvent {
  Delivered = 3,
  OutForDelivery = 11,
  NotOutForDelivery = 12,
}

export enum ScanEventReasonOutForDelivery {
  Normal = 0,
}

export enum ScanEventReasonDelivered {
  ParcelShop = 124,
}

export interface GetExpectedDeliveryRequest {
  instructionType: InstructionType;
  actionType: ActionTypes;
  checksum?: string;
  data?: string;
  code: string;
  zipCode: string;
  houseNo: string;
  notCardId?: string;
  targetZipCode?: string;
  parcelShopCustNo?: string;
  email?: string;
  deepLinkId?: string;
}

export interface ExpectedDeliveryResult {
  deliveryDates: string[];
  availableDays: DateAndTimeFrames[];
}

export interface SupportRequest {
  houseNumber: string;
  name: string;
  phoneNumber: string;
  email: string;
  code: string;
  zipCode: string;
  instructionType: InstructionType;
}

export interface ValidateAciRequest {
  parcelNumber: string;
  email: string;
}

export interface ValidationEndPoint {
  url: string;
  request?: ValidateAciRequest;
}

export interface ApiCallResult {
  title: string;
  isSuccess: boolean;
  message: string;
}

export enum ParcelShopFilter {
  None = 0,
  ParcelShop = 1,
  Depot = 2,
}

export enum LocatorFilter {
  InstructionSite = 0,
  ParcelShopLocator = 1,
}

export interface FeedbackControl {
  type: FeedbackControlType;
  label: string;
  name?: string;
  value?: string;
  required?: boolean;
  complaint?: boolean;
  reason?: string;
  assignTo?: string;
  actions?: FeedbackAction[];
  isUserInput?: boolean;
  state?: ParcelState;
  parcelState?: number;
  parcelSubstate?: number;
  childControls?: FeedbackControl[];
  endOfSession?: boolean;
}

export interface FeedbackAction {
  endOfSession: boolean;
  labels: string[];
  parcelStateRequired?: boolean;
  ntbCodeRequired?: boolean;
  childControls?: FeedbackControl[];
}

export enum FeedbackControlType {
  Text = 'text',
  Input = 'input',
}

export interface FeedbackRequest {
  email: string;
  complaint: boolean;
  feedback?: string;
  parcelNumber?: string;
  route?: string;
  token?: string;
  reason?: string;
  assignTo?: string;
}

export interface AddressCheckResult {
  isPostOfficeBox: boolean;
  zipCode?: string;
  houseNumber?: string;
  street?: string;
  city?: string;
}

export interface AddressLookUpResponse {
  street: string;
  city: string;
  postOfficeBox: string;
}
