import { ChangeDetectionStrategy, Component, computed, Signal } from '@angular/core';
import { Store } from '@ngxs/store';
import { BaseTrackAndTraceComponent } from '../../track-and-trace/summary/base-track-and-trace.component';
import { BreakpointObserver } from '@angular/cdk/layout';
import { toSignal } from '@angular/core/rxjs-interop';
import { Constants } from '../../../models/constants';
import { SetActiveWidgetAction } from '../../../state/app.actions';
import { WidgetType } from '../../../state/app.model';
import { InstructionResult, InstructionResultCode, ValidationResultCode } from '../../../models/gls-info.model';
import { AppState } from '../../../state/app.state';

@Component({
  selector: 'app-instruction-result',
  templateUrl: './instruction-result.component.html',
  styleUrls: ['./instruction-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InstructionResultComponent extends BaseTrackAndTraceComponent {
  breakpoint = toSignal(this.breakpointObserver.observe([Constants.BreakPointMobile]));

  isMobile: Signal<boolean> = computed(() => this.breakpoint()?.matches ?? false);

  protected result: Signal<InstructionResult | undefined>;

  get succeeded(): boolean {
    return this.result()?.resultCode === InstructionResultCode.Succeeded;
  }

  get errorDescription(): string {
    const errorCode = this.result()?.errorCode;
    if (!errorCode) return 'Instruction.ErrorCodes.DefaultMessage';
    switch (+errorCode) {
      case ValidationResultCode.NoActiveHandHeldSession:
        return 'Instruction.ErrorCodes.NoActiveHandheldSession';

      case ValidationResultCode.NoRttOrNotEnoughStops:
        return 'Instruction.ErrorCodes.NoRttOrNotEnoughStops';

      case ValidationResultCode.Delivered:
        return 'Instruction.ErrorCodes.ParcelHasBeenDelivered';

      default:
        return 'Instruction.ErrorCodes.DefaultMessage';
    }
  }

  constructor(store: Store, private readonly breakpointObserver: BreakpointObserver) {
    super(store);
    this.result = toSignal(this.store.select(AppState.instructionResult));
  }

  async confirm() {
    this.store.dispatch(new SetActiveWidgetAction(WidgetType.ParcelDetails));
  }
}
