import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseTrackAndTraceComponent } from '../../summary/base-track-and-trace.component';
import { ParcelCollectionPoint } from '../../../../models/gls-info.model';

@Component({
  selector: 'app-context-delivered',
  templateUrl: './context-delivered.component.html',
  styleUrl: './context-delivered.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContextDeliveredComponent extends BaseTrackAndTraceComponent {
  get collectionPoint(): ParcelCollectionPoint | undefined | null {
    const parcelshop = this.details?.deliveryScanInfo.parcelShop;
    if (parcelshop) {
      return parcelshop;
    }
    return this.details?.deliveryScanInfo?.apl;
  }
}
