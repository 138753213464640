import { Params, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import {
  ResetParcelIdentifierAction,
  SetActiveWidgetAction,
} from '../../state/app.actions';
import { WidgetType } from '../../state/app.model';

export const root = '/';

export const transformParamsWithLoweredKeys = (
  params: Params
): { [key: string]: string } => {
  const dictionary: { [key: string]: string } = Object.keys(params).reduce(
    (acc: { [key: string]: string }, item) => {
      const lowercasedKey = item.toLocaleLowerCase();
      acc[lowercasedKey] = params[item];
      return acc;
    },
    {}
  );
  return dictionary;
};

export const queryParameter = (
  paramsWithLoweredKeys: { [key: string]: string },
  key: string
): string | undefined => {
  const loweredCaseKey = key.toLocaleLowerCase();
  return paramsWithLoweredKeys[loweredCaseKey];
};

export const returnToSearchParcelWidget = (store: Store, router: Router) => {
  store.dispatch([
    new SetActiveWidgetAction(WidgetType.SearchParcel),
    new ResetParcelIdentifierAction(),
  ]);
  router.navigateByUrl(root);
};
