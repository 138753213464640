import { Injectable, NgZone } from '@angular/core';
import { Store } from '@ngxs/store';
import { SetConsentAction } from '../state/app.actions';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CookiebotService {
  constructor(private ngZone: NgZone, private store: Store) {
    this.initializeCookiebot();
  }

  private initializeCookiebot() {
    if (environment.environmentName === 'cypress') {
      return;
    }
    // @ts-expect-error ignore this warning about types
    window['gtag_enable_tcf_support'] = true;
    // @ts-expect-error ignore this warning about types
    window['__tcfapi'](
      'addEventListener',
      2,
      (tcData: any, success: boolean) => {
        this.ngZone.run(() => {
          // @ts-expect-error ignore this warning about types
          const hasMarketingConsent = window.Cookiebot.consent.marketing === true;
          const tcfTag = tcData?.tcString;

          this.store.dispatch(
            new SetConsentAction(
              success && hasMarketingConsent && tcfTag ? tcfTag : ''
            )
          );
        });
      }
    );
  }
}
