import { getEndpointPlugin } from "@smithy/middleware-endpoint";
import { getSerdePlugin } from "@smithy/middleware-serde";
import { Command as $Command } from "@smithy/smithy-client";
import { commonParams } from "../endpoint/EndpointParameters";
import { SearchPlaceIndexForTextRequestFilterSensitiveLog, SearchPlaceIndexForTextResponseFilterSensitiveLog } from "../models/models_0";
import { de_SearchPlaceIndexForTextCommand, se_SearchPlaceIndexForTextCommand } from "../protocols/Aws_restJson1";
export { $Command };
export class SearchPlaceIndexForTextCommand extends $Command.classBuilder().ep({
  ...commonParams
}).m(function (Command, cs, config, o) {
  return [getSerdePlugin(config, this.serialize, this.deserialize), getEndpointPlugin(config, Command.getEndpointParameterInstructions())];
}).s("LocationService", "SearchPlaceIndexForText", {}).n("LocationClient", "SearchPlaceIndexForTextCommand").f(SearchPlaceIndexForTextRequestFilterSensitiveLog, SearchPlaceIndexForTextResponseFilterSensitiveLog).ser(se_SearchPlaceIndexForTextCommand).de(de_SearchPlaceIndexForTextCommand).build() {}