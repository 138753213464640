import { Injectable } from '@angular/core';
import { TrackAndTraceClientService } from '../clients/track-and-trace.clients';
import { ParcelDetails } from '../models/track-and-trace.model';

@Injectable({
  providedIn: 'root',
})
export class TrackAndTraceService {
  constructor(
    private readonly trackAndTraceClient: TrackAndTraceClientService
  ) {}

  public async parcelInfo(
    parcelNo: string,
    verificationCode: string,
    culture: string,
    captcha: string
  ): Promise<ParcelDetails | undefined> {
    return this.trackAndTraceClient.parcelInfo(
      parcelNo,
      verificationCode,
      culture,
      captcha
    );
  }
}
