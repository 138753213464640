import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { WindowDimension } from '../models/window.model';

@Injectable({
  providedIn: 'root'
})
export class WindowResizeService {
  private dimensionsSubject = new BehaviorSubject<WindowDimension>({
    width: window.innerWidth,
    height: window.innerHeight
  });

  dimensions$ = this.dimensionsSubject.asObservable();

  constructor(private ngZone: NgZone) {
    this.initResizeListener();
  }

  private initResizeListener(): void {
    this.ngZone.runOutsideAngular(() => {
      fromEvent(window, 'resize')
        .pipe(debounceTime(200))
        .subscribe(() => {
          this.ngZone.run(() => {
            this.dimensionsSubject.next({
              width: window.innerWidth,
              height: window.innerHeight
            });
          });
        });
    });
  }
}
