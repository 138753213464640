<div class="info-container" *ngIf="!depositLocation">
  <ng-container *ngIf="isSignedBy">
    <div class="signed-by">
      <span class="label" translate>Parcel.Details.SignedBy</span>
      <span>: {{ signedBy }}</span>
    </div>
  </ng-container>

  <ng-container *ngIf="isSignedByNeighbours">
    <div class="signed-by">
      <span class="label" translate>Parcel.Details.SignedByNeighbours</span>
      <span>: {{ isSignedByNeighbours }}</span>
    </div>
  </ng-container>

  <ng-container *ngIf="depositPermission">
    <div class="spotlight-gray" translate>
      TrackAndTrace.DeliveredInfo.DepositPermission
    </div>
  </ng-container>
</div>
