import { ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, OnInit, Signal } from '@angular/core';
import { Store } from '@ngxs/store';
import { BaseTrackAndTraceComponent } from '../../track-and-trace/summary/base-track-and-trace.component';
import { BreakpointObserver } from '@angular/cdk/layout';
import { toSignal } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from '../../../models/constants';
import { SetActiveWidgetAction, SetInstructionAction } from '../../../state/app.actions';
import { WidgetType } from '../../../state/app.model';
import { AbstractControlOptions, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AddressFormSettings } from '../../../services/addressFormsSettings';
import { atLeastOne } from '../../../validators/at-least-one-validator';
import {
  ActionTypes,
  AddressCategoryType,
  AddressCheckResult,
  InstructionAddressType,
  InstructionType,
} from '../../../models/gls-info.model';
import { AddressBookService } from '../../../clients/addressbook.client';
import { InstructionClientService } from '../../../clients/instruction.client';
import { isToday } from 'date-fns/isToday';
import { getLocale } from '../../../services/spotlight.utils';

@Component({
  selector: 'app-instruction-alternate-address',
  templateUrl: './instruction-alternate-address.component.html',
  styleUrls: ['./instruction-alternate-address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InstructionAlternateAddressComponent extends BaseTrackAndTraceComponent implements OnInit {
  form: FormGroup;
  addressCheck?: AddressCheckResult;

  breakpoint = toSignal(this.breakpointObserver.observe([Constants.BreakPointMobile]));

  isMobile: Signal<boolean> = computed(() => this.breakpoint()?.matches ?? false);

  get isPostOfficeBox(): boolean {
    return this.addressCheck?.isPostOfficeBox ?? false;
  }

  isInvalidControl = (name: string): boolean => {
    const control = this.form.controls[name];
    return control.invalid && (control.dirty || control.touched);
  };

  isControlError = (name: string, error: string): boolean => {
    const control = this.form.controls[name];
    return control.errors && control.errors[error];
  };

  get isInvalidAddress(): boolean {
    return this.zipCode && this.houseNo && (this.street === '' || this.city === '');
  }

  get city() {
    return this.form.get('city')?.value;
  }

  get street() {
    return this.form.get('street')?.value;
  }

  get zipCode() {
    return this.form.get('zipCode')?.value;
  }

  get houseNo() {
    return this.form.get('houseNo')?.value;
  }

  availableDates: string[] = [];

  constructor(
    store: Store,
    private readonly breakpointObserver: BreakpointObserver,
    public readonly fb: FormBuilder,
    private addressBookService: AddressBookService,
    private cd: ChangeDetectorRef,
    private instructionClient: InstructionClientService,
    private translateService: TranslateService
  ) {
    super(store);

    this.form = fb.group(
      {
        date: [],
        email: [
          '',
          [
            Validators.maxLength(AddressFormSettings.Email.MaxLength),
            Validators.pattern(AddressFormSettings.Email.Regex),
            Validators.required,
          ],
        ],
        phoneNumber: [
          '',
          [
            Validators.maxLength(AddressFormSettings.PhoneNumber.MaxLength),
            Validators.pattern(AddressFormSettings.PhoneNumber.Regex),
          ],
        ],
        name1: ['', [Validators.maxLength(30), Validators.required]],
        name2: ['', [Validators.maxLength(30)]],
        contact: ['', [Validators.maxLength(30)]],
        zipCode: ['', [Validators.minLength(4), Validators.maxLength(10), Validators.required]],
        houseNo: ['', [Validators.maxLength(5), Validators.required]],
        houseNoAdd: ['', [Validators.maxLength(5)]],
        street: ['', [Validators.maxLength(30), Validators.required]],
        city: ['', [Validators.maxLength(30), Validators.required]],
      },
      { validator: atLeastOne(Validators.required, ['email', 'phoneNumber']) } as AbstractControlOptions
    );
  }

  async ngOnInit() {
    this.form.patchValue({ zipCode: this.instructionValidateResult()?.leadingAddress?.zipCode ?? '' });
    this.form.patchValue({ houseNo: this.instructionValidateResult()?.leadingAddress?.houseNo ?? '' });
    await this.getExpectedActionDate();
    if (this.availableDates.length) {
      this.form.patchValue({ date: this.availableDates[0] });
      this.cd.detectChanges();
    }
  }

  goBack() {
    this.store.dispatch(new SetActiveWidgetAction(this.rootWidget));
  }

  async onAddressChanged() {
    const houseNumber = this.houseNo;
    const zipCode = this.zipCode.replace(/\s/g, '');

    if (!zipCode.length || !houseNumber.length) {
      this.form.patchValue({ street: '' });
      this.form.patchValue({ city: '' });
      this.cd.detectChanges();
      return;
    }

    if (zipCode.length > 3) {
      await this.getExpectedActionDate();
    }

    if (!houseNumber.length) {
      this.form.patchValue({ street: '' });
      this.form.patchValue({ city: '' });
      this.cd.detectChanges();
      return;
    }

    await this.verifyAddress();
  }

  async verifyAddress() {
    const address = await this.addressBookService.getAdressFromNLZipCode(this.zipCode, +this.houseNo);
    const isPostOfficeBox = (address?.postOfficeBox ?? 'Y') === 'Y';
    if (isPostOfficeBox) {
      this.form.patchValue({ street: '' });
      this.form.patchValue({ city: '' });
    } else {
      this.form.patchValue({ street: address?.street ?? '' });
      this.form.patchValue({ city: address?.city ?? '' });
    }
    this.cd.detectChanges();
  }

  async getExpectedActionDate() {
    const instructionValidateRequest = this.instructionValidateRequest();
    const instructionValidateResult = this.instructionValidateResult();
    if (!this.details || !instructionValidateResult || !instructionValidateRequest) return;

    this.cd.detectChanges();

    const result = await this.instructionClient.getEta({
      actionType: ActionTypes.RetryDelivery,
      instructionType: instructionValidateResult.instructionType ?? InstructionType.FlexDelivery,
      code: this.details.parcelNo,
      zipCode: instructionValidateResult.leadingAddress.zipCode,
      targetZipCode: this.zipCode,
      houseNo: this.form.get('houseNo')?.value ?? '1',
      checksum: instructionValidateRequest.checksum,
      data: instructionValidateRequest.csiData,
      deepLinkId: instructionValidateRequest.iddeeplink ?? '',
    });

    this.availableDates = result?.deliveryDates ?? [];
    if (this.availableDates.length > 0) {
      this.form.patchValue({ date: this.availableDates[0] });
    }
    this.cd.detectChanges();
  }

  renderFullDate = (timestamp: string | null | undefined): string => {
    if (!timestamp) {
      return '';
    }
    const date = new Date(timestamp);
    if (isToday(date)) {
      return this.translateService.instant('Eta.today');
    }
    return date.toLocaleDateString(getLocale(this.translateService), {
      weekday: 'long',
      month: 'short',
      day: 'numeric',
    });
  };

  confirm() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    let instruction = this.createInstructionModel();
    if (!instruction) return;
    instruction.actionType = ActionTypes.DeliverToAlternateAddress;
    instruction.actionDate = this.form.get('date')?.value ?? '';
    instruction.alternateAddress = {
      addressType: InstructionAddressType.Alternative,
      addressCategory: AddressCategoryType.Private,
      addressFunction: '',
      name1: this.form.get('name1')?.value ?? '',
      name2: this.form.get('name2')?.value ?? '',
      name3: '',
      street1: this.form.get('street')?.value ?? '',
      street2: '',
      houseNo: this.form.get('houseNo')?.value ?? '',
      houseNoAdd: this.form.get('houseNoAdd')?.value ?? '',
      zipCode: this.form.get('zipCode')?.value ?? '',
      city: this.form.get('city')?.value ?? '',
      countryA2: 'NL',
      contactPerson: this.form.get('contact')?.value ?? '',
      phoneNo: this.form.get('phoneNumber')?.value ?? '',
      email: this.form.get('email')?.value ?? '',
      locCode: '',
    };
    this.store.dispatch([
      new SetInstructionAction(instruction),
      new SetActiveWidgetAction(WidgetType.InstructionConfirm),
    ]);
  }
}
