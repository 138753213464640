import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TtUiConfig } from '../environments/ttui/ttui.config';
import {
  LocatorFilter,
  ParcelShopFilter,
  ParcelShopModel,
  SearchOpenParcelShopsRequest,
  SearchParcelShopsRequest,
} from '../models/gls-info.model';
import { lastValueFrom } from 'rxjs';
import { dateCorrectionForUtcMapping } from '../services/spotlight.utils';

@Injectable({
  providedIn: 'root',
})
export class CollectionPointClientService {
  constructor(private readonly http: HttpClient) {}

  private get parcelShopApiUrl() {
    return `${TtUiConfig.ApiUrl}/${TtUiConfig.ParcelShopApi}`;
  }

  private get aplApiUrl() {
    return `${TtUiConfig.ApiUrl}/${TtUiConfig.AplApi}`;
  }
  public async searchOpenParcelShopsAndGlsDepots(
    zipCode: string,
    originalZipCode: string,
    houseNo: string,
    quantity: number,
    filter: ParcelShopFilter,
    locatorFilter: LocatorFilter
  ): Promise<ParcelShopModel[] | undefined> {
    return this.searchOpenParcelCollectionPoints(
      this.parcelShopApiUrl,
      zipCode,
      originalZipCode,
      houseNo,
      quantity,
      filter,
      locatorFilter
    );
  }

  public async searchOpenApls(
    zipCode: string,
    originalZipCode: string,
    houseNo: string,
    quantity: number,
    filter: ParcelShopFilter,
    locatorFilter: LocatorFilter
  ): Promise<ParcelShopModel[] | undefined> {
    return this.searchOpenParcelCollectionPoints(
      this.aplApiUrl,
      zipCode,
      originalZipCode,
      houseNo,
      quantity,
      filter,
      locatorFilter
    );
  }

  private async searchOpenParcelCollectionPoints(
    api: string,
    zipCode: string,
    originalZipCode: string,
    houseNo: string,
    quantity: number,
    filter: ParcelShopFilter,
    locatorFilter: LocatorFilter
  ): Promise<ParcelShopModel[] | undefined> {
    const apiErrorCode = 'Api.Errors.searchOpenParcelshops';
    const date = dateCorrectionForUtcMapping(new Date())?.toJSON() ?? '';
    const request: SearchOpenParcelShopsRequest = {
      zipCode,
      houseNr: houseNo,
      quantity,
      culture: 'nl-NL',
      instruction: true,
      date: date,
      originalZipCode,
      filter,
      locatorFilter,
    };
    const url = `${api}/search-open`;
    const response = await lastValueFrom(
      this.http.post<ParcelShopModel[]>(url, request, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        observe: 'response',
      })
    );

    if (response.status === 200) {
      return response.body ?? undefined;
    }
    if (response.status === 204) {
      return [];
    }

    throw new Error(apiErrorCode);
  }

  public async searchParcelShops(
    zipCode: string,
    houseNo: string,
    quantity: number,
    filter: ParcelShopFilter,
    locatorFilter: LocatorFilter
  ): Promise<ParcelShopModel[] | undefined> {
    return this.searchParcelCollectionPoints(this.parcelShopApiUrl, zipCode, houseNo, quantity, filter, locatorFilter);
  }

  public async searchApls(
    zipCode: string,
    houseNo: string,
    quantity: number,
    filter: ParcelShopFilter,
    locatorFilter: LocatorFilter
  ): Promise<ParcelShopModel[] | undefined> {
    return this.searchParcelCollectionPoints(this.aplApiUrl, zipCode, houseNo, quantity, filter, locatorFilter);
  }

  private async searchParcelCollectionPoints(
    api: string,
    zipCode: string,
    houseNo: string,
    quantity: number,
    filter: ParcelShopFilter,
    locatorFilter: LocatorFilter
  ): Promise<ParcelShopModel[] | undefined> {
    const apiErrorCode = 'Api.Errors.searchParcelshops';
    const date = dateCorrectionForUtcMapping(new Date())?.toJSON() ?? '';
    const request: SearchParcelShopsRequest = {
      zipCode,
      houseNr: houseNo,
      quantity,
      culture: 'nl-NL',
      instruction: true,
      date: date,
      filter,
      locatorFilter,
    };
    const url = `${api}/search`;
    const response = await lastValueFrom(
      this.http.post<ParcelShopModel[]>(url, request, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        observe: 'response',
      })
    );

    if (response.status === 200) {
      return response.body ?? undefined;
    }

    throw new Error(apiErrorCode);
  }
}
