import { ChangeDetectionStrategy, Component, computed, Signal } from '@angular/core';
import { Store } from '@ngxs/store';
import { BaseTrackAndTraceComponent } from '../../track-and-trace/summary/base-track-and-trace.component';
import { SetActiveWidgetAction } from '../../../state/app.actions';
import { WidgetType } from '../../../state/app.model';
import { BreakpointObserver } from '@angular/cdk/layout';
import { toSignal } from '@angular/core/rxjs-interop';
import { Constants } from '../../../models/constants';

@Component({
  selector: 'app-instruction-options',
  templateUrl: './instruction-options.component.html',
  styleUrls: ['./instruction-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InstructionOptionsComponent extends BaseTrackAndTraceComponent {
  breakpoint = toSignal(this.breakpointObserver.observe([Constants.BreakPointMobile]));

  isMobile: Signal<boolean> = computed(() => this.breakpoint()?.matches ?? false);

  constructor(store: Store, private readonly breakpointObserver: BreakpointObserver) {
    super(store);
  }

  goBack() {
    this.store.dispatch(new SetActiveWidgetAction(WidgetType.ParcelDetails));
  }

  deliverAtCollectionPoint() {
    this.store.dispatch(new SetActiveWidgetAction(WidgetType.InstructionCollectionPoint));
  }

  deliverWithDepositPermission() {
    this.store.dispatch(new SetActiveWidgetAction(WidgetType.InstructionDepostPermission));
  }

  deliverAtOtherMoment() {
    this.store.dispatch(new SetActiveWidgetAction(WidgetType.InstructionDeliverOtherMoment));
  }
  
  deliverAtNeigbours() {
    this.store.dispatch(new SetActiveWidgetAction(WidgetType.InstructionDeliverAtNeighbours));
  }

  refuseParcel() {
    this.store.dispatch(new SetActiveWidgetAction(WidgetType.InstructionRefuseParcel));
  }

  deliverToAlternateAddress() {
    this.store.dispatch(new SetActiveWidgetAction(WidgetType.InstructionAlternateAddress));
  }
}
