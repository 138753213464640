import { TranslateService } from '@ngx-translate/core';
import { ParcelDetails, ParcelState, ParcelSubState, RouteAddress, Scan } from '../models/track-and-trace.model';
import { formatDate } from '@angular/common';
import { getLocale } from './spotlight.utils';
import { GlsInfoLocation, ParcelCollectionPoint } from '../models/gls-info.model';

export const translationKeyStatusInfo = (scan: Scan): string =>
  scan.eventReasonDescrAltCons ? `statusInformation.${scan.eventReasonDescrAltCons}` : '';

export const getInternationalLink = (gpNo: string | undefined) =>
  `https://gls-group.eu/EU/en/parcel-tracking?match=${gpNo}`;

export const hasAddress = (details: ParcelDetails): boolean => {
  if (details.isRetour) {
    if (details.customerAddresses && details.customerAddresses.length > 0) {
      return true;
    }
  } else {
    return !details.shipmentAddress;
  }
  return false;
};

export const renderDateAndTime = (translate: TranslateService, dateTime: string | null | undefined): string => {
  if (!dateTime) return '';
  const fullDate = new Date(dateTime);
  const date = fullDate.toLocaleDateString(getLocale(translate), {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  const time = formatDate(fullDate, 'shortTime', getLocale(translate));
  return `${date} ${time}`;
};

export const isDepositPermission = (scan: Scan): boolean =>
  scan.eventReasonDescrAltCust === 'Delivered with deposit permission';

export const mergeFields = (list: (string | undefined)[]): string | undefined =>
  list.reduce((acc: string | undefined, item: string | undefined) => {
    if (item) {
      if (acc) {
        return acc + ' ' + item;
      } else {
        return item;
      }
    }
    return acc;
  }, undefined);

const isZeroOrPositiveNumber = (str: string): boolean =>
  !isNaN(Number(str)) && isFinite(Number(str)) && Number(str) >= 0;

export const fullHouseNumber = (
  houseNumber: string | undefined,
  houseNumberAddition: string | undefined
): string | undefined => {
  return !houseNumberAddition
    ? houseNumber
    : isZeroOrPositiveNumber(houseNumberAddition)
    ? `${houseNumber}-${houseNumberAddition}`
    : `${houseNumber}${houseNumberAddition}`;
};

export const nameCollectionPoint = (address: ParcelCollectionPoint | undefined | null): string | undefined =>
  mergeFields([address?.name, address?.name1, address?.name2]);

export const streetCollectionPoint = (address: GlsInfoLocation | undefined | null): string | undefined => {
  const houseNumber = fullHouseNumber(address?.houseNo, address?.houseNoAddition);
  return mergeFields([address?.street, houseNumber]);
};

export const cityCollectionPoint = (address: GlsInfoLocation | undefined): string | undefined =>
  mergeFields([address?.zipCode, address?.city]);

export const countryCollectionPoint  = (address: GlsInfoLocation | undefined): string | undefined => address?.country;


export const name = (address: RouteAddress | undefined): string | undefined =>
  mergeFields([address?.name, address?.name2, address?.name3]);

export const street = (address: RouteAddress | undefined): string | undefined => {
  const houseNumber = fullHouseNumber(address?.houseNo, address?.houseNoAdd);
  return mergeFields([address?.street, houseNumber]);
};

export const city = (address: RouteAddress | undefined): string | undefined =>
  mergeFields([address?.zipcode, address?.city]);

export const country = (address: RouteAddress | undefined): string | undefined => address?.country;

export const getParcelStateTranslationKey = (details: ParcelDetails | undefined): string => {
  const state = details?.state;
  if (details?.isRetour) {
    if (details?.subState === ParcelSubState.ReturnedToSender) {
      return 'TrackAndTrace.Status.ReturnedToSender';
    }
    return 'TrackAndTrace.StatusType.Return';
  }
  const isDeliveredAtNeighbours =
    details?.state === ParcelState.Delivered && details?.subState === ParcelSubState.DeliveredAtNeighbors;
  if (details?.state) {
    switch (state) {
      case ParcelState.Announced:
        return 'TrackAndTrace.StatusType.SendingData';
      case ParcelState.Received:
        return 'TrackAndTrace.StatusType.ReceivedByGLS';
      case ParcelState.InRegion:
        return 'TrackAndTrace.StatusType.InDepot';
      case ParcelState.OutForDelivery:
        if (details?.deliveryScanInfo.apl) {
          return 'TrackAndTrace.StatusType.DeliverOnTheWayToApl';
        }
        if (details?.deliveryScanInfo.parcelShop) {
          return 'TrackAndTrace.StatusType.DeliverOnTheWayToParcelshop';
        }
        return 'TrackAndTrace.StatusType.DeliverOnTheWay';
      case ParcelState.Delivered:
        if (details?.subState === ParcelSubState.CollectedFromApl) {
          return 'TrackAndTrace.ParcelSubState.CollectedFromApl';
        }
        if (details?.subState === ParcelSubState.CollectedFromParcelShop) {
          return 'TrackAndTrace.ParcelSubState.CollectedFromParcelShop';
        }
        if (details?.subState === ParcelSubState.DeliveredAtParcelShop) {
          return 'TrackAndTrace.ParcelSubState.DeliveredAtParcelShop';
        }
        if (details?.subState === ParcelSubState.DeliveredAtApl) {
          return 'TrackAndTrace.ParcelSubState.DeliveredAtApl';
        }
        if (isDeliveredAtNeighbours) {
          return 'TrackAndTrace.DeliveredTitle.DeliveredAtTheNeighbours';
        }
        return 'TrackAndTrace.StatusType.Delivered';
      case ParcelState.NotDelivered:
        return 'TrackAndTrace.StatusType.NotDelivered';
      default:
        return 'Unknown parcel state.';
    }
  }
  return 'TrackAndTrace.StatusType.SendingData';
};
