import { Component, OnDestroy } from '@angular/core';
import { Subscription, TeardownLogic } from 'rxjs';

@Component({
  selector: 'app-base-component',
  template: '',
  styleUrls: [],
})
export abstract class BaseComponentComponent implements OnDestroy {
  private subscriptions = new Subscription();

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  register = (teardown: TeardownLogic) => this.subscriptions.add(teardown);

  unregister = (subscription: Subscription) => this.subscriptions.remove(subscription);
}

