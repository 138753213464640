import { ChangeDetectionStrategy, Component, Signal } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Store } from '@ngxs/store';
import { AppState } from '../../../state/app.state';
import { toSignal } from '@angular/core/rxjs-interop';
import { WidgetStateModel, WidgetType } from '../../../state/app.model';

@Component({
  selector: 'app-main-widget-content',
  templateUrl: './main-widget-content.component.html',
  styleUrl: './main-widget-content.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainWidgetContentComponent {
  widget: Signal<WidgetStateModel | undefined>;

  get environmentName(): string {
    return environment.environmentName;
  }

  get isLoading(): boolean {
    return false;
  }

  get showSearchParcel(): boolean {
    return this.widget()?.widgetType === WidgetType.SearchParcel;
  }

  get showParcelDetails(): boolean {
    return this.widget()?.widgetType === WidgetType.ParcelDetails;
  }

  get showLoadingSkeleton(): boolean {
    return this.widget()?.widgetType === WidgetType.LoadingSkeleton || this.widget()?.widgetType === undefined;
  }

  get showValidationError(): boolean {
    return this.widget()?.widgetType === WidgetType.InstructionValidationFailed;
  }

  get showEnterZipCode(): boolean {
    return this.widget()?.widgetType === WidgetType.EnterZipCode;
  }

  get showSelectedCollectionPoint(): boolean {
    return this.widget()?.widgetType === WidgetType.InstructionCollectionPoint;
  }

  get showSelectCollectionPoint(): boolean {
    return this.widget()?.widgetType === WidgetType.SelectOtherCollectionPoint;
  }

  get showSelectDeliveryOption(): boolean {
    return this.widget()?.widgetType === WidgetType.InstructionSelectDeliveryOption;
  }

  get showDeliverAtOtherMoment(): boolean {
    return this.widget()?.widgetType === WidgetType.InstructionDeliverOtherMoment;
  }

  get showDeliverAtNeighBours(): boolean {
    return this.widget()?.widgetType === WidgetType.InstructionDeliverAtNeighbours;
  }

  get showDeliverToAlternateAddress(): boolean {
    return this.widget()?.widgetType === WidgetType.InstructionAlternateAddress;
  }

  get showDepostPermission(): boolean {
    return this.widget()?.widgetType === WidgetType.InstructionDepostPermission;
  }

  get showRefuseParcel(): boolean {
    return this.widget()?.widgetType === WidgetType.InstructionRefuseParcel;
  }

  get showConfirmInstruction(): boolean {
    return this.widget()?.widgetType === WidgetType.InstructionConfirm;
  }

  get showInstructionResult(): boolean {
    return this.widget()?.widgetType === WidgetType.InstructionResult;
  }

  get showAciConfirmWidget(): boolean {
    return this.widget()?.widgetType === WidgetType.AciConfirmAddress;
  }

  get showAciEnterEmail(): boolean {
    return this.widget()?.widgetType === WidgetType.AciEnterEmail;
  }

  get showShopLocator(): boolean {
    return this.widget()?.widgetType === WidgetType.ShopLocator;
  }
  
  constructor(private readonly store: Store) {
    this.widget = toSignal(this.store.select(AppState.widget));
  }
}
