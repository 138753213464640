import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseTrackAndTraceComponent } from '../base-track-and-trace.component';

@Component({
  selector: 'app-deposit-location',
  templateUrl: './deposit-location.component.html',
  styleUrls: ['./deposit-location.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DepositLocationComponent extends BaseTrackAndTraceComponent {
}
