<div class="card-tt-details" [class]="isExpanded ? 'expanded' : 'collapsed'">
  <div class="widget-container" [class.expanded]="isExpanded">
    <div class="primary-column" [class.mobile]="isMobile()">
      <div class="primary-content" [class.desktop]="!isMobile()">
        <ng-container *ngIf="inTransit">
          <app-context-in-transit></app-context-in-transit>
        </ng-container>
        <ng-container *ngIf="isDelivered">
          <app-context-delivered></app-context-delivered>
        </ng-container>
        <ng-container *ngIf="notDelivered">
          <app-context-not-delivered></app-context-not-delivered>
        </ng-container>
        <ng-container *ngIf="isRetour">
          <app-context-returned></app-context-returned>
        </ng-container>
      </div>
      <div class="primary-footer" *ngIf="!isMobile()">
        <div class="return-block">
          <ng-container *ngIf="canChangeDelivery">
            <app-instruction-options *ngIf="canShowOptions"></app-instruction-options>
            <button
              mat-button
              class="change-delivery-btn btn btn-primary w280 attention-button"
              (click)="changeDelivery()"
              *ngIf="!canShowOptions"
            >
              {{ 'Instruction.ChangeDelivery' | translate }}
            </button>
          </ng-container>
        </div>

        <div class="footer">
          <img src="assets/images/parcel.png" alt="Parcel" class="size-40" />
          <div class="parcel-number spotlight-gray" (click)="copyParcelNumber()">
            <label class="spotlight-blue" translate>Search.Parcel.Code</label>
            {{ parcelNumber }} <mat-icon class="copy">content_copy</mat-icon>
          </div>

          <button
            class="btn-horizontal-expander size-40 size-40-expander"
            aria-label="Expand"
            (click)="toggleExpansion()"
          >
            <mat-icon [class]="isExpanded ? 'expanded' : 'collapsed'">navigate_next</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <ng-container *ngIf="!isMobile() && isExpanded">
      <div class="secondary-column desktop expanded">
        <app-secundary-column></app-secundary-column>
      </div>
    </ng-container>

    <ng-container *ngIf="isMobile()">
      <div class="secondary-column mobile">
        <ng-container *ngIf="canChangeDelivery">
          <app-instruction-options *ngIf="canShowOptions"></app-instruction-options>
          <button
            mat-button
            class="btn btn-primary btnChangeDelivery"
            (click)="changeDelivery()"
            *ngIf="!canShowOptions"
          >
            {{ 'Instruction.ChangeDelivery' | translate }}
          </button>
        </ng-container>
        <app-secundary-column></app-secundary-column>
      </div>
    </ng-container>
  </div>
</div>
