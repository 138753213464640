<ng-container *ngIf="isLoading()">
  <app-skeleton-parcel-search></app-skeleton-parcel-search>
</ng-container>

<div class="primary-column" [class.mobile]="isMobile()" *ngIf="!isLoading()">
  <div class="primary-content">
    <div class="header">
      <button class="btn-horizontal-expander size-40 size-40-expander" aria-label="Expand" (click)="goBack()">
        <mat-icon class="expanded">navigate_next</mat-icon>
      </button>
      <span class="spotlight-blue title" translate>Instruction.ConfirmDeliveryOption</span>
    </div>
    <form [formGroup]="form">
      <ng-container *ngIf="isCsiInstruction">
        <!-- depot -->
        <label translate>Input.Depot</label>
        <div class="depot form-field-icon-holder form-control_icon-info invalid-value-missing">
          <mat-select formControlName="depot" class="combo">
            <mat-option *ngFor="let depot of depots" [value]="depot?.locCode">
              <span class="time">{{ depot?.locCode }} - {{ depot?.name }}</span>
            </mat-option>
          </mat-select>
        </div>

        <!--entire shipment-->
        <mat-checkbox class="shipment" formControlName="entireShipment">
          <span class="spotlight-gray shipment-description" translate>Input.InstructionAppliesToEntireShipment</span>
        </mat-checkbox>
      </ng-container>

      <!-- email -->
      <label translate>Input.Email</label>
      <div
        class="search-container form-group form-group_with_icon form-group_with_feedback"
        [class.is-invalid]="isInvalidControl('email')"
      >
        <div class="invalid-value-missing">
          <input
            id="email"
            data-cy="tbemail"
            formControlName="email"
            type="text"
            class="email form-control"
            autocomplete="email"
            appTrimInput
            #email
            minlength="5"
            maxlength="320"
          />
        </div>

        <ng-container *ngIf="isInvalidControl('email')">
          <div
            *ngIf="isControlError('email', 'required')"
            class="feedback"
            [translateParams]="{
              fieldName: ('Input.Email' | translate)
            }"
            translate
          >
            Validations.Required.Field
          </div>

          <div
            *ngIf="isControlError('email', 'minlength')"
            class="feedback"
            [translateParams]="{
              fieldName: ('Input.Email' | translate),
              length: 5
            }"
            translate
          >
            Validations.Required.MinLength
          </div>

          <div
            *ngIf="isControlError('email', 'maxlength')"
            class="feedback"
            [translateParams]="{
              fieldName: ('Input.Email' | translate),
              length: 30
            }"
            translate
          >
            Validations.Required.MaxLength
          </div>

          <div
            *ngIf="isControlError('email', 'pattern')"
            class="feedback"
            translate
          >
            Validations.Required.EmailPattern
          </div>
        </ng-container>
      </div>

      <!-- phoneNumber -->
      <label translate>Input.PhoneNumber</label>
      <div
        class="search-container form-group form-group_with_icon form-group_with_feedback"
        [class.is-invalid]="isInvalidControl('phoneNumber')"
      >
        <div class="invalid-value-missing">
          <input
            id="phoneNumber"
            data-cy="tbphoneNumber"
            formControlName="phoneNumber"
            type="text"
            class="phoneNumber form-control"
            autocomplete="phoneNumber"
            appTrimInput
            #email
            minlength="5"
            maxlength="25"
          />
        </div>

        <ng-container *ngIf="isInvalidControl('phoneNumber')">
          <div
            *ngIf="isControlError('phoneNumber', 'required')"
            class="feedback"
            [translateParams]="{
              fieldName: ('Input.PhoneNumber' | translate)
            }"
            translate
          >
            Validations.Required.Field
          </div>

          <div
            *ngIf="isControlError('phoneNumber', 'minlength')"
            class="feedback"
            [translateParams]="{
              fieldName: ('Input.PhoneNumber' | translate),
              length: 5
            }"
            translate
          >
            Validations.Required.MinLength
          </div>

          <div
            *ngIf="isControlError('phoneNumber', 'maxlength')"
            class="feedback"
            [translateParams]="{
              fieldName: ('Input.PhoneNumber' | translate),
              length: 30
            }"
            translate
          >
            Validations.Required.MaxLength
          </div>

          <div
            *ngIf="isControlError('phoneNumber', 'pattern')"
            class="feedback"
            translate
          >
            Validations.Required.PhoneNumberPattern
          </div>
        </ng-container>
      </div>

      <!-- houseNumber -->
      <ng-container *ngIf="houseNumberRequired">
        <label translate>Input.HouseNumber</label>
        <div
          class="search-container form-group form-group_with_icon form-group_with_feedback"
          [class.is-invalid]="isInvalidControl('houseNumber')"
        >
          <div class="invalid-value-missing">
            <input
              id="houseNumber"
              data-cy="tbhouseNumber"
              formControlName="houseNumber"
              type="text"
              class="phoneNumber form-control"
              autocomplete="houseNumber"
              appTrimInput
              #email
              minlength="1"
              maxlength="5"
            />
          </div>

          <ng-container *ngIf="isInvalidControl('houseNumber')">
            <div
              *ngIf="isControlError('houseNumber', 'required')"
              class="feedback"
              [translateParams]="{
                fieldName: ('Input.HouseNumber' | translate)
              }"
              translate
            >
              Validations.Required.Field
            </div>

            <div
              *ngIf="isControlError('houseNumber', 'minlength')"
              class="feedback"
              [translateParams]="{
                fieldName: ('Input.HouseNumber' | translate),
                length: 5
              }"
              translate
            >
              Validations.Required.MinLength
            </div>

            <div
              *ngIf="isControlError('houseNumber', 'maxlength')"
              class="feedback"
              [translateParams]="{
                fieldName: ('Input.HouseNumber' | translate),
                length: 30
              }"
              translate
            >
              Validations.Required.MaxLength
            </div>
          </ng-container>
        </div>
      </ng-container>
    </form>
  </div>

  <div class="primary-footer">
    <button mat-button class="btn btn-primary fullwidth" (click)="confirm()">
      {{ 'Instruction.Confirm' | translate }}
    </button>
  </div>
</div>
