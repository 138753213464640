<div class="card-search">
  <ng-container *ngIf="showLoadingWidget">
    <app-skeleton-parcel-search></app-skeleton-parcel-search>
  </ng-container>

  <form [formGroup]="form" *ngIf="!showLoadingWidget">
    <div class="header">
      <img src="assets/images/parcel.png"  data-cy="parcelImage" alt="Parcel" class="size-40" />
      <h1 class="title1" data-cy="SearchParcelTitle1">{{ 'Search.Parcel.Title1' | translate  }}</h1>
      <h2 class="title2" data-cy="SearchParcelTitle2">{{ 'Search.Parcel.Title2' | translate }}</h2>
    </div>
    <div>
      <div class="input">
        <!-- ParcelCode -->
        <div class="form-group" [class.is-invalid]="isInvalidControl('code') || isSearchError">
          <div class="form-field-icon-holder invalid-value-missing">
            <input
              id="code"
              data-cy="CodeTxtBox"
              formControlName="code"
              type="text"
              class="code form-control w280"
              [placeholder]="placeholderCode"
              autocomplete="code"
              appTrimInput
              #code
              (input)="code.value = code.value.toUpperCase()"
              (paste)="handlePasteParcelNo($event)"
            />
          </div>
          <ng-container *ngIf="isInvalidControl('code')">
            <div
              *ngIf="isControlError('code', 'required')"
              class="feedback invalid-value-missing"
              [translateParams]="{ fieldName: (label | translate | lowercase) }"
              translate
            >
              Validations.Required.Field
            </div>
            <div *ngIf="isControlError('code', 'pattern')" class="feedback" translate>
              Validations.Required.CodePattern
            </div>
            <div *ngIf="isControlError('code', 'minlength')" class="feedback" translate>
              Validations.Required.CodePattern
            </div>
          </ng-container>
        </div>
        <!-- ZipCode -->
        <div
          class="form-group form-group_with_icon form-group_with_feedback"
          [class.is-invalid]="isInvalidControl('zipCode') || isSearchError"
        >
          <div class="form-field-icon-holder invalid-value-missing">
            <input
              id="zipCode"
              data-cy="AddressZipCodeTxtBox"
              formControlName="zipCode"
              type="text"
              class="zipcode form-control w280"
              autocomplete="zipCode"
              [placeholder]="placeholderZipCode"
              appTrimInput
              #zip
              (paste)="handlePasteZipCode($event)"
              (input)="zip.value = zip.value.toUpperCase()"
              minlength="4"
              maxlength="10"
            />
          </div>
          <ng-container *ngIf="isInvalidControl('zipCode')">
            <div
              *ngIf="isControlError('zipCode', 'required')"
              class="feedback invalid-value-missing"
              [translateParams]="{
                fieldName: ('Instruction.ZipCode' | translate | lowercase)
              }"
              translate
            >
              Validations.Required.Field
            </div>
            <div *ngIf="isControlError('zipCode', 'pattern')" class="feedback" translate>
              Validations.Required.ZipCodePattern
            </div>
            <div *ngIf="isControlError('zipCode', 'minlength')" class="feedback" translate>
              Validations.Required.ZipCodePattern
            </div>
          </ng-container>
          <ng-container *ngIf="isSearchError && !isInvalidControl('code') && !isInvalidControl('zipCode')">
            <div class="feedback invalid-value-missing" translate>Search.NoData</div>
          </ng-container>
        </div>
      </div>
      <button mat-button (click)="onSearchParcelClicked()" data-cy="ShowParcelInfoBtn" class="btn btn-primary w280" [disabled]="isInProgress">
        {{ 'Buttons.ShowParcelInfo' | translate }}
      </button>
    </div>
  </form>
</div>
