<div
  class="grip-handle-wrapper"
  (touchstart)="onTouchStart($event)"
  (touchmove)="onTouchMove($event)"
  (touchend)="onTouchEnd($event)"
>
  <div class="grip-handle"></div>
</div>
<div #content class="content">
  <app-main-widget-content class="mobile"></app-main-widget-content>
</div>
