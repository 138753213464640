import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Translations_nl } from './assets/i18n/nl';
import { Translations_en } from './assets/i18n/en';
import { LanguageSelectorComponent } from './language/language-selector/language-selector.component';
import { LanguageSettings } from './language/language-settings';
import { LanguageService } from './language/language.service';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [LanguageSelectorComponent],
  providers: [LanguageSettings],
  exports: [TranslateModule, LanguageSelectorComponent],
})
export class LanguageModule {
  constructor(public languageService: LanguageService) {
    this.languageService.addTranslation('nl', Translations_nl);
    this.languageService.addTranslation('en', Translations_en);
  }
}
