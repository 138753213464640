import { ParcelDetails, ParcelState } from '../../../../models/track-and-trace.model';
import {
  nameCollectionPoint,
  streetCollectionPoint,
  cityCollectionPoint,
  countryCollectionPoint,
} from '../../../../services/track-and-trace.utils';
import {
  BusinessHours,
  BusinessOpening,
  GeoLocation,
  GlsInfoLocation,
  ParcelCollectionPoint,
} from '../../../../models/gls-info.model';
import { Store } from '@ngxs/store';
import { AwsMapBaseComponentComponent, AwsMapsService } from '@root/libs/aws-maps/src';
import { toSignal } from '@angular/core/rxjs-interop';
import { AppState } from '../../../../state/app.state';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, signal, Signal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-parcel-collection-point',
  templateUrl: './parcel-collection-point.component.html',
  styleUrl: './parcel-collection-point.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParcelCollectionpointComponent extends AwsMapBaseComponentComponent implements OnInit {
  protected parcel: Signal<ParcelDetails | undefined>;
  imageUrl = signal('');

  @Input() primary = true;
  @Input() showtime = false;
  @Input() allowSelectCollectionPoint = false;
  @Input() showtitle = false;
  @Input() showPhoto = false;
  @Input() collectionPoint: ParcelCollectionPoint | null | undefined = null;
  @Input() selectCollectionPointTitle = 'Instruction.SelectOtherCollectionPoint';
  @Output() selectCollectionPoint: EventEmitter<boolean> = new EventEmitter();

  get isApl(): boolean | undefined {
    return this.collectionPoint?.isApl && this.collectionPoint?.id !== undefined;
  }
  get isMapVisible(): boolean {
    return this.geoLocation !== undefined && this.details?.state !== ParcelState.NotDelivered;
  }

  get geoLocation(): GeoLocation | undefined {
    return this.collectionPoint?.location.gelocation;
  }

  get address(): GlsInfoLocation | undefined {
    return this.collectionPoint?.location;
  }

  get details(): ParcelDetails | undefined {
    return this.parcel();
  }

  get name(): string | undefined {
    return nameCollectionPoint(this.collectionPoint);
  }

  get street(): string | undefined {
    return streetCollectionPoint(this.address);
  }

  get website(): string {
    return this.details?.deliveryScanInfo?.parcelShop?.website ?? '';
  }

  get websiteUrl(): string | undefined {
    const website = this.website.replace('http://', '').replace('https://', '');
    return website.length > 0 ? 'https://' + website : undefined;
  }

  get city(): string | undefined {
    return cityCollectionPoint(this.address);
  }

  get country(): string | undefined {
    return countryCollectionPoint(this.address);
  }

  get isOpen(): boolean {
    return this.openingStatus?.isOpen ?? false;
  }

  get info(): string | undefined {
    return this.openingStatus?.info;
  }

  get openingsText(): string | undefined {
    if (this.collectionPoint) {
      return this.isOpen
        ? this.translationService.instant('CollectionPoint.BusinessHours.Open')
        : this.translationService.instant('CollectionPoint.BusinessHours.Closed');
    }
    return undefined;
  }

  get openingStatus(): BusinessOpening | undefined {
    if (this.collectionPoint) {
      return this.determineBusinessOpenening(this.collectionPoint);
    }
    return undefined;
  }

  get remainingDaysAvailable(): number {
    return this.calculateRemainingDays();
  }

  get remainingDaysTextId(): string {
    return this.remainingDaysAvailable > 1 ? 'CollectionPoint.RemainingDays' : 'CollectionPoint.RemainingDay';
  }

  get openingHours(): BusinessHours[] | undefined {
    return this.collectionPoint?.businessHours;
  }

  constructor(
    protected readonly store: Store,
    private readonly translationService: TranslateService,
    protected override readonly awsMapsService: AwsMapsService
  ) {
    super(awsMapsService);
    this.parcel = toSignal(this.store.select(AppState.parcelDetails));
  }

  determineBusinessOpenening = (collectionPoint: ParcelCollectionPoint): BusinessOpening => {
    const is24X7Open = this.is24x7Open(collectionPoint);
    if (is24X7Open) {
      return { is24X7Open, isOpen: true, info: '24x7' };
    }
    const now = new Date();
    const currentDay = now.getDay();
    const currentTime = now.toTimeString().slice(0, 5);

    const todayHours = collectionPoint.businessHours.filter((hours) => hours.dayOfWeek === currentDay) ?? [];

    return todayHours.reduce(
      (acc: BusinessOpening, item) => {
        if (acc.isOpen) {
          return acc;
        }
        const { openTime, closeTime } = item;
        const isOpen = currentTime >= openTime && currentTime <= closeTime;
        if (isOpen) {
          return {
            is24X7Open,
            isOpen,
            info: `${this.translationService.instant('CollectionPoint.BusinessHours.Until')} ${closeTime} `,
          };
        }
        return acc;
      },
      { is24X7Open, isOpen: false, info: '' }
    );
  };

  is24x7Open = (collectionPoint: ParcelCollectionPoint): boolean => {
    if (collectionPoint.businessHours.length !== 7) {
      return false;
    }

    const is24X7Open = collectionPoint.businessHours.reduce((acc, item) => {
      if (item.openTime !== '00:00' && item.closeTime !== '23:59') {
        return false;
      }
      return acc;
    }, true);

    return is24X7Open;
  };

  calculateRemainingDays = (): number => {
    const timespan = this.details?.timeRemainingToCollectParcel;
    if (!timespan) {
      return 0;
    }
    // A TimeSpan of 5 days, 12 hours, 45 minutes, 30.1234567 seconds would be converted to the string: "5.12:45:30.1234567"
    const ddhhmmss = timespan.split(':');
    if (ddhhmmss.length === 3) {
      const daysAndHours = ddhhmmss[0];
      const ddhh = daysAndHours.split('.');
      if (ddhh.length === 1) {
        return parseInt(ddhh[0]) < 12 ? 0 : 1;
      } else {
        return parseInt(ddhh[0]);
      }
    }
    return 0;
  };

  openingDay = (businessHour: BusinessHours): string =>
    this.translationService.instant(`CollectionPoint.BusinessHours.Day${businessHour.dayOfWeek}`);

  openingTime = (businessHour: BusinessHours): string => {
    if (businessHour.openTime === '00:00' && businessHour.closeTime === '00:00') {
      return this.translationService.instant('CollectionPoint.BusinessHours.Closed');
    }
    return `${businessHour.openTime} - ${businessHour.closeTime}`;
  };

  isCurrentOpening = (businessHour: BusinessHours): boolean => {
    const now = new Date();
    const currentDay = now.getDay();
    const currentTime = now.toTimeString().slice(0, 5);

    const { openTime, closeTime } = businessHour;
    return currentDay === businessHour.dayOfWeek && currentTime >= openTime && currentTime <= closeTime;
  };

  async ngOnInit() {
    if (!this.collectionPoint?.isApl && this.collectionPoint?.id) {
      const url = 'https://apm.gls.nl/api/parcelshop/v1/image/' + this.collectionPoint?.id;
      const response = await fetch(url);
      if (response.ok) {
        const imageBlob = await response.blob();

        if (imageBlob.size > 100) {
          this.imageUrl.set(URL.createObjectURL(imageBlob));
        }
      }
    } else if (this.collectionPoint?.isApl && this.collectionPoint?.id) {
      this.imageUrl.set('/assets/images/apl_blue.svg');
    }

    const gpsLocation = this.geoLocation;
    if (!this.isMapVisible || !gpsLocation) return;
    if (this.details?.deliveryScanInfo.parcelShop) {
      await this.drawParcelShopLocation(gpsLocation.latitude, gpsLocation.longitude);
    }
    if (this.details?.deliveryScanInfo.apl) {
      await this.drawAplLocation(gpsLocation.latitude, gpsLocation.longitude);
    }
  }

  onSelectedCollectionPoint() {
    this.selectCollectionPoint.emit(true);
  }
}
