<div class="card-search">
  <div class="primary-column" [class.mobile]="isMobile()">
    <div class="primary-content">
      <button
        mat-button
        class="more-options btn btn-secondary fullwidth"
        *ngIf="canDeliverWithDepositPermission"
        (click)="deliverWithDepositPermission()"
      >
        <mat-icon>location_on</mat-icon>
        <span translate>Instruction.DeliverWithDepositPermission</span>
      </button>

      <button
        mat-button
        class="more-options btn btn-secondary fullwidth"
        *ngIf="canDeliverToCollectionPoint"
        (click)="deliverAtCollectionPoint()"
      >
        <mat-icon>storefront</mat-icon>
        <span translate>Instruction.DeliverToCollectionPoint</span>
      </button>

      <button
        mat-button
        class="more-options btn btn-secondary fullwidth"
        *ngIf="canDeliverAtOtherMoment && !isPickup"
        (click)="deliverAtOtherMoment()"
      >
        <mat-icon>schedule</mat-icon>
        <span translate>Instruction.DeliverAtOtherMoment</span>
      </button>

      <button
        mat-button
        class="more-options btn btn-secondary fullwidth"
        *ngIf="canDeliverAtOtherMoment && isPickup"
        (click)="deliverAtOtherMoment()"
      >
        <mat-icon>schedule</mat-icon>
        <span translate>Instruction.PickupAtOtherMoment</span>
      </button>

      <button
        mat-button
        class="more-options btn btn-secondary fullwidth"
        *ngIf="canDeliverAtNeighbours"
        (click)="deliverAtNeigbours()"
      >
        <mat-icon>group</mat-icon>
        <span translate>Instruction.HomeAddress.DeliverAtNeighbours</span>
      </button>

      <button
        mat-button
        class="more-options btn btn-secondary fullwidth"
        *ngIf="canDeliverToAlternateAddress"
        (click)="deliverToAlternateAddress()"
      >
        <mat-icon>local_shipping</mat-icon>
        <span translate>Instruction.DeliverToAlternateAddress</span>
      </button>

      <button
        mat-button
        class="more-options btn btn-secondary fullwidth"
        *ngIf="canRefuseParcel"
        (click)="refuseParcel()"
      >
        <mat-icon>cancel</mat-icon>
        <span translate>Instruction.RefuseParcel</span>
      </button>
    </div>
  </div>
</div>
