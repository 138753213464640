<app-track-and-trace-status-title></app-track-and-trace-status-title>

<ng-container *ngIf="showEta">
  <div class="spotlight-black" translate>Eta.NextDeliveryAttempt</div>
  <app-delivery-date></app-delivery-date>
  <app-parcel-progress></app-parcel-progress>
</ng-container>
<div class="spotlight-gray footnote notDeliveredreason" *ngIf="notDeliveredReason" >{{notDeliveredReason | translate}}</div>

<app-deposit-location></app-deposit-location>

<ng-container *ngIf="showEta">
  <div class="spotlight-gray spotlight-footnote" translate>Eta.checktimes</div>
</ng-container>
