<div class="gls-height">
  <a href="#" (click)="returnToSearch(); (false)">
    <div class="gls-container spotlight-gray">
      <mat-icon class="icon">navigate_before</mat-icon>
      <img
        src="/assets/images/2021_gls-logo_blue-yellow.svg"
        alt="GLS"
        class="logo"
      />
    </div>
  </a>
</div>
