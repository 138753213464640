<ng-container *ngIf="isDeliveredSustainable || isSustainableDelivery">
  <div class="sustainable">
    <div class="icon" >
      <img class="img-to-fit"  [class.mobile]="isMobile()" src="/assets/images/GLS_Emissievrij_icoon_DEF.svg" />
    </div>

    <ng-container *ngIf="isDeliveredSustainable">
      <div class="spotlight spotlight-black">
        {{ 'Parcel.Details.DeliveredSustainable' | translate }}
      </div>
    </ng-container>
    <ng-container *ngIf="isSustainableDelivery">
      <div class="spotlight spotlight-black">
        {{ 'Parcel.Details.SustainableDelivery' | translate }}
      </div>
    </ng-container>
  </div>
</ng-container>
