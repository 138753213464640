import { ChangeDetectionStrategy, Component, OnInit, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { AppState } from '../../../../state/app.state';
import { Store } from '@ngxs/store';
import { AwsMapBaseComponentComponent, AwsMapsService } from '@root/libs/aws-maps/src';
import { ParcelDetails, ParcelState } from '../../../../models/track-and-trace.model';

@Component({
  selector: 'app-eta-live-tracking-map',
  templateUrl: './eta-live-tracking-map.component.html',
  styleUrl: './eta-live-tracking-map.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EtaLiveTrackingMapComponent extends AwsMapBaseComponentComponent implements OnInit {
  protected parcel: Signal<ParcelDetails | undefined>;

  get isMapAvailable(): boolean {
    return (
      this.details?.state !== ParcelState.NotDelivered &&
      this.remainingStops >= 0 &&
      this.remainingStops <= 10 &&
      this.isRealtimeTracking &&
      !this.details?.deliveryScanInfo?.parcelShop &&
      !this.details?.deliveryScanInfo?.apl
    );
  }

  get remainingStops(): number {
    return this.details?.deliveryStatus.remainingStops ?? 0;
  }

  get isRealtimeTracking(): boolean {
    return this.details?.deliveryStatus.isRealTimeTracking ?? false;
  }

  get details(): ParcelDetails | undefined {
    return this.parcel();
  }

  get stopTextValue(): string {
    return this.remainingStops && this.remainingStops === 1
      ? 'TrackAndTrace.RealTimeTracking.SingleStop'
      : 'TrackAndTrace.RealTimeTracking.MultipleStops';
  }

  constructor(protected readonly store: Store, protected override readonly awsMapsService: AwsMapsService) {
    super(awsMapsService);
    this.parcel = toSignal(this.store.select(AppState.parcelDetails));
  }

  async ngOnInit() {
    if (!this.isMapAvailable) return;
    if (this.details?.deliveryStatus?.driverPosition && this.details?.deliveryStatus?.destinationPosition) {
      const route = await this.awsMapsService.trackingDirections(
        this.details.deliveryStatus.driverPosition.lattitude,
        this.details.deliveryStatus.driverPosition.longitude,
        this.details.deliveryStatus.destinationPosition.lattitude,
        this.details.deliveryStatus.destinationPosition.longitude
      );
      if (route) {
        await this.drawTrackAndTraceDirections(route);
      }
    }
  }
}
