import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DecimalPipe, formatDate } from '@angular/common';
import { ParcelProperty, TrackAndTraceProperty } from './track-and-trace-details.model';
import { BaseTrackAndTraceComponent } from '../base-track-and-trace.component';
import { Store } from '@ngxs/store';
import { expandCollapse } from '../../../../animations/expand-collapse-animation';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-track-and-trace-details',
  templateUrl: './track-and-trace-details.component.html',
  styleUrls: ['../summary.scss', './track-and-trace-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DecimalPipe],
  animations: [expandCollapse],
})
export class TrackAndTraceDetailsComponent extends BaseTrackAndTraceComponent implements OnInit {
  properties: ParcelProperty[] = [];
  isExpanded = false;

  constructor(store: Store, private readonly translateService: TranslateService, private decimailPipe: DecimalPipe) {
    super(store);
  }

  ngOnInit(): void {
    this.properties = this.createProperties();
    this.register(this.translateService.onLangChange.subscribe(() => (this.properties = this.createProperties())));
  }

  createProperties = (): ParcelProperty[] => {
    const keys = [
      TrackAndTraceProperty.Uniqueno,
      TrackAndTraceProperty.JobDate,
      TrackAndTraceProperty.Reference,
      TrackAndTraceProperty.Dimensions,
      TrackAndTraceProperty.Weight,
      TrackAndTraceProperty.GpNo,
    ];
    const result: ParcelProperty[] = keys.reduce((acc: ParcelProperty[], item) => {
      const value = this.mapValue(item);
      if (value) {
        return [...acc, { label: item, value }];
      }
      return acc;
    }, []);
    return result;
  };

  mapValue = (key: string): string | undefined => {
    if (key === TrackAndTraceProperty.Uniqueno) {
      return this.details?.uniqueNo;
    }
    if (key === TrackAndTraceProperty.Reference) {
      return this.details?.reference;
    }
    if (key === TrackAndTraceProperty.Dimensions) {
      const width = this.details?.width;
      const height = this.details?.height;
      const length = this.details?.length;
      if (width && height && length) {
        return `${length} x ${width} x ${height} cm`;
      }
    }
    if (key === TrackAndTraceProperty.Weight) {
      const weight = this.details?.weighedWeight ?? this.details?.suppliedWeight;
      if (weight) {
        const formattedNumber = this.decimailPipe.transform(weight, '1.2-2');
        return `${formattedNumber} kg`;
      }
    }
    if (key === TrackAndTraceProperty.GpNo) {
      return this.details?.gpNo;
    }
    if (key === TrackAndTraceProperty.JobDate) {
      return this.jobDate();
    }
    return undefined;
  };

  toggleExpansion = () => {
    this.isExpanded = !this.isExpanded;
  };

  jobDate = (): string | undefined => {
    if (this.details?.jobDate) {
      const datum = new Date(this.details?.jobDate);
      const format = 'shortDate';
      return formatDate(datum, format, this.translateService.currentLang);
    }
    return undefined;
  };
}
