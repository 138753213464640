import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseTrackAndTraceComponent } from '../base-track-and-trace.component';
import { Scan } from '../../../../models/track-and-trace.model';
import { expandCollapse } from '../../../../animations/expand-collapse-animation';

@Component({
  selector: 'app-scans-component',
  templateUrl: './scans-component.component.html',
  styleUrls: ['../summary.scss', './scans-component.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [expandCollapse],
})
export class ScansComponentComponent extends BaseTrackAndTraceComponent {
  isExpanded = false;

  get scans(): Scan[] | undefined {
    const value = this.parcel()?.scans;
    if (value) {
      let previousScan: Scan | undefined = undefined;
      return [...value]
        .sort(
          (a, b) =>
            new Date(b.dateTime).getTime() - new Date(a.dateTime).getTime()
        )
        .reduce((acc: Scan[], scan: Scan) => {
          if (
            previousScan &&
            previousScan.eventNo === scan.eventNo &&
            previousScan.reasonNo === scan.reasonNo
          ) {
            return acc;
          }
          previousScan = scan;
          return [...acc, scan];
        }, []);
    }
    return value;
  }

  toggleExpansion = () => {
    this.isExpanded = !this.isExpanded;
  };
}
