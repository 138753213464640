import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseTrackAndTraceComponent } from '../../summary/base-track-and-trace.component';
import { hasAddress } from '../../../../services/track-and-trace.utils';
import { ParcelState } from '../../../../models/track-and-trace.model';
import { ParcelCollectionPoint } from '../../../../models/gls-info.model';

@Component({
  selector: 'app-context-in-transit',
  templateUrl: './context-in-transit.component.html',
  styleUrl: './context-in-transit.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContextInTransitComponent extends BaseTrackAndTraceComponent {
  get etaWarning(): string | undefined {
    const details = this.parcel();
    if (!(details && hasAddress(details))) {
      return undefined;
    }

    if (details?.state === ParcelState.Announced) {

      return 'Eta.Warning.PREADVICE';
    }

    if (this.isExport && this.details?.showEta === false) {
      return 'Eta.Warning.EXPORT';
    }

    if (this.isImport && this.details?.showEta === false) {
      return 'Eta.Warning.IMPORT';
    }

    return undefined;
  }

  get checkTimes(): string {
    return !this.etaWarning ? 'Eta.checktimes' : '';
  }

  get collectionPoint(): ParcelCollectionPoint | undefined | null {
    const parcelshop = this.details?.deliveryScanInfo.parcelShop;
    if (parcelshop) {
      return parcelshop;
    }
    return this.details?.deliveryScanInfo?.apl;
  }
}
