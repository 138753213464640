var geojsonNormalize = require('@mapbox/geojson-normalize'),
  geojsonFlatten = require('geojson-flatten'),
  flatten = require('./flatten');
if (!(geojsonFlatten instanceof Function)) geojsonFlatten = geojsonFlatten.default;
module.exports = function (_) {
  if (!_) return [];
  var normalized = geojsonFlatten(geojsonNormalize(_)),
    coordinates = [];
  normalized.features.forEach(function (feature) {
    if (!feature.geometry) return;
    coordinates = coordinates.concat(flatten(feature.geometry.coordinates));
  });
  return coordinates;
};