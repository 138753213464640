import { Injectable } from '@angular/core';
import {
  LocationClient,
  CalculateRouteCommand,
  CalculateRouteCommandOutput,
  SearchPlaceIndexForTextCommand,
  SearchPlaceIndexForTextCommandOutput,
} from '@aws-sdk/client-location';
import { Amplify, Auth } from 'aws-amplify';
import { ICredentials } from '@aws-amplify/core';
import { createMap } from 'maplibre-gl-js-amplify';
import { AwsMapsConfig } from '../config';

@Injectable({
  providedIn: 'root',
})
export class AwsMapsClientService {
  private _isApiLoaded = false;

  get credentials(): Promise<ICredentials> {
    return Auth.currentCredentials();
  }


  public createMap(options: any): Promise<maplibregl.Map> {
    try {
      this.load();

      return createMap(options);
    } catch (ex) {
      console.log(ex);
    }

    throw new Error('AwsMaps.Errors.CreateMap');
  }

  public async getDirections(
    originLat: number,
    originLng: number,
    destinationLat: number,
    destinationLng: number
  ): Promise<CalculateRouteCommandOutput> {
    try {
      this.load();

      const locationClient = new LocationClient({
        region: AwsMapsConfig.Region,
        credentials: await this.credentials,
      });
      const calculateRouteCommand = new CalculateRouteCommand({
        CalculatorName: AwsMapsConfig.CalculatorName,
        DeparturePosition: [originLng, originLat],
        DestinationPosition: [destinationLng, destinationLat],
        IncludeLegGeometry: true,
      });

      return locationClient.send(calculateRouteCommand);
    } catch (ex) {
      console.log(ex);
    }

    throw new Error('AwsMaps.Errors.GetDirections');
  }

  public async searchLocationByPostalCode(
    postalCode: string
  ): Promise<SearchPlaceIndexForTextCommandOutput> {
    try {
      this.load();

      const locationClient = new LocationClient({
        region: AwsMapsConfig.Region,
        credentials: await this.credentials,
      });
      const searchPlaceIndexForTextCommand = new SearchPlaceIndexForTextCommand(
        {
          IndexName: AwsMapsConfig.PlaceIndexName,
          Text: `${postalCode}, Netherlands`,
        }
      );

      return locationClient.send(searchPlaceIndexForTextCommand);
    } catch (ex) {
      console.log(ex);
    }

    throw new Error('AwsMaps.Errors.SearchLocationByPostalCode');
  }

  private load(): void {
    // If the API has already been loaded, we can skip this step.
    if (this._isApiLoaded) {
      return;
    }
    try {
      Amplify.configure({
        Auth: {
          identityPoolId: `${AwsMapsConfig.Region}:${AwsMapsConfig.IdentityPoolId}`,
          region: AwsMapsConfig.Region,
        },
        geo: {
          AmazonLocationService: {
            maps: {
              items: {
                [AwsMapsConfig.MapName]: {
                  style: 'Default style',
                },
              },
              default: AwsMapsConfig.MapName,
            },
            region: AwsMapsConfig.Region,
          },
        },
      });

      this._isApiLoaded = true;
    } catch {
      throw new Error('AwsMaps.Errors.Load');
    }
  }
}
