import { TranslateService } from '@ngx-translate/core';
import { Scan } from '../models/track-and-trace.model';
import { ScanEvent, ScanEventReasonDelivered, ScanEventReasonOutForDelivery } from '../models/gls-info.model';
import { catchError, lastValueFrom, throwError, timeout } from 'rxjs';
import { ReCaptchaV3Service } from 'ng-recaptcha-2';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { isDate } from 'date-fns/isDate';
import { addMinutes } from 'date-fns/addMinutes';
import get from 'lodash-es/get';

export const copyToClipboard = (text: string, snackBar: MatSnackBar, translateService: TranslateService) => {
  if (navigator.clipboard && window.isSecureContext) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        const message = translateService.instant('Clipboard.CopiedSuccess');
        showMessage(message, snackBar);
      })
      .catch(() => {
        const message = translateService.instant('Clipboard.FailedToCopy');
        showMessage(message, snackBar);
      });
  }
};

const showMessage = (message: string, snackBar: MatSnackBar) => {
  const options: MatSnackBarConfig = {
    verticalPosition: 'top',
    duration: 2000,
  };
  snackBar.open(message, undefined, options);
};

export const getLocale = (translate: TranslateService): string => getCultureByLanguage(translate.currentLang);

export const getCultureByLanguage = (lang: string) => (lang === 'nl' ? 'nl-NL' : 'en-GB');

export const isOutForDeliveryEvent = (scan: Scan): boolean =>
  scan.eventNo === ScanEvent.OutForDelivery && scan.reasonNo === ScanEventReasonOutForDelivery.Normal;

export const isParcelShopEvent = (scan: Scan): boolean =>
  scan.eventNo === ScanEvent.Delivered && scan.reasonNo === ScanEventReasonDelivered.ParcelShop;

export const getCaptchaTokenForParcelInfo = async (reCaptchaService: ReCaptchaV3Service): Promise<string | undefined> =>
  getCaptchaToken(reCaptchaService, 'parcel_info');

const getCaptchaToken = async (reCaptchaService: ReCaptchaV3Service, action: string): Promise<string | undefined> =>
  retryGetToken(reCaptchaService, action, 5);

const retryGetToken = async (
  reCaptchaService: ReCaptchaV3Service,
  action: string,
  attempt: number
): Promise<string | undefined> => {
  try {
    return await lastValueFrom(
      reCaptchaService.execute(action).pipe(
        timeout(5000),
        catchError((err) => {
          if (err.name === 'TimeoutError') {
            return throwError(() => new Error('timeout'));
          }
          return throwError(() => err);
        })
      )
    );
  } catch (ex) {
    if (attempt < 0) {
      console.error(ex);
    } else {
      return retryGetToken(reCaptchaService, action, attempt - 1);
    }
  }
  return undefined;
};

export const dateCorrectionForUtcMapping = (value: any): Date | undefined => {
  if (value) {
    const date = isDate(value) ? value : get(value, '_d');
    if (date) {
      const minutesOffSet = date.getTimezoneOffset();
      return addMinutes(date, -1 * minutesOffSet);
    }
  }
  return value;
};

export function lastOrDefault<T>(arr: T[], predicate: (item: T) => boolean, defaultValue: T): T {
  for (let i = arr.length - 1; i >= 0; i--) {
      if (predicate(arr[i])) {
          return arr[i];
      }
  }
  return defaultValue;
}
