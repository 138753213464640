import { ChangeDetectionStrategy, Component, OnInit, Signal, computed } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';

import { toSignal } from '@angular/core/rxjs-interop';
import { Store } from '@ngxs/store';
import { Constants } from '../../models/constants';
import { TargetAudience } from '../../models/common.model';
import { GetEmergencyNotificationsAction, SetActiveWidgetAction } from '../../state/app.actions';
import { ActivatedRoute } from '@angular/router';

import { LanguageService } from '@nx/language';
import { LinkManagerService } from '../../services/linkmanager.service';
import { ReCaptchaV3Service } from 'ng-recaptcha-2';
import get from 'lodash-es/get';
import { WidgetType } from '../../state/app.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent implements OnInit {
  breakpoint = toSignal(this.breakpointObserver.observe([Constants.BreakPointMobile]));

  isMobile: Signal<boolean> = computed(() => this.breakpoint()?.matches ?? false);

  constructor(
    private readonly breakpointObserver: BreakpointObserver,
    private readonly route: ActivatedRoute,
    private readonly language: LanguageService,
    private readonly store: Store,
    private readonly linkManager: LinkManagerService,
    private readonly recaptchaService: ReCaptchaV3Service
  ) {
    this.store.dispatch(new GetEmergencyNotificationsAction(TargetAudience.GLS));
  }

  changeToLanguageFromUrl = () => {
    const language = get(this.route.snapshot.queryParams, 'lang');
    if (language && language.length === 2) {
      const lowerCaseLanguage = language.toLowerCase();
      if (lowerCaseLanguage === 'nl' || lowerCaseLanguage === 'en') {
        this.language.useLanguage(lowerCaseLanguage);
      }
    }
  };

  async ngOnInit() {
    this.changeToLanguageFromUrl();
    await this.linkManager.processDeepLinks(this.route, this.recaptchaService);
  }
}
