import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { InstructionClientService } from '../clients/instruction.client';
import {
  InstructionType,
  ActionType,
  DeepLinkType,
  InstructionTypeString,
  ValidationResultCode,
} from '../models/gls-info.model';
import { InstructionValidationRequest } from '../models/instruction-validation-request';
import {
  SetInstructionValidationResultAction,
  SetActiveWidgetAction,
  GetParcelDetailsAction,
  SetInstructionValidationRequestAction,
} from '../state/app.actions';
import { WidgetType } from '../state/app.model';
import { HttpParams } from '@angular/common/http';
import { getLocale, getCaptchaTokenForParcelInfo } from './spotlight.utils';
import { TranslateService } from '@ngx-translate/core';
import { ReCaptchaV3Service } from 'ng-recaptcha-2';
import get from 'lodash-es/get';

@Injectable({
  providedIn: 'root',
})
export class LinkManagerService {
  private readonly parcelNoKeys = ['parcelno', 'parcelnr', 'p', 'n', 'ndoc', 'trackid'];
  private readonly dateKeys = ['datum', 'dt', 'date'];
  private readonly customerNoKeys = ['nvrl', 'customerno'];
  private readonly usernameKeys = ['user'];
  private readonly userTypeKeys = ['usertype'];
  private readonly referenceKeys = ['reference', 'vref'];
  private readonly zipcodeKeys = ['zipcode', 'z', 'postalcode', 'postcode'];
  private readonly shipmentKeys = ['shipment'];
  private readonly checksumKeys = ['checksum', 'chk', 'c'];
  private readonly deeplinkKeys = ['id'];
  constructor(
    private readonly store: Store,
    private readonly translateService: TranslateService,
    private readonly instructionClient: InstructionClientService
  ) {}

  createInstructionContextFromDeepLinkId = (params: HttpParams): InstructionValidationRequest | undefined => {
    const iddeeplink = params.get('deeplink') ?? params.get('id') ?? undefined;
    const deepLinkType = params.get('action') ?? params.get('type') ?? params.get('type') ?? undefined;

    let instructionType = InstructionType.NotAtHome;
    const action = ActionType.Instruction;

    switch (deepLinkType) {
      case DeepLinkType.Aci:
        instructionType = InstructionType.AciAdresConfirmed;
        break;

      case DeepLinkType.AplNotDelivered:
        instructionType = InstructionType.AplNotDelivered;
        break;

      case DeepLinkType.Nti:
        instructionType = InstructionType.NotAtHome;
        break;

      case DeepLinkType.Otfi:
        instructionType = InstructionType.OTFI;
        break;

      case DeepLinkType.Fdi:
        instructionType = InstructionType.FlexDelivery;
        break;

      default:
        instructionType = InstructionType.OTFI;
        break;
    }
    return iddeeplink ? { iddeeplink, action, instructionType } : undefined;
  };

  createInstructionContextFromQueryParams = (params: HttpParams): InstructionValidationRequest | undefined =>
    params.keys().reduce(
      (acc: InstructionValidationRequest, key: string) => {
        if (key.toLowerCase() === 'n' || key.toLowerCase() === 'q') {
          const code = params.get('n') ?? params.get('q') ?? undefined;
          const instructionType = code?.length === 5 ? InstructionType.ShopDepositReceipt : InstructionType.NotAtHome;

          const viaMail = key.toLowerCase() === 'n';
          return {
            ...acc,
            code,
            instructionType,
            action: ActionType.Instruction,
            canModifyEmailAddress: !viaMail,
          };
        }

        if (!params.get(key)) {
          // the key is the value. e.g. http://localhost:4200/ai?00938349301048FLD20240813
          return this.mapKeyToInstructionContext(params, key);
        }

        const defaultAction =
          this.lengthWithoutLanguage(params.keys()) === 1 ? ActionType.TrackAndTraceLink : ActionType.Instruction;
        return this.mapParametersToInstructionContext(defaultAction, key, params, acc);
      },
      { action: ActionType.TrackAndTraceLink }
    );

  private lengthWithoutLanguage = (keys: string[]): number => {
    const result = keys.reduce((acc, name) => {
      if (name !== 'lang' && name !== 'zipcode') {
        return acc + 1;
      }
      return acc;
    }, 0);
    return result;
  };

  private includes = (arr: string[], key: string): boolean => {
    return arr.includes(key.toLowerCase());
  };

  private getParamValueByKey = (key: string, params: HttpParams): string | undefined => {
    return params.get(key) ?? undefined;
  };

  mapParametersToInstructionContext = (
    defaultAction: ActionType,
    key: string,
    params: HttpParams,
    acc: InstructionValidationRequest
  ) => {
    if (this.includes(this.parcelNoKeys, key)) {
      return { ...acc, code: this.getParamValueByKey(key, params) };
    }

    if (this.includes(this.deeplinkKeys, key)) {
      return { ...acc, iddeeplink: this.getParamValueByKey(key, params) };
    }

    if (this.includes(this.dateKeys, key)) {
      return { ...acc, date: this.getParamValueByKey(key, params) };
    }

    if (this.includes(this.customerNoKeys, key)) {
      return { ...acc, custNo: this.getParamValueByKey(key, params) };
    }

    if (this.includes(this.usernameKeys, key)) {
      return { ...acc, username: this.getParamValueByKey(key, params) };
    }

    if (this.includes(this.userTypeKeys, key)) {
      return { ...acc, userType: this.getParamValueByKey(key, params) };
    }

    if (this.includes(this.referenceKeys, key)) {
      return { ...acc, reference: this.getParamValueByKey(key, params) };
    }

    if (this.includes(this.zipcodeKeys, key)) {
      return { ...acc, zipCode: this.getParamValueByKey(key, params) };
    }

    if (this.includes(this.shipmentKeys, key)) {
      return { ...acc, shipment: this.getParamValueByKey(key, params) };
    }

    if (this.includes(this.checksumKeys, key)) {
      return { ...acc, checksum: this.getParamValueByKey(key, params) };
    }

    if (key.toLowerCase() === 'type') {
      const type = this.getParamValueByKey(key, params);

      switch (type?.toLowerCase()) {
        case InstructionTypeString.ShopDelivery:
          return {
            ...acc,
            action: ActionType.Instruction,
            instructionType: InstructionType.ShopDelivery,
          };

        case InstructionTypeString.ShopDeliveryPlus:
          return {
            ...acc,
            action: ActionType.Instruction,
            instructionType: InstructionType.ShopDeliveryPlus,
          };

        case InstructionTypeString.AplNotDelivered:
          return {
            ...acc,
            action: ActionType.Instruction,
            instructionType: InstructionType.AplNotDelivered,
          };

        default:
          return {
            ...acc,
            action: ActionType.Instruction,
            instructionType: InstructionType.FlexDelivery,
          };
      }
    }

    if (key.toLowerCase() === 'uq') {
      const uniqueNo = this.getParamValueByKey(key, params);
      const parcelNo = get(acc, 'code', undefined);
      const action = get(acc, 'action', ActionType.TrackAndTraceLink);
      return { ...acc, uniqueNo, code: parcelNo, action };
    }

    if (key.toLowerCase() === 'param') {
      const param = this.getParamValueByKey(key, params);
      const zipCode = param?.substring(22, 29) === 'ZIPCODE' ? param?.substring(29) : undefined;

      return {
        ...acc,
        action: defaultAction,
        instructionType:
          defaultAction === ActionType.TrackAndTraceLink ? InstructionType.NotAtHome : InstructionType.CSI,
        code: param?.substring(0, 14),
        date: param?.substring(14, 22),
        zipCode,
        depotNumber: zipCode === undefined ? param?.substring(22, 26) : undefined,
        username: zipCode === undefined ? param?.substring(26) : undefined,
        csiData: param,
      };
    }

    return acc;
  };

  mapKeyToInstructionContext = (params: HttpParams, key: string): InstructionValidationRequest => {
    const instruction = { action: ActionType.Instruction };
    if (!key.length) {
      return instruction;
    }

    const zipcode = params.get('zipcode') ?? undefined;
    if (key.length === 9) {
      const parcelNo = key.substring(1, 8);
      const instType = key.substring(0, 1) === 'F' ? InstructionType.FlexDelivery : Number(key.substring(0, 1));
      return { ...instruction, code: parcelNo, instructionType: instType, zipCode: zipcode };
    }

    if (key.length === 25) {
      const parcelNo = key.substring(0, 14);
      const instTypeString = key.substring(14, 17).toLowerCase();
      const date = key.substring(17, 25);

      switch (instTypeString) {
        case InstructionTypeString.FlexDelivery:
          return {
            ...instruction,
            code: parcelNo,
            instructionType: InstructionType.FlexDelivery,
            date,
            zipCode: zipcode,
          };

        case InstructionTypeString.ShopDelivery:
          return {
            ...instruction,
            code: parcelNo,
            instructionType: InstructionType.ShopDelivery,
            date,
            zipCode: zipcode,
          };

        case InstructionTypeString.ShopDeliveryPlus:
          return {
            ...instruction,
            code: parcelNo,
            instructionType: InstructionType.ShopDeliveryPlus,
            date,
            zipCode: zipcode,
          };

        case InstructionTypeString.Aci:
          return {
            ...instruction,
            code: parcelNo,
            instructionType: InstructionType.ACI,
            date,
            zipCode: zipcode,
          };
      }
    }

    // In other cases we are dealing with a Track&Trace link where we need to get the parcel number.
    return { action: ActionType.TrackAndTraceLink, code: key.substring(0, 14) };
  };

  async processDeepLinks(route: ActivatedRoute, reCaptchaService: ReCaptchaV3Service) {
    let params = new HttpParams({ fromObject: route.snapshot.queryParams });
    const paramMap = route.snapshot.paramMap;
    paramMap.keys.forEach((key) => {
      params = params.append(key, paramMap.get(key) ?? '');
    });
    this.processDeepLinksByHttpParams(params, reCaptchaService);
  }

  async processDeepLinksByHttpParams(params: HttpParams, reCaptchaService: ReCaptchaV3Service) {
    this.store.dispatch(new SetInstructionValidationResultAction(undefined));
    this.store.dispatch(new SetInstructionValidationRequestAction(undefined));

    const language = params.get('lang')?.toLocaleLowerCase();
    if (language && language !== this.translateService.currentLang) {
      this.translateService.use(language.toLowerCase());
    }
    const request =
      this.createInstructionContextFromDeepLinkId(params) ?? this.createInstructionContextFromQueryParams(params);

    if (!request) {
      this.store.dispatch(new SetActiveWidgetAction(WidgetType.SearchParcel));
      return;
    }

    if (request.action === ActionType.TrackAndTraceLink && (!request.code || !request.zipCode)) {
      if (request.zipCode) {
        this.store.dispatch(new SetActiveWidgetAction(WidgetType.ShopLocator));
      } else {
        this.store.dispatch(new SetActiveWidgetAction(WidgetType.SearchParcel));
      }
      return;
    }

    if (request.action === ActionType.TrackAndTraceLink && request.code && request.zipCode) {
      if (request.code?.length === 6) {
        await this.validateNtbCode(request.code, request.zipCode, reCaptchaService);
        return;
      }
      await this.getParcelDetails(request, reCaptchaService);
      return;
    }

    if (this.instructionClient.needsZipCode(request)) {
      this.store.dispatch(new SetActiveWidgetAction(WidgetType.EnterZipCode));
      return;
    }

    if (request.instructionType === InstructionType.ACI) {
      // ACI, we need the email-address first before we can validate
      this.store.dispatch(new SetInstructionValidationRequestAction(request));
      this.store.dispatch(new SetActiveWidgetAction(WidgetType.AciEnterEmail));
      return;
    }
    await this.validateInstruction(request, reCaptchaService);
  }

  async validateInstruction(request: InstructionValidationRequest, reCaptchaService: ReCaptchaV3Service) {
    const captcha = (await getCaptchaTokenForParcelInfo(reCaptchaService)) ?? '';
    this.store.dispatch(new SetInstructionValidationRequestAction(request));
    const result = await this.instructionClient.validate(request, captcha);
    if (result) {
      if (result.parcels && result.parcels.length > 0) {
        request.code = result.parcels[0].nlpclNo;
      }
      if (result.instructionType) {
        request.instructionType = result.instructionType;
      }
      if (result.deepLinkId) {
        request.iddeeplink = result.deepLinkId;
      }
      if (result.leadingAddress?.zipCode) {
        request.zipCode = result.leadingAddress?.zipCode;
      }
      this.store.dispatch(new SetInstructionValidationRequestAction(request));
    }
    this.store.dispatch(new SetInstructionValidationResultAction(result));

    if (
      result &&
      request.instructionType === InstructionType.OTFI &&
      result?.resultCode !== ValidationResultCode.Valid &&
      result?.resultCode !== ValidationResultCode.Invalid &&
      result?.resultCode !== ValidationResultCode.InvalidZipCode
    ) {
      if (result.parcels.length > 0) {
        // OTFI is not allowed, but parcelnumber & zip code is valid, so just open T&T details
        request.code = result.parcels[0].nlpclNo;
        request.zipCode = result.leadingAddress.zipCode;
        await this.getParcelDetails(request, reCaptchaService);
        return;
      }
      this.store.dispatch(new SetActiveWidgetAction(WidgetType.InstructionValidationFailed));
      return;
    }

    if (result && request.instructionType === InstructionType.AciAdresConfirmed) {
      request.code = result.parcels[0].nlpclNo;
      request.zipCode = result.leadingAddress.zipCode;
      if (request.code && request.zipCode) {
        const culture = getLocale(this.translateService);
        const captcha = (await getCaptchaTokenForParcelInfo(reCaptchaService)) ?? '';
        this.store.dispatch(
          new GetParcelDetailsAction(WidgetType.AciConfirmAddress, request.code, request.zipCode, culture, captcha)
        );
      }
      return;
    }

    if (result && +result.allowedActions > 0) {
      request.code = result.parcels[0].nlpclNo;
      request.zipCode = result.leadingAddress?.zipCode ?? request.zipCode;
      await this.getParcelDetails(request, reCaptchaService);
      return;
    }

    if (result?.resultCode !== ValidationResultCode.Valid) {
      // show error
      this.store.dispatch(new SetActiveWidgetAction(WidgetType.InstructionValidationFailed));
      return;
    }
  }

  getParcelDetails = async (request: InstructionValidationRequest, reCaptchaService: ReCaptchaV3Service) => {
    if (!request.code || !request.zipCode) return;
    const culture = getLocale(this.translateService);
    const captcha = (await getCaptchaTokenForParcelInfo(reCaptchaService)) ?? '';
    this.store.dispatch(
      new GetParcelDetailsAction(WidgetType.ParcelDetails, request.code, request.zipCode, culture, captcha)
    );
  };

  async validateNtbCode(ntbCode: string, zipCode: string, reCaptchaService: ReCaptchaV3Service) {
    const request = {
      action: ActionType.Instruction,
      instructionType: InstructionType.NotAtHome,
      ntbCode: ntbCode,
      code: ntbCode,
      zipCode: zipCode,
    } as InstructionValidationRequest;

    await this.validateInstruction(request, reCaptchaService);
  }
}
