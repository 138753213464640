<div class="primary-column" [class.mobile]="isMobile()">
  <div class="primary-content">
    <div class="header">
      <button class="btn-horizontal-expander size-40 size-40-expander" aria-label="Expand" (click)="goBack()">
        <mat-icon class="expanded">navigate_next</mat-icon>
      </button>
      <span class="spotlight-blue title" translate>Instruction.RefuseParcel</span>
    </div>
  </div>

  <form [formGroup]="form">
    <!-- reason -->
    <div class="spotlight-gray" translate>Instruction.Refuse.Reason</div>
    <div class="search-container form-group form-group_with_icon form-group_with_feedback">
      <div class="invalid-value-missing">
        <input
          id="reason"
          data-cy="tbreason"
          formControlName="reason"
          type="text"
          class="reason form-control"
          autocomplete="reason"
          appTrimInput
          #reason
          maxlength="30"
        />
      </div>
    </div>
  </form>

  <div class="spotlight-gray note" [class.mobile]="isMobile()" translate>Instruction.Refuse.Description</div>

  <div class="primary-footer">
    <button mat-button class="btn btn-primary fullwidth" (click)="confirm()">
      {{ 'Instruction.ContinueWithNextStep' | translate }}
    </button>
  </div>
</div>
