import { ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, OnInit, signal, Signal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseTrackAndTraceComponent } from '../../../track-and-trace/summary/base-track-and-trace.component';
import { Store } from '@ngxs/store';
import { BreakpointObserver } from '@angular/cdk/layout';
import { toSignal } from '@angular/core/rxjs-interop';
import { Constants } from '../../../../models/constants';
import { SetActiveWidgetAction, SetSelectedCollectionPointAction } from '../../../../state/app.actions';
import { WidgetType } from '../../../../state/app.model';
import { CollectionPointClientService } from '../../../../clients/collectionpoint.client';
import { ParcelShopFilter, LocatorFilter, ParcelCollectionPoint } from '../../../../models/gls-info.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-instruction-select-collectionpoint',
  templateUrl: './instruction-select-collectionpoint.component.html',
  styleUrls: ['./instruction-select-collectionpoint.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InstructionSelectCollectionpointComponent extends BaseTrackAndTraceComponent implements OnInit {
  CodeRegex = '[a-zA-Z0-9]+'; // do not allow white spaces or other characters
  form: FormGroup;
  collectionPoints: ParcelCollectionPoint[] = [];
  breakpoint = toSignal(this.breakpointObserver.observe([Constants.BreakPointMobile]));
  showLoadingWidget = signal(false);

  isMobile: Signal<boolean> = computed(() => this.breakpoint()?.matches ?? false);

  isInvalidControl = (name: string): boolean => {
    const control = this.form.controls[name];
    return control.invalid && (control.dirty || control.touched);
  };

  isControlError = (name: string, error: string): boolean => {
    const control = this.form.controls[name];
    return control.errors && control.errors[error];
  };

  get placeholderZipCode(): string {
    return this.translateService.instant('Instruction.ZipCode');
  }

  constructor(
    store: Store,
    public readonly fb: FormBuilder,
    private readonly translateService: TranslateService,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly collectionPointService: CollectionPointClientService,
    private readonly cd: ChangeDetectorRef
  ) {
    super(store);
    this.form = this.fb.group({
      zipCode: [
        '',
        [Validators.minLength(4), Validators.maxLength(10), Validators.required, Validators.pattern(this.CodeRegex)],
      ],
    });
  }

  async onSubmit(event: Event) {
    event.preventDefault();
    if (!this.form.valid) return;
    await this.loadCollectionPoints(this.form.controls['zipCode'].value);
  }

  async ngOnInit() {
    this.showLoadingWidget.set(true);
    const targetZipCode = this.instructionValidateResult()?.leadingAddress?.zipCode ?? '';
    this.form.patchValue({
      zipCode: targetZipCode,
    });
    await this.loadCollectionPoints(targetZipCode);
    this.showLoadingWidget.set(false);
    this.cd.detectChanges();
  }

  async loadCollectionPoints(targetZipCode: string) {
    this.collectionPoints = [];
    const originalZipCode = this.instructionValidateResult()?.leadingAddress?.zipCode;
    if (originalZipCode && targetZipCode) {
      if (this.canDeliverToParcelShop || this.canDeliverToDepot) {
        let filter = this.canDeliverToParcelShop ? ParcelShopFilter.ParcelShop : ParcelShopFilter.None;
        if (this.canDeliverToDepot) filter |= ParcelShopFilter.Depot;

        const shops = await this.collectionPointService.searchOpenParcelShopsAndGlsDepots(
          targetZipCode,
          originalZipCode,
          '1',
          10,
          filter,
          LocatorFilter.InstructionSite
        );

        if (shops) {
          shops.forEach((shop) => {
            this.collectionPoints.push({
              ...shop,
              isApl: false,
            });
          });
        }
      }

      if (this.canDeliverToApl) {
        const apls = await this.collectionPointService.searchOpenApls(
          targetZipCode,
          originalZipCode,
          '1',
          10,
          ParcelShopFilter.ParcelShop,
          LocatorFilter.InstructionSite
        );

        if (apls) {
          apls.forEach((apl) => {
            this.collectionPoints.push({
              ...apl,
              isApl: true,
            });
          });
        }
      }

      const depot = this.collectionPoints.find((point) => point.isDepot);
      this.collectionPoints.sort((a, b) => a.location.distance.minutes - b.location.distance.minutes);
      this.collectionPoints = this.collectionPoints.slice(0, 10);
      if (depot && !this.collectionPoints.find((point) => point.isDepot)) {
        this.collectionPoints.push(depot);
      }
      this.cd.detectChanges();
    }
  }

  goBack() {
    this.store.dispatch(new SetActiveWidgetAction(this.rootWidget));
  }

  selectCollectionPoint(collectionpoint: ParcelCollectionPoint) {
    this.store.dispatch(new SetSelectedCollectionPointAction(collectionpoint));
    this.store.dispatch(new SetActiveWidgetAction(WidgetType.InstructionCollectionPoint));
  }
}
