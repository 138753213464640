import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TtUiConfig } from '../environments/ttui/ttui.config';
import { lastValueFrom } from 'rxjs';
import { ParcelDetails } from '../models/track-and-trace.model';

@Injectable({
  providedIn: 'root',
})
export class TrackAndTraceClientService {
  constructor(private readonly http: HttpClient) {}

  private get parcelApiUrl() {
    return `${TtUiConfig.ApiUrl}/${TtUiConfig.ParcelController}`;
  }

  public async parcelInfo(
    parcelNo: string,
    verificationCode: string,
    culture: string,
    captcha: string
  ): Promise<ParcelDetails | undefined> {
    for (let retry = 0; retry < 2; retry++) {
      const startTime = new Date().getTime();
      const apiErrorCode = 'Api.Errors.parcelInfo';
      const url = `${this.parcelApiUrl}/${parcelNo}/postalcode/${verificationCode}/details/${culture}`;
      try {
        const response = await lastValueFrom(
          this.http.get<ParcelDetails>(`${url}?captcha=${captcha}`, {
            observe: 'response',
          })
        );
        if (response.status === 200) {
          return response.body ?? undefined;
        }
        if (response.status === 204) {
          return undefined;
        }
      } catch (ex) {
        const timePassedInMilliSecs = new Date().getTime() - startTime;
        console.log(apiErrorCode, { startTime, timePassedInMilliSecs });
      }
    }
    return undefined;
  }
}
