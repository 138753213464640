import { ChangeDetectionStrategy, Component, computed, Signal } from '@angular/core';
import { Store } from '@ngxs/store';
import { BaseTrackAndTraceComponent } from '../../track-and-trace/summary/base-track-and-trace.component';
import { TranslateService } from '@ngx-translate/core';
import { SetActiveWidgetAction, SetInstructionAction } from '../../../state/app.actions';
import { BreakpointObserver } from '@angular/cdk/layout';
import { toSignal } from '@angular/core/rxjs-interop';
import { Constants } from '../../../models/constants';
import { WidgetType } from '../../../state/app.model';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ActionTypes } from '../../../models/gls-info.model';

@Component({
  selector: 'app-instruction-depositpermission',
  templateUrl: './instruction-depositpermission.component.html',
  styleUrls: ['./instruction-depositpermission.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InstructionDepositpermissionComponent extends BaseTrackAndTraceComponent {
  form: FormGroup;
  showAvgError = false;

  breakpoint = toSignal(this.breakpointObserver.observe([Constants.BreakPointMobile]));

  isMobile: Signal<boolean> = computed(() => this.breakpoint()?.matches ?? false);

  isInvalidControl = (name: string): boolean => {
    const control = this.form.controls[name];
    return control.invalid && (control.dirty || control.touched);
  };

  isControlError = (name: string, error: string): boolean => {
    const control = this.form.controls[name];
    return control.errors && control.errors[error];
  };

  isAvgAccepted(): boolean {
    return this.form.get('avg')?.value === true;
  }

  get placeholderDepositLocation(): string {
    return this.translateService.instant('Instruction.DepositPermission.PlaceHolder');
  }

  constructor(
    store: Store,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly translateService: TranslateService,
    public readonly fb: FormBuilder
  ) {
    super(store);
    this.form = this.fb.group({
      depositLocation: ['', [Validators.minLength(5), Validators.maxLength(30), Validators.required]],
      avg: [false, [Validators.required]],
    });
  }

  goBack() {
    this.store.dispatch(new SetActiveWidgetAction(this.rootWidget));
  }

  confirm() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    if (!this.isAvgAccepted()) {
      this.showAvgError = true;
      return;
    }
    let instruction = this.createInstructionModel();
    if (!instruction) return;
    instruction.actionType = ActionTypes.RetryDeliveryWithDepositPermission;
    instruction.allowDeposit = true;
    instruction.depositRemarks = this.form.get('depositLocation')?.value;
    this.store.dispatch([
      new SetInstructionAction(instruction),
      new SetActiveWidgetAction(WidgetType.InstructionConfirm),
    ]);
  }
}
