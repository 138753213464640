import { ChangeDetectionStrategy, Component, OnInit, Signal } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { ResetSearchParcelErrorAction, GetParcelDetailsAction } from '../../state/app.actions';
import { AppState } from '../../state/app.state';
import { toSignal } from '@angular/core/rxjs-interop';
import { ReCaptchaV3Service } from 'ng-recaptcha-2';
import { ParcelIdentifierModel, WidgetStateModel, WidgetType } from '../../state/app.model';
import { BaseComponentComponent } from '../../base/base-component.component';
import { HttpParams } from '@angular/common/http';
import { Location } from '@angular/common';
import { getCaptchaTokenForParcelInfo, getLocale } from '../../services/spotlight.utils';
import { TranslateService } from '@ngx-translate/core';
import { LinkManagerService } from '../../services/linkmanager.service';

@Component({
  selector: 'app-search-parcel',
  templateUrl: './search-parcel.component.html',
  styleUrl: './search-parcel.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchParcelComponent extends BaseComponentComponent implements OnInit {
  widget: Signal<WidgetStateModel | undefined>;
  parcelIdentifier: Signal<ParcelIdentifierModel | undefined>;
  searchError: Signal<boolean | undefined>;
  form: FormGroup;
  showHouseNumber = false;
  emailValidated = false;
  CodeRegex = '[a-zA-Z0-9]+'; // do not allow white spaces or other characters

  isRedirect = false;

  get controls() {
    return this.form.controls;
  }

  get label(): string {
    return 'Search.Parcel.Code';
  }

  get title(): string {
    return 'Search.Parcel.Title1';
  }

  get title2(): string {
    return 'Search.Parcel.Title2';
  }

  get placeholderCode(): string {
    return this.translateService.instant('Search.Parcel.Code');
  }

  get placeholderZipCode(): string {
    return this.translateService.instant('Instruction.ZipCode');
  }

  get inputParcelNumber(): string {
    return this.controls['code'].value;
  }

  get inputZipCode(): string {
    return this.controls['zipCode'].value;
  }

  get isInProgress(): boolean {
    return this.widget()?.isInProgress ?? false;
  }

  get isSearchError(): boolean {
    if (this.isInProgress) {
      return false;
    }
    return this.searchError() ?? false;
  }

  get showLoadingWidget(): boolean {
    return this.isRedirect && !this.searchError();
  }

  get isNotAtHomeCode(): boolean {
    return this.inputParcelNumber?.length === 6;
  }

  constructor(
    public readonly fb: FormBuilder,
    private readonly store: Store,
    private translateService: TranslateService,
    private readonly reCaptchaService: ReCaptchaV3Service,
    private location: Location,
    private linkManagerService: LinkManagerService
  ) {
    super();
    this.widget = toSignal(this.store.select(AppState.widget));
    this.parcelIdentifier = toSignal(this.store.select(AppState.parcelIdentifier));
    this.searchError = toSignal(this.store.select(AppState.searchError));
    this.form = this.fb.group({
      code: [
        this.parcelIdentifier()?.parcelNumber,
        [Validators.minLength(5), Validators.maxLength(14), Validators.required, Validators.pattern(this.CodeRegex)],
      ],
      zipCode: [
        this.parcelIdentifier()?.zipCode,
        [Validators.minLength(4), Validators.maxLength(10), Validators.required, Validators.pattern(this.CodeRegex)],
      ],
    });
  }

  ngOnInit(): void {
    this.register(
      this.form.valueChanges.subscribe(() => {
        this.isRedirect = false;
        if (this.searchError()) {
          this.store.dispatch(new ResetSearchParcelErrorAction());
        }
      })
    );
  }

  isInvalidControl = (name: string): boolean => {
    const control = this.form.controls[name];
    return control.invalid && (control.dirty || control.touched);
  };

  isControlError = (name: string, error: string): boolean => {
    const control = this.form.controls[name];
    return control.errors && control.errors[error];
  };

  async onSearchParcelClicked() {
    if (!(this.inputParcelNumber || this.inputZipCode)) {
      this.form.markAllAsTouched();
      return;
    }
    const params = new HttpParams().appendAll({
      trackid: this.inputParcelNumber,
      zipcode: this.inputZipCode,
    });

    this.location.replaceState(location.pathname, params.toString());
    const culture = getLocale(this.translateService);
    await this.getParcelDetails(culture);
  }

  getParcelDetails = async (culture: string) => {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    if (this.isNotAtHomeCode) {
      await this.linkManagerService.validateNtbCode(this.inputParcelNumber, this.inputZipCode, this.reCaptchaService);

    } else {
      const captcha = (await getCaptchaTokenForParcelInfo(this.reCaptchaService)) ?? '';
      this.store.dispatch(
        new GetParcelDetailsAction(
          WidgetType.ParcelDetails,
          this.inputParcelNumber,
          this.inputZipCode,
          culture,
          captcha
        )
      );
    }
  };

  handlePasteZipCode(event: ClipboardEvent) {
    this.onPasteText(event, 'zipCode');
  }

  handlePasteParcelNo(event: ClipboardEvent) {
    this.onPasteText(event, 'code');
  }

  onPasteText(event: ClipboardEvent, controlName: string) {
    event.preventDefault();
    const pastedText = event.clipboardData?.getData('text') || '';
    const sanitizedText = pastedText.replace(/\s+/g, '');
    const zipCodeControl = this.form.get(controlName);
    zipCodeControl?.setValue(sanitizedText, { emitEvent: false });
  }
}
