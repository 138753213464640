export function isCoordinates(array) {
  return Array.isArray(array) && typeof array[0] === "number" && typeof array[1] === "number";
}
export function isCoordinatesArray(array) {
  return isCoordinates(array[0]);
}
export function isNamedLocation(object) {
  return object && Array.isArray(object.coordinates) && typeof object.coordinates[0] === "number" && typeof object.coordinates[1] === "number";
}
export function isNamedLocationArray(array) {
  return isNamedLocation(array[0]);
}
export function isGeofence(object) {
  return object && typeof object.geofenceId === "string" && typeof object.geometry === "object";
}
export function isGeofenceArray(array) {
  return Array.isArray(array) && isGeofence(array[0]);
}
export function isPolygon(object) {
  return Array.isArray(object) && isCoordinatesArray(object[0]);
}
export function isPolygonArray(array) {
  return Array.isArray(array) && isPolygon(array[0]);
}
export function isGeoJsonSource(source) {
  return source.type === "geojson";
}
export const strHasLength = str => typeof str === "string" && str.length > 0;
export const getFeaturesFromData = data => {
  let features;
  if (isCoordinatesArray(data)) {
    features = data.map(point => {
      return {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: point
        },
        properties: {
          place_name: `Coordinates,${point}`
        }
      };
    });
  } else if (isNamedLocationArray(data)) {
    features = data.map(location => {
      return {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: location.coordinates
        },
        properties: {
          title: location.title,
          address: location.address
        }
      };
    });
  } else {
    features = data;
  }
  return features;
};
export const urlEncodePeriods = str => {
  return str.replace(/\./g, "%2E");
};
export function validateCoordinates(coordinates) {
  const [lng, lat] = coordinates;
  if (!Number.isFinite(lng) || !Number.isFinite(lat)) {
    throw new Error(`Invalid coordinates: [${lng},${lat}]`);
  }
  if (lat < -90 || lat > 90) {
    const errorString = "Latitude must be between -90 and 90 degrees inclusive.";
    console.warn(errorString);
    throw new Error(errorString);
  } else if (lng < -180 || lng > 180) {
    const errorString = "Longitude must be between -180 and 180 degrees inclusive.";
    console.warn(errorString);
    throw new Error(errorString);
  }
}
export function createElement(tagName, className, container) {
  const el = window.document.createElement(tagName);
  if (className !== undefined) el.className = className;
  if (container) container.appendChild(el);
  return el;
}
export function removeElement(node) {
  if (node.parentNode) {
    node.parentNode.removeChild(node);
  }
}