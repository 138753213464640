<div [style.top.px]="top" [style.left.px]="left" [style.position]="'absolute'">
  <div class="widget flex" [class.widget-mobile]="isMobile()" [class.show-notification]="showNotification">
    <div>
      <div class="white-box logo shadow-box">
        <app-gls-selector></app-gls-selector>
      </div>
      <div class="debug" [class.hide-version]="hideVersion">
        Version: {{ version }} | {{ environmentName }} |
        <app-windowsize></app-windowsize>
      </div>
    </div>

    <ng-container *ngIf="showNotification && !isMobile()">
      <app-webnotification></app-webnotification>
    </ng-container>

    <div class="white-box language shadow-box" *ngIf="!isMobile()">
      <button (click)="onDutch()" *ngIf="currentLang === 'en'">
        <div class="language-container">
          <img src="/assets/images/nl.png" />
          <span>NL</span>
        </div>
      </button>
      <button (click)="onEnglish()" *ngIf="currentLang === 'nl'">
        <div class="language-container">
          <img src="/assets/images/en.png" />
          <span>EN</span>
        </div>
      </button>
    </div>

    <div class="white-box notify shadow-box" *ngIf="showNotification && isMobile()">
      <button mat-icon-button (click)="onShowNotification()"><span class="info">i</span></button>
    </div>

    <div class="white-box menu shadow-box">
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>menu</mat-icon>
      </button>
    </div>

    <mat-menu #menu="matMenu">
      <button class="btn-menu" mat-menu-item (click)="onDutch()" *ngIf="isMobile()">
        <img class="menu-image" src="/assets/images/nl.png" />
        <span class="menubtn-span" translate>Menu.Dutch</span>
      </button>

      <button class="btn-menu" mat-menu-item (click)="onEnglish()" *ngIf="isMobile()">
        <img class="menu-image" src="/assets/images/en.png" />
        <span class="menubtn-span" translate>Menu.English</span>
      </button>

      <button class="btn-menu" mat-menu-item  (click)="showShopLocator()">
          <span class="menubtn-span" translate>Menu.Parcelshop</span>
      </button>

      <a href="https://werkenbij.gls.nl/" target="_blank" class="nounderline btn-menu" mat-menu-item>
        <span class="menubtn-span" translate>Menu.WorkAtGls</span></a
      >

      <a
        href="https://gls-group.eu/NL/nl/veelgestelde-vragen/"
        target="_blank"
        class="nounderline btn-menu"
        mat-menu-item
      >
        <span class="menubtn-span" translate>Menu.FAQ</span></a
      >

      <a href="https://pakketinvoer.gls.nl/" target="_blank" class="nounderline btn-menu" mat-menu-item>
        <span class="menubtn-span" translate>Menu.Shipping</span></a
      >

      <a href="https://neerzettoestemming.gls.nl/" target="_blank" class="nounderline btn-menu" mat-menu-item>
        <span class="menubtn-span" translate>Menu.DepositPermission</span></a
      >
    </mat-menu>
  </div>
</div>
