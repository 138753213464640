import { Action, Selector, State, StateContext } from '@ngxs/store';
import { AppStateModel, AllowedOptionType, NotificationModel, ParcelIdentifierModel, WidgetStateModel, WidgetType } from './app.model';
import { Injectable } from '@angular/core';
import {
  GetEmergencyNotificationsAction,
  HideEmergencyNotificationAction,
  ResetSearchParcelErrorAction,
  GetParcelDetailsAction,
  SetActiveWidgetAction,
  SetParcelDetailsAction,
  ToggleWidgetExpansionAction,
  RefreshParcelDetailsAction,
  SetConsentAction,
  SetMapVisibilityAction,
  ExpandWidgetExpansionAction,
  ResetParcelIdentifierAction,
  SetInstructionValidationResultAction,
  SetSelectedCollectionPointAction,
  SetInstructionValidationRequestAction,
  SetInstructionAction,
  SetInstructionFlowAction,
  SetInstructionResultAction,
} from './app.actions';
import { ParcelDetails } from '../models/track-and-trace.model';
import { CommonUiClientService } from '../clients/common-ui.client';
import { TrackAndTraceService } from '../services/track-and-trace.service';
import { TranslateService } from '@ngx-translate/core';
import { InstructionValidationResponse } from '../models/instruction-validation-response';
import { CreateInstructionRequestModel, InstructionOption, InstructionResult, ParcelCollectionPoint } from '../models/gls-info.model';
import { InstructionValidationRequest } from '../models/instruction-validation-request';
import { getOptions } from '../services/instruction.utils';

@State<AppStateModel>({
  name: 'app',
  defaults: {
    notification: {
      visible: true,
      notifications: []
    },
    allowedOptions: []
  },
})
@Injectable()
export class AppState {
  constructor(
    private readonly trackAndTraceService: TrackAndTraceService,
    private readonly clientService: CommonUiClientService,
    private readonly translateService: TranslateService
  ) {}

  @Selector() static instructionResult(app: AppStateModel): InstructionResult | undefined {
    return app.instructionResult;
  }

  @Selector() static inInstructionFlow(app: AppStateModel): boolean | undefined {
    return app.inInstructionFlow;
  }

  @Selector() static instructionValidationRequest(app: AppStateModel): InstructionValidationRequest | undefined {
    return app.instructionValidationRequest;
  }

  @Selector() static instructionValidationResult(app: AppStateModel): InstructionValidationResponse | undefined {
    return app.instructionValidationResult;
  }

  @Selector() static instruction(app: AppStateModel): CreateInstructionRequestModel | undefined {
    return app.instruction;
  }

  @Selector() static selectedCollectionPoint(app: AppStateModel): ParcelCollectionPoint | undefined {
    return app.selectedCollectionPoint;
  }

  @Selector() static widget(app: AppStateModel): WidgetStateModel | undefined {
    return app.widget;
  }

  @Selector() static searchError(app: AppStateModel): boolean | undefined {
    return app.searchError;
  }

  @Selector() static isMapVisible(app: AppStateModel): boolean | undefined {
    return app.isMapVisible;
  }

  @Selector() static parcelIdentifier(app: AppStateModel): ParcelIdentifierModel | undefined {
    return app.parcelIdentifier;
  }

  @Selector() static parcelDetails(app: AppStateModel): ParcelDetails | undefined {
    return app.parcelDetails;
  }

  @Selector() static notification(app: AppStateModel): NotificationModel {
    return app.notification;
  }

  @Selector() static consent(app: AppStateModel): string | undefined {
    return app.consent;
  }

  @Selector() static allowedOptions(app: AppStateModel): AllowedOptionType[] {
    return app.allowedOptions;
  }

  @Action(SetConsentAction)
  async actionSetConsentAction(ctx: StateContext<AppStateModel>, { tcf }: SetConsentAction) {
    ctx.patchState({ consent: tcf });
  }

  @Action(GetParcelDetailsAction)
  async widgetSearchParcelAction(
    ctx: StateContext<AppStateModel>,
    { widgetType, parcelNumber, zipCode, captcha, culture }: GetParcelDetailsAction
  ) {
    const prevState = ctx.getState();
    const widget: WidgetStateModel = {
      widgetType: WidgetType.LoadingSkeleton,
      isExpanded: prevState.widget?.isExpanded ?? false,
      isInProgress: true,
    };
    ctx.patchState({ widget });
    const parcel = await this.trackAndTraceService.parcelInfo(parcelNumber, zipCode, culture, captcha);
    const isSuccess = parcel !== undefined;
    ctx.dispatch(new SetParcelDetailsAction(widgetType, parcelNumber, zipCode, isSuccess, parcel));
  }

  @Action(RefreshParcelDetailsAction)
  async refreshParcelDetailsAction(ctx: StateContext<AppStateModel>, { culture, captcha }: RefreshParcelDetailsAction) {
    const prevState = ctx.getState();
    const widget = prevState?.widget;
    const parcelNumber = prevState?.parcelIdentifier?.parcelNumber;
    const zipCode = prevState?.parcelIdentifier?.zipCode;
    if (!(widget && parcelNumber && zipCode)) {
      return;
    }
    ctx.patchState({ widget: { ...widget, isInProgress: true } });
    const parcel = await this.trackAndTraceService.parcelInfo(parcelNumber, zipCode, culture, captcha);
    const isSuccess = parcel !== undefined;
    ctx.dispatch(new SetParcelDetailsAction(WidgetType.ParcelDetails, parcelNumber, zipCode, isSuccess, parcel));
  }

  @Action(SetParcelDetailsAction)
  widgetSearchParcelResultAction(
    ctx: StateContext<AppStateModel>,
    { widgetType, parcelNumber, zipCode, isSuccess, parcelDetails }: SetParcelDetailsAction
  ) {
    const prevState = ctx.getState();
    const widget: WidgetStateModel = {
      widgetType: isSuccess ? widgetType : WidgetType.SearchParcel,
      isExpanded: prevState.widget?.isExpanded ?? false,
      isInProgress: false,
    };
    const parcelIdentifier: ParcelIdentifierModel = {
      parcelNumber,
      zipCode,
    };

    const searchError = !isSuccess;
    ctx.patchState({
      widget,
      searchError,
      parcelIdentifier,
      parcelDetails,
      selectedCollectionPoint: undefined,
    });
  }

  @Action(ResetParcelIdentifierAction)
  resetParcelIdentifierAction(ctx: StateContext<AppStateModel>) {
    ctx.patchState({
      parcelIdentifier: undefined,
      parcelDetails: undefined,
      instructionValidationRequest: undefined,
      instructionValidationResult: undefined,
      selectedCollectionPoint: undefined,
      instruction: undefined,
      inInstructionFlow: false,
    });
  }

  @Action(ResetSearchParcelErrorAction)
  resetSearchParcelErrorAction(ctx: StateContext<AppStateModel>) {
    const searchError = false;
    ctx.patchState({ searchError });
  }

  @Action(SetActiveWidgetAction)
  setWidgetAppearanceAction(ctx: StateContext<AppStateModel>, { widgetType }: SetActiveWidgetAction) {
    const state = ctx.getState();
    if (state.widget) {
      const widget = { ...state.widget, widgetType };
      ctx.patchState({ widget });
    } else {
      ctx.patchState({
        widget: {
          isExpanded: false,
          isInProgress: false,
          widgetType: widgetType,
        },
      });
    }

    if (widgetType === WidgetType.SearchParcel || widgetType === WidgetType.ParcelDetails) {
      ctx.patchState({
        inInstructionFlow: false,
      });
    }
    
    if (widgetType === WidgetType.InstructionSelectDeliveryOption) {
      ctx.patchState({
        inInstructionFlow: true,
      });
    }
  }

  @Action(GetEmergencyNotificationsAction)
  async getEmergencyNotificationsAction(
    ctx: StateContext<AppStateModel>,
    { audience }: GetEmergencyNotificationsAction
  ) {
    const notifications = (await this.clientService.getWebNotifications(audience)) ?? [];
    return ctx.patchState({
      notification: {
        notifications,
        visible: true,
      },
    });
  }

  @Action(HideEmergencyNotificationAction)
  hideEmergencyNotificationAction(ctx: StateContext<AppStateModel>) {
    const prevState = ctx.getState();
    const notification: NotificationModel = {
      ...prevState.notification,
      visible: false,
    };
    ctx.patchState({ notification });
  }

  @Action(ToggleWidgetExpansionAction)
  toggleWidgetExpansionAction(ctx: StateContext<AppStateModel>) {
    const prevState = ctx.getState();
    const widget = prevState.widget;
    if (widget) {
      ctx.patchState({ widget: { ...widget, isExpanded: !widget.isExpanded } });
    }
  }

  @Action(ExpandWidgetExpansionAction)
  expandWidgetExpansionAction(ctx: StateContext<AppStateModel>) {
    const prevState = ctx.getState();
    const widget = prevState.widget;
    if (widget) {
      ctx.patchState({ widget: { ...widget, isExpanded: true } });
    }
  }

  @Action(SetMapVisibilityAction)
  setMapVisibilityAction(ctx: StateContext<AppStateModel>, { isVisibleMap }: SetMapVisibilityAction) {
    ctx.patchState({ isMapVisible: isVisibleMap });
  }

  @Action(SetInstructionValidationRequestAction)
  setInstructionValidationRequestAction(
    ctx: StateContext<AppStateModel>,
    { instructionValidationRequest }: SetInstructionValidationRequestAction
  ) {
    ctx.patchState({ instructionValidationRequest });
  }

  @Action(SetInstructionValidationResultAction)
  setInstructionValidationResultAction(
    ctx: StateContext<AppStateModel>,
    { instructionValidationResult }: SetInstructionValidationResultAction
  ) {
    const instructionOptions = getOptions(instructionValidationResult);
    ctx.patchState({ instructionValidationResult, allowedOptions: instructionOptions });
  }

  @Action(SetSelectedCollectionPointAction)
  setSelectedCollectionPointAction(
    ctx: StateContext<AppStateModel>,
    { collectionPoint }: SetSelectedCollectionPointAction
  ) {
    ctx.patchState({ selectedCollectionPoint: collectionPoint });
  }

  @Action(SetInstructionAction)
  setInstructionAction(ctx: StateContext<AppStateModel>, { instruction }: SetInstructionAction) {
    ctx.patchState({ instruction });
  }

  @Action(SetInstructionFlowAction)
  setInstructionFlowAction(ctx: StateContext<AppStateModel>, { inInstructionFlow }: SetInstructionFlowAction) {
    ctx.patchState({ inInstructionFlow });
  }

  @Action(SetInstructionResultAction)
  setInstructionResultAction(ctx: StateContext<AppStateModel>, { instructionResult }: SetInstructionResultAction) {
    ctx.patchState({ instructionResult });
  }
}
