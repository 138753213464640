<div class="card-tt-details">
  <div class="widget-container">
    <div class="primary-column" [class.mobile]="isMobile()">
      <div class="primary-content" [class.desktop]="!isMobile()">
        <div class="card">
          <div class="title spotlight-blue" translate>Instruction.Result.InstructionNotPossible</div>
          <div class="spotlight-black">{{ description | translate }}</div>
        </div>
      </div>
      <div class="primary-footer" *ngIf="!isMobile()">
        <div class="return-block">
          <button mat-button class="btn btn-secondary w280" (click)="showParcelDetails()" *ngIf="canShowTrackAndTrace">
            {{ 'Instruction.BackToParcelDetails' | translate }}
          </button>
          <button mat-button class="btn btn-secondary w280" (click)="returnToSearch()" *ngIf="!canShowTrackAndTrace">
            {{ 'Buttons.TrackAnother' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
