import { ChangeDetectionStrategy, Component, computed, Signal } from '@angular/core';
import { Store } from '@ngxs/store';
import { HideEmergencyNotificationAction } from '../../state/app.actions';
import { NotificationModel } from '../../state/app.model';
import { AppState } from '../../state/app.state';
import { toSignal } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs';
import { WebNotification } from '../../models/common.model';
import { MatDialog } from '@angular/material/dialog';
import { WebnotificationDetailsComponent } from './webnotification-details.component';

@Component({
  selector: 'app-webnotification',
  templateUrl: './webnotification.component.html',
  styleUrl: './webnotification.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WebnotificationComponent {
  notification: Signal<NotificationModel | undefined>;
  language = toSignal(
    this.translate.onLangChange.pipe(map(() => this.translate.currentLang))
  );

  webNotification: Signal<WebNotification | undefined> = computed(() => {
    const notifications = this.notification()?.notifications;
    const language = this.language() ?? this.translate.currentLang;
    if (notifications && notifications.length > 0) {
      return notifications.find((m) => m.culture.startsWith(language));
    }
    return undefined;
  });

  get message(): string {
    return this.webNotification()?.title ?? '';
  }

  get details(): string {
    return this.webNotification()?.text ?? '';
  }

  constructor(
    private readonly store: Store,
    private translate: TranslateService,
    private readonly dialog: MatDialog
  ) {
    this.notification = toSignal(this.store.select(AppState.notification));
  }

  hideNotification = () =>
    this.store.dispatch(new HideEmergencyNotificationAction());

  openDetails = (): void => {
    this.dialog.open(WebnotificationDetailsComponent, {
      data: {
        title: this.message,
        html: this.details,
      },
      autoFocus: false,
      width: '700px',
    });
  };
}
