<app-skeleton-parcel-search *ngIf="showLoadingWidget()"></app-skeleton-parcel-search>

<div class="primary-column" [class.mobile]="isMobile()" *ngIf="!showLoadingWidget()">
  <div class="primary-content">
    <div class="header">
      <button class="btn-horizontal-expander size-40 size-40-expander" aria-label="Expand" (click)="goBack()">
        <mat-icon class="expanded">navigate_next</mat-icon>
      </button>
      <div class="spotlight-blue title" translate>Instruction.DeliverToCollectionPoint</div>
    </div>

    <ng-container *ngIf="canDeliverToCollectionPoint && selectedCollectionPoint()">
      <app-parcel-collection-point
        [collectionPoint]="selectedCollectionPoint()"
        [showtime]="true"
        [showtitle]="false"
        [allowSelectCollectionPoint]="true"
        (selectCollectionPoint)="selectDifferentCollectionPoint()"
      ></app-parcel-collection-point>
    </ng-container>
  </div>

  <div class="primary-footer">
    <button mat-button class="btn btn-primary fullwidth" (click)="confirm()">
      {{ 'Instruction.ContinueWithNextStep' | translate }}
    </button>
  </div>
</div>
