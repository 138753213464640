export const Translations_en = {
  Common: {
    Translate: 'Common translated',
    Feedback: 'Feedback',
  },
  Languages: {
    nl: {
      a2: 'NL',
      full: 'Dutch',
    },
    en: {
      a2: 'EN',
      full: 'English',
    },
  },
};
