<app-skeleton-parcel-search *ngIf="showLoadingWidget()"></app-skeleton-parcel-search>

<div class="primary-column" [class.mobile]="isMobile()" *ngIf="!showLoadingWidget()">
  <div class="primary-content">
    <div class="header">
      <button class="btn-horizontal-expander size-40 size-40-expander" aria-label="Expand" (click)="goBack()">
        <mat-icon class="expanded">navigate_next</mat-icon>
      </button>
      <div class="spotlight-blue title" *ngIf="!isPickup" translate>Instruction.HomeAddress.DeliverOn</div>
      <div class="spotlight-blue title" *ngIf="isPickup"  translate>Instruction.HomeAddress.PickupAt</div>
    </div>

    <div class="card">
      <div class="card-inner">
        <form [formGroup]="form" class="form">
          <div class="delivery-moment">
            <div class="form-field-icon-holder form-control_icon-info invalid-value-missing">
              <mat-select formControlName="date" class="combo">
                <mat-option *ngFor="let date of availableDates" [value]="date">
                  <span class="time">{{ deliveryTime(date) }}</span>
                </mat-option>
              </mat-select>
            </div>
          </div>

          <!--At neigbours-->
          <mat-checkbox class="neighbours" formControlName="neighbours" *ngIf="!isPickup">
            <div class="spotlight-gray neigbours-description" translate>
              Instruction.HomeAddress.DeliverAtNeighbours
            </div>
          </mat-checkbox>

          <!-- Neighours HouseNo -->
          <div
            class="houseno-container form-group form-group_with_icon form-group_with_feedback"
            [class.is-invalid]="isInvalidControl('houseNo')"
            *ngIf="!isPickup"
          >
            <div class="invalid-value-missing">
              <input
                id="houseNo"
                data-cy="tbhouseNo"
                formControlName="houseNo"
                type="text"
                class="input-houseNo form-control"
                autocomplete="houseNo"
                appTrimInput
                #houseNo
                minlength="1"
                maxlength="10"
              />
            </div>

            <ng-container *ngIf="isInvalidControl('houseNo')">
              <div
                *ngIf="isControlError('houseNo', 'required')"
                class="feedback"
                [translateParams]="{
                  fieldName: ('Instruction.HomeAddress.HouseNumber' | translate | lowercase)
                }"
                translate
              >
                Validations.Required.Field
              </div>
            </ng-container>
          </div>
        </form>

        <div class="spotlight-gray spotlight-footnote disclaimer" translate>Instruction.HomeAddress.Disclaimer</div>
      </div>
    </div>
  </div>

  <div class="primary-footer">
    <button mat-button class="btn btn-primary fullwidth" (click)="confirm()">
      {{ 'Instruction.ContinueWithNextStep' | translate }}
    </button>
  </div>
</div>
