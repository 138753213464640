import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseTrackAndTraceComponent } from '../base-track-and-trace.component';
import { renderDateAndTime } from '../../../../services/track-and-trace.utils';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-track-and-trace-date-title',
  templateUrl: './track-and-trace-date-title.component.html',
  styleUrl: './track-and-trace-date-title.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrackAndTraceDateTitleComponent extends BaseTrackAndTraceComponent {
  get lastUpdate(): string | undefined {
    if (this.isDeliveredAtCollectionPoint) return undefined;

    const deliveryDate = this.details?.deliveryScanInfo?.dateTime;
    const lastDate = deliveryDate !== undefined && deliveryDate !== '0001-01-01T00:00:00' ? deliveryDate : undefined;
    return renderDateAndTime(this.translateService, lastDate);
  }

  constructor(store: Store, private translateService: TranslateService) {
    super(store);
  }
}
