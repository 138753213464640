import { ChangeDetectionStrategy, Component, computed, Signal } from '@angular/core';
import { BaseTrackAndTraceComponent } from '../base-track-and-trace.component';
import { BreakpointObserver } from '@angular/cdk/layout';
import { toSignal } from '@angular/core/rxjs-interop';
import { Constants } from '../../../../models/constants';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-delivery-sustainable',
  templateUrl: './delivery-sustainable.component.html',
  styleUrl: './delivery-sustainable.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliverySustainableComponent extends BaseTrackAndTraceComponent {
  breakpoint = toSignal(this.breakpointObserver.observe([Constants.BreakPointMobile]));

  isMobile: Signal<boolean> = computed(() => this.breakpoint()?.matches ?? false);

  constructor(store: Store, private readonly breakpointObserver: BreakpointObserver) {
    super(store);
  }
}
