import { ChangeDetectionStrategy, Component, computed, OnInit, signal, Signal } from '@angular/core';
import { Store } from '@ngxs/store';
import { BaseTrackAndTraceComponent } from '../../track-and-trace/summary/base-track-and-trace.component';
import { BreakpointObserver } from '@angular/cdk/layout';
import { toSignal } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from '../../../models/constants';
import { SetActiveWidgetAction, SetInstructionAction } from '../../../state/app.actions';
import { WidgetType } from '../../../state/app.model';
import { InstructionClientService } from '../../../clients/instruction.client';
import { ActionTypes, InstructionType } from '../../../models/gls-info.model';
import { getLocale } from '../../../services/spotlight.utils';
import { isToday } from 'date-fns';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

interface DateAndTimeFrame {
  date: string;
  startTime: string;
  endTime: string;
}

@Component({
  selector: 'app-instruction-other-moment',
  templateUrl: './instruction-deliver-other-moment.component.html',
  styleUrls: ['./instruction-deliver-other-moment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InstructionDeliveryOtherTimeComponent extends BaseTrackAndTraceComponent implements OnInit {
  form: FormGroup;
  showLoadingWidget = signal(false);
  breakpoint = toSignal(this.breakpointObserver.observe([Constants.BreakPointMobile]));

  isMobile: Signal<boolean> = computed(() => this.breakpoint()?.matches ?? false);

  availableDates: DateAndTimeFrame[] = [];

  deliveryTime(date: DateAndTimeFrame | undefined): string {
    return date ? `${this.renderFullDate(date?.date)} ${date?.startTime} -  ${date?.endTime}` : '';
  }

  isInvalidControl = (name: string): boolean => {
    const control = this.form.controls[name];
    return control.invalid && (control.dirty || control.touched);
  };

  isControlError = (name: string, error: string): boolean => {
    const control = this.form.controls[name];
    return control.errors && control.errors[error];
  };

  get isNeighboursOptionActive(): boolean {
    return this.form.get('neighbours')?.value;
  }

  constructor(
    store: Store,
    private readonly breakpointObserver: BreakpointObserver,
    private instructionClient: InstructionClientService,
    private translateService: TranslateService,
    public readonly fb: FormBuilder
  ) {
    super(store);

    this.form = this.fb.group({
      date: [, Validators.required],
      neighbours: [false],
      houseNo: [''],
    });

    this.form.get('neighbours')?.valueChanges.subscribe((neighbours) => {
      if (!neighbours) {
        this.form.get('houseNo')?.removeValidators([Validators.required, Validators.maxLength(10)]);
        this.form.get('houseNo')?.setValue('');
        this.form.get('houseNo')?.updateValueAndValidity();
      } else if (neighbours) {
        this.form.get('houseNo')?.addValidators([Validators.required, Validators.maxLength(10)]);
        this.form.get('houseNo')?.markAllAsTouched();
        this.form.get('houseNo')?.updateValueAndValidity();
      }
    });
  }

  async ngOnInit() {
    const instructionValidateRequest = this.instructionValidateRequest();
    const instructionValidateResult = this.instructionValidateResult();
    if (!this.details || !instructionValidateResult || !instructionValidateRequest) return;

    this.showLoadingWidget.set(true);
    const result = await this.instructionClient.getEta({
      actionType: ActionTypes.RetryDelivery,
      instructionType: instructionValidateResult.instructionType ?? InstructionType.FlexDelivery,
      code: this.details.parcelNo,
      zipCode: instructionValidateResult.leadingAddress.zipCode,
      targetZipCode: instructionValidateResult.leadingAddress.zipCode,
      houseNo: instructionValidateResult.leadingAddress.houseNo,
      checksum: instructionValidateRequest.checksum,
      data: instructionValidateRequest.csiData,
      deepLinkId: instructionValidateRequest.iddeeplink ?? '',
    });

    this.availableDates = [];

    if (result) {
      result?.availableDays.forEach((date) => {
        date.timeFrames.forEach((timeframe) => {
          this.availableDates.push({
            date: date.date,
            startTime: timeframe.startTime,
            endTime: timeframe.endTime,
          });
        });
      });
    }
    if (this.availableDates.length > 0) {
      this.form.patchValue({ date: this.availableDates[0] });
    }
    this.showLoadingWidget.set(false);
  }

  goBack() {
    this.store.dispatch(new SetActiveWidgetAction(this.rootWidget));
  }

  confirm() {
    if (!this.form.valid) return;
    const date = this.form.get('date')?.value;
    if (!date) return;
    const allowNeighbours = this.form.get('neighbours')?.value;
    const instruction = {
      ...this.createInstructionModel()!,
      actionType: ActionTypes.RetryDelivery,
      actionDate: date.date ?? '',
      timeSpanStart: date.startTime ?? '',
      timeSpanEnd: date.endTime ?? '',
      allowNeighbours: allowNeighbours,
      neighboursHouseNo: allowNeighbours ? this.form.get('houseNo')?.value : '',
    };
    this.store.dispatch([
      new SetInstructionAction(instruction),
      new SetActiveWidgetAction(WidgetType.InstructionConfirm),
    ]);
  }

  renderFullDate = (timestamp: string | null | undefined): string => {
    if (!timestamp) {
      return '';
    }
    const date = new Date(timestamp);
    if (isToday(date)) {
      return this.translateService.instant('Eta.today');
    }
    return date.toLocaleDateString(getLocale(this.translateService), {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
    });
  };
}
