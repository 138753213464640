import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseTrackAndTraceComponent } from '../base-track-and-trace.component';
import { getParcelStateTranslationKey } from '@spotlight/services/track-and-trace.utils';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-track-and-trace-status-title',
  templateUrl: './track-and-trace-status-title.component.html',
  styleUrl: './track-and-trace-status-title.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrackAndTraceStatusTitleComponent extends BaseTrackAndTraceComponent {
  get title(): string {
    return getParcelStateTranslationKey(this.details);
  }

  get subTitle(): string | undefined {
    if (this.isNotDelivered && !this.showEta) {
      return this.lastPhysicalScan?.eventReasonDescr ?? '';
    }
    return undefined;
  }
}
