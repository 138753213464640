<div class="primary-column" [class.mobile]="isMobile()">
  <div class="primary-content">
    <div class="header">
      <button class="btn-horizontal-expander size-40 size-40-expander" aria-label="Expand" (click)="goBack()">
        <mat-icon class="expanded">navigate_next</mat-icon>
      </button>
      <div class="spotlight-blue title" translate>Instruction.HomeAddress.DeliverAtNeighbours</div>
    </div>

    <div class="card">
      <div class="card-inner">
        <div class="spotlight-black" translate>Instruction.HomeAddress.Neighbours</div>
        <form [formGroup]="form" class="form">
          <!-- Neighours HouseNo -->
          <div
            class="houseno-container form-group form-group_with_icon form-group_with_feedback"
            [class.is-invalid]="isInvalidControl('houseNo')"
          >
            <div class="invalid-value-missing">
              <input
                id="houseNo"
                data-cy="tbhouseNo"
                formControlName="houseNo"
                type="text"
                class="input-houseNo form-control"
                autocomplete="houseNo"
                appTrimInput
                #houseNo
                minlength="1"
                maxlength="10"
              />
            </div>

            <ng-container *ngIf="isInvalidControl('houseNo')">
              <div
                *ngIf="isControlError('houseNo', 'required')"
                class="feedback"
                [translateParams]="{
                  fieldName: ('Instruction.HomeAddress.HouseNumber' | translate)
                }"
                translate
              >
                Validations.Required.Field
              </div>
            </ng-container>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="primary-footer">
    <button mat-button class="btn btn-primary fullwidth" (click)="confirm()">
      {{ 'Instruction.ContinueWithNextStep' | translate }}
    </button>
  </div>
</div>
