import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TtUiConfig } from '../environments/ttui/ttui.config';
import { lastValueFrom } from 'rxjs';
import { InstructionValidationRequest } from '../models/instruction-validation-request';
import { InstructionValidationResponse } from '../models/instruction-validation-response';
import {
  CreateInstructionRequestModel,
  ExpectedDeliveryResult,
  GetExpectedDeliveryRequest,
  InstructionResult,
  InstructionType,
  ValidateAciRequest,
} from '../models/gls-info.model';

@Injectable({
  providedIn: 'root',
})
export class InstructionClientService {
  constructor(private readonly http: HttpClient) {}

  public needsZipCode(request: InstructionValidationRequest) {
    if (request.zipCode) return false;

    switch (request.instructionType) {
      case InstructionType.FlexDelivery:
        if (request.iddeeplink) return false;
        return true;

      case InstructionType.ShopDelivery:
        return true;

      case InstructionType.ShopDeliveryPlus:
        return true;

      case InstructionType.NotAtHome:
        if (request.iddeeplink) return false;
        return true;
    }
    return false;
  }

  private getValidateUrl(request: InstructionValidationRequest): [string, string, object | undefined] {
    let method = 'get';
    let url = '';
    let body = undefined;
    switch (request.instructionType) {
      case InstructionType.ACI:
        method = 'post';
        url = `${TtUiConfig.ApiUrl}/instruction/api/aci/v1/validate`;
        body = {
          parcelNumber: request.code,
          email: request.email,
        };
        break;

      case InstructionType.AciAdresConfirmed:
        url = `${TtUiConfig.ApiUrl}/instruction/api/aci/v1/confirm-address/${request.iddeeplink}`;
        break;

      case InstructionType.FlexDelivery:
        url = request.iddeeplink
          ? `${TtUiConfig.ApiUrl}/instruction/api/flexdelivery/v1/${request.iddeeplink}/deeplink`
          : `${TtUiConfig.ApiUrl}/instruction/api/flexdelivery/v1/${request.code}/validate/${request.zipCode}`;
        break;

      case InstructionType.ShopDelivery:
        url = `${TtUiConfig.ApiUrl}/instruction/api/shopdelivery/v1/${request.code}/validate/${request.zipCode}`;
        break;

      case InstructionType.ShopDeliveryPlus:
        url = `${TtUiConfig.ApiUrl}/instruction/api/shopdeliveryplus/v1/${request.code}/validate/${request.zipCode}`;
        break;

      case InstructionType.NotAtHome:
        url = request.iddeeplink
          ? `${TtUiConfig.ApiUrl}/instruction/api/ntbcard/v1/${request.iddeeplink}/deeplink`
          : `${TtUiConfig.ApiUrl}/instruction/api/ntbcard/v1/${request.code}/validate/${request.zipCode}`;
        break;

      case InstructionType.CSI:
        method = 'post';
        url = `${TtUiConfig.AksUrl}/instruction-api/api/csi/v1/validate`;
        body = {
          code: request.code,
          csiChecksum: request.checksum,
          csiData: request.csiData,
          validateEntireShipment: request.validateEntireShipment,
        };
        break;

      case InstructionType.ShopDepositReceipt:
        url = `${TtUiConfig.ApiUrl}/instruction/api/aci/v1/validate`;
        break;

      case InstructionType.OTFI:
        url = `${TtUiConfig.ApiUrl}/instruction/api/otfi/v1/${request.iddeeplink}/deeplink`;
        break;

      case InstructionType.AplNotDelivered:
        url = `${TtUiConfig.ApiUrl}/instruction/api/apldeliveryfailed/v1/${request.iddeeplink}`;
        break;
    }
    return [method, url, body];
  }

  public async getEta(request: GetExpectedDeliveryRequest): Promise<ExpectedDeliveryResult | undefined> {
    const apiErrorCode = 'Api.Errors.getExpectedDeliveryDateAndTime';
    try {
      const url = `${TtUiConfig.ApiUrl}/instruction/api/eta/v1`;
      const response = await lastValueFrom(
        this.http.post<ExpectedDeliveryResult>(url, request, {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
          }),
          observe: 'response',
        })
      );

      if (response.status === 200) {
        return response.body ?? undefined;
      }
    } catch (ex) {}

    return undefined;
  }

  public async validate(
    request: InstructionValidationRequest,
    captcha: string
  ): Promise<InstructionValidationResponse | undefined> {
    const [method, url, body] = this.getValidateUrl(request);
    const finalUrl = url + '?captcha=' + captcha;
    try {
      let response: HttpResponse<InstructionValidationResponse> | undefined = undefined;
      if (method == 'post') {
        response = await lastValueFrom(
          this.http.post<InstructionValidationResponse>(finalUrl, body, {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
            }),
            observe: 'response',
          })
        );
      } else {
        response = await lastValueFrom(
          this.http.get<InstructionValidationResponse>(finalUrl, {
            observe: 'response',
          })
        );
      }
      if (response.status === 200) {
        return response.body ?? undefined;
      }
      if (response.status === 204) {
        return undefined;
      }
    } catch (ex) {
      console.log(ex);
    }
    return undefined;
  }

  public async validateAci(
    request: ValidateAciRequest,
    captcha: string
  ): Promise<InstructionValidationResponse | undefined> {
    const finalUrl = `${TtUiConfig.ApiUrl}/instruction/api/aci/v1/validate?captcha=${captcha}`;
    try {
      const response = await lastValueFrom(
        this.http.post<InstructionValidationResponse>(finalUrl, request, {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
          }),
          observe: 'response',
        })
      );
      if (response.status === 200) {
        return response.body ?? undefined;
      }
      if (response.status === 204) {
        return undefined;
      }
    } catch (ex) {
      console.log(ex);
    }
    return undefined;
  }

  public async createInstruction(
    request: CreateInstructionRequestModel,
    captcha: string
  ): Promise<InstructionResult | undefined> {
    const finalUrl = `${TtUiConfig.ApiUrl}/instruction/api/instruction/v1?captcha=${captcha}`;
    try {
      const response = await lastValueFrom(
        this.http.post<InstructionResult>(finalUrl, request, {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
          }),
          observe: 'response',
        })
      );
      if (response.status === 200) {
        return response.body ?? undefined;
      }
      if (response.status === 204) {
        return undefined;
      }
    } catch (ex) {
      console.log(ex);
    }
    return undefined;
  }
}
