<div class="container">
  <app-track-and-trace-status-title></app-track-and-trace-status-title>
  <app-delivery-date></app-delivery-date>
  <app-parcel-progress></app-parcel-progress>
  <app-delivery-sustainable></app-delivery-sustainable>
  <ng-container *ngIf="etaWarning">
    <div
      class="spotlight-gray spotlight-footnote"
      [translate]="etaWarning"
    ></div>
  </ng-container>
  <app-eta-stops-left></app-eta-stops-left>
  <app-deposit-location></app-deposit-location>
  <div class="spotlight-gray spotlight-footnote" [translate]="checkTimes"></div>
  <app-parcel-collection-point *ngIf="collectionPoint" [collectionPoint]="collectionPoint"
  ></app-parcel-collection-point>
</div>
