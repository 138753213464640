import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseTrackAndTraceComponent } from '../base-track-and-trace.component';
import { RouteAddress } from '../../../../models/track-and-trace.model';

@Component({
  selector: 'app-address-block',
  templateUrl: './address-block.component.html',
  styleUrl: './address-block.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressBlockComponent extends BaseTrackAndTraceComponent {
  get sender(): RouteAddress | undefined {
    return this.parcel()?.addressInfo?.from;
  }

  get deliveryAddress(): RouteAddress | undefined {
    return this.parcel()?.addressInfo?.to;
  }
}
