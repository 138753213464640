import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { formatDate } from '@angular/common';
import { Address, Scan, ScanType } from '../../../../models/track-and-trace.model';
import { getLocale, isParcelShopEvent } from '../../../../services/spotlight.utils';
import { TranslateService } from '@ngx-translate/core';
import { translationKeyStatusInfo } from '../../../../services/track-and-trace.utils';

@Component({
  selector: 'app-scan-details-component',
  templateUrl: './scan-details-component.component.html',
  styleUrl: './scan-details-component.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScanDetailsComponentComponent {
  @Input() scan?: Scan;

  parcelShopAddress?: Address;

  get date(): string | undefined {
    if (this.scan) {
      return this.renderFullDate(this.scan);
    }
    return undefined;
  }

  get title(): string | undefined {
    if (this.scan) {
      return this.scan.eventReasonDescr;
    }
    return undefined;
  }

  get description(): string | undefined {
    if (this.scan) {
      return this.renderDescription(this.scan);
    }
    return undefined;
  }

  constructor(private readonly translate: TranslateService) {}

  renderFullDate = (scan: Scan): string => {
    const scanDate = new Date(scan.dateTime);
    const date = formatDate(scanDate, 'd MMMM', getLocale(this.translate));
    const time = formatDate(scanDate, 'shortTime', getLocale(this.translate));

    return `${date}, ${time}`;
  };

  renderDescription(scan: Scan): string | undefined {
    switch (scan.scanType) {
      case ScanType.Physical:
      case ScanType.Depot:
        return scan.depotName && scan.depotName !== '-' && scan.countryName
          ? `${scan.depotName}, ${scan.countryName}`
          : undefined;
    }

    const text =
      isParcelShopEvent(scan) && this.parcelShopAddress
        ? this.address(this.parcelShopAddress)
        : scan.eventReasonDescrAltCust;

    if (scan.eventReasonDescrAltCons && text) {
      return `${this.translate.instant(translationKeyStatusInfo(scan))} ${text}`;
    }

    return undefined;
  }

  address = (parcelshop: Address): string =>
    `${parcelshop.name}, ${parcelshop.street} ${parcelshop.houseNo}${parcelshop.houseNoAdd}, ${parcelshop.city}`;
}
