<ul class="block">
  <div class="header" (click)="toggleExpansion()">
    <div class="spotlight-blue spotlight-subtitle center-vertical" translate>
      TrackAndTrace.Details.Scans
    </div>
    <button class="btn-vertical-expander" aria-label="Expand">
      <mat-icon [class]="isExpanded ? 'expanded' : 'collapsed'"
        >keyboard_arrow_down</mat-icon
      >
    </button>
  </div>
  <div
    class="expander-content"
    [@expandCollapse]="isExpanded ? 'expanded' : 'collapsed'"
  >
    <li *ngFor="let scan of scans">
      <app-scan-details-component [scan]="scan"></app-scan-details-component>
    </li>
  </div>
</ul>
