import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseTrackAndTraceComponent } from './summary/base-track-and-trace.component';
import { Store } from '@ngxs/store';
import { ParcelCollectionPoint } from '../../models/gls-info.model';

@Component({
  selector: 'app-secundary-column',
  templateUrl: './secundary-column.component.html',
  styleUrl: './secundary-column.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SecundaryColumnComponent extends BaseTrackAndTraceComponent {
  constructor(store: Store) {
    super(store);
  }

  get collectionPoint(): ParcelCollectionPoint | undefined | null {
    const parcelshop = this.details?.deliveryScanInfo.parcelShop;
    if (parcelshop) {
      return parcelshop;
    }
    return this.details?.deliveryScanInfo?.apl;
  }
}
