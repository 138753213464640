import { ParcelCollectionPoint } from './gls-info.model';

export enum TrackAndTraceStatusType {
  SendingData,
  ReceivedByGLS,
  OnTheWayToDepot,
  InDepot,
  DeliverOnTheWay,
  DeliveryRefused,
  Delivered,
  Return,
}

export interface Address {
  name: string;
  name2: string;
  name3: string;
  street: string;
  houseNo: string;
  houseNoAdd: string;
  zipcode: string;
  city: string;
  addrType: string;
  email: string;
  country: string;
}

export interface DeliveryScanInfo {
  isDelivered: boolean;
  country: string;
  countryCode: string;
  name: string;
  dateTime: string;
  eventID: string;
  signedBy: string;
  parcelShop: ParcelCollectionPoint | null;
  apl: ParcelCollectionPoint | null;
}

export interface DeliveryListInfo {
  sustainableDelivery: boolean;
  hasDeliveryListScan: boolean;
  date: string;
  listNo: string;
  name: string;
  depot: string;
  address: Address;
  isAPL: boolean;
  isParcelShop: boolean;
  depositLocation: string | undefined;
}

export enum ScanType {
  None = 0,
  Data = 1,
  Physical = 2,
  Network = 3,
  Depot = 4,
  Delivery = 5,
  DeliveryAttempt = 6,
  Neighbors = 7,
  NotAtHome = 8,
  Instruction = 9,
  Delivered = 10,
}

export interface Scan {
  eventId: string;
  dateTime: string;
  eventNo: number;
  reasonNo: number;
  eventReasonDescr: string;
  eventReasonDescrAltCust: string;
  eventReasonDescrAltCons: string;
  depotCode: string;
  depotName: string;
  countryCode: string;
  countryName: string;
  deliverName: string;
  info: string;
  infoExtra: string;
  scanType: ScanType;
  isSummaryTitle: boolean;
}

export interface ParcelInfo {
  parcelNo: string;
  initialDate: string;
  reference: string;
  zipCode: string;
  country: string;
  lastStatus: string;
  dateTime: string;
  deliveryName: string;
  uniqueNo: string;
  eventInfo: string;
  lastEventNo: number;
  lastReasonNo: number;
  jobDate: string;
  cancelled: string;
  returnFlag: string;
}

export interface AddressInfo {
  from: RouteAddress;
  to: RouteAddress;
  recipient: RouteAddress;
}

export interface ParcelDetails {
  isRetour: boolean;
  parcelNo: string;
  custNo: string;
  custName: string;
  shipmentNo: string;
  shipmentUniqueNo: string;
  initialDate?: string;
  jobDate: string;
  gpNo: string;
  uniqueNo: string;
  product: string;
  services: string;
  isExpress: boolean;
  reference: string;
  zipcode: string;
  city: string;
  weighedWeight: number | null;
  suppliedWeight: number | null;
  countryCode: string;
  countryName: string;
  isParcelShop: boolean;
  width: number;
  height: number;
  length: number;
  depot: string;
  depotName: string;
  cancelled: boolean;
  lastScanDepot: string;
  lastScanType: ScanType;
  addresses: Address[];
  customerAddresses: Address[];
  scans: Scan[];
  shipmentAddress: Address;
  parcels: ParcelInfo[];
  deliveryScanInfo: DeliveryScanInfo;
  deliveryListInfo: DeliveryListInfo;
  deliveryStatus: DeliveryStatus;
  addressInfo?: AddressInfo;
  state: ParcelState;
  subState: ParcelSubState;
  showEta: boolean;
  instruction?: string;
  timeRemainingToCollectParcel?: string;
  sapNo?: string;
}

export interface DeliveryStatus {
  isRealTimeTracking: boolean;
  remainingStops: number;
  driverPosition: Coordinates;
  destinationPosition: Coordinates;
  etaTimestamp: string | null;
  etaTimestampMin: string | null;
  etaTimestampMax: string | null;
  status: string | null;
}

export interface Coordinates {
  lattitude: number;
  longitude: number;
}

export enum BlockType {
  ParcelGeneral,
  ParcelIdentification,
  ParcelInformation,
}

export interface GetDetailsRequest {
  uniqueNo: string;
  jobDate: string;
  culture: string;
}

export interface TtParcelContext {
  audience: AudienceType;
  parcelNo: string;
  jobDate: string;
  uniqueNo: string;
  culture: string;
  custNo: string | null;
}

export interface TtChecksumContext {
  audience: AudienceType;
  parcelNo: string;
  checksum: string;
  reference: string;
  culture: string;
  customerNo: string;
  dateFrom: string;
  userName: string;
  userType: string;
}

export interface GetParcelDetailsByChecksumRequest {
  parcelNo: string;
  customerNo: string;
  reference: string;
  dateFrom: string;
  userType: string;
  checksum: string;
  userName: string;
  culture: string;
}

export enum TrackAndTraceInfoView {
  Full,
  MobileStatus,
  MobileDetails,
}

export interface ParcelDeliveryDate {
  deliveryDate: string;
  status: string;
  timeFrame: ParcelDeliveryTime;
}

export interface ParcelDeliveryTime {
  dayOfWeek: number;
  startTime: string;
  endTime: string;
  description: string;
}

export enum AudienceType {
  Consignor,
  Recipient,
}

export enum ScanSortType {
  dateTime = 'dateTime',
  countryCode = 'countryCode',
  countryName = 'countryName',
  depotCode = 'depotCode',
  depotName = 'depotName',
  eventReasonDescr = 'eventReasonDescr',
  reasonNo = 'reasonNo',
  eventNo = 'eventNo',
  eventReasonDescrAltCust = 'eventReasonDescrAltCust',
  eventReasonDescrAltCons = 'eventReasonDescrAltCons',
  deliverName = 'deliverName',
  info = 'info',
  infoExtra = 'infoExtra',
}

export enum ParcelSortType {
  None,
  ParcelNo,
  InitialDate,
  Reference,
  ZipCode,
  LastStatus,
  DateTime,
  DeliveryName,
  SignedBy,
}

export enum SortDirection {
  Ascending,
  Descending,
}

export interface TrackAndTraceStep {
  isRetour: boolean;
  status: ParcelState;
  iconUrl: string;
  label: string;
}

export enum ParcelState {
  Announced,
  Received,
  InRegion,
  OutForDelivery,
  Delivered,
  NotDelivered,
}

export enum ParcelSubState {
  None,
  Delivered, // parcel is delivered to the consignee
  DeliveredAtNeighbors, // parcel is delivered to the neighbors
  DeliveredAtParcelShop, // parcel is delivered to the parcel shop
  DeliveryFailedNotAtHome, // consignee was not at home
  DeliveryFailedRefused, // consignee refused the parcel
  ReturnedToSender, // parcel is returned to the sender
  DeliveredAtApl, // parcel is delivered to the APL
  Reserved, //
  CollectedFromApl, // consignee collected the parcel from the APL
  CollectedFromParcelShop, // consignee collected the parcel from the parcel shop
  NotCollectedFromApl, // consignee did not collect the parcel from the APL in time, so parcel is returned to GLS depot
  NotCollectedAtParcelShop, // consignee did not collect the parcel from the parcel shop in time, so parcel is returned to GLS depot
}

export interface RouteAddress {
  name: string;
  name2: string;
  name3: string;
  street: string;
  country: string;
  countryCode: string;
  houseNo: string;
  houseNoAdd: string;
  zipcode: string;
  city: string;
  email: string;
  routeAddressType: RouteAddressType;
}

export enum RouteAddressType {
  None = 0,
  Sender = 1,
  Recipient = 2,
  ParcelShop = 3,
  ReturnAddress = 4,
}
