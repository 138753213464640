<ul class="block" *ngIf="parcels">
  <div class="header" (click)="toggleExpansion()">
    <div
      class="spotlight-blue spotlight-subtitle center-vertical"
      translate
      [translateParams]="{
        count: parcels.length
      }"
    >
      TrackAndTrace.Details.Parcels
    </div>
    <button class="btn-vertical-expander" aria-label="Expand">
      <mat-icon [class]="isExpanded ? 'expanded' : 'collapsed'">keyboard_arrow_down</mat-icon>
    </button>
  </div>
  <div class="expander-content" [@expandCollapse]="isExpanded ? 'expanded' : 'collapsed'">
    <li *ngFor="let parcel of parcels">
      <div class="parcel">
        <div class="spotlight-gray">
          <a [href]="getUrl(parcel)">{{ parcel.parcelNo }}</a>
        </div>
        <div class="spotlight-gray">{{ jobDate(parcel) }}</div>
        <div class="spotlight-black spotlight">
          {{ parcel.lastStatus }}
        </div>
      </div>
    </li>
  </div>
</ul>
