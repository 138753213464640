import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { CommonUIConfig } from '../environments/commonui/commonui.config';
import { Sites, TargetAudience, WebNotification, WebNotificationRequest } from '../models/common.model';

@Injectable({
  providedIn: 'root',
})
export class CommonUiClientService {
  private get apiWebNotificationUrl() {
    return `${CommonUIConfig.ApiUrl}/${CommonUIConfig.WebNotificationController}`;
  }

  constructor(private http: HttpClient) {}

  public async getWebNotifications(audience: TargetAudience): Promise<Array<WebNotification> | undefined> {
    const siteId = this.getSiteIdByAudience(audience);
    const request = {
      siteId,
    } as unknown as WebNotificationRequest;

    const url = `${this.apiWebNotificationUrl}`;
    for (let retry = 0; retry < 2; retry++) {
      const startTime = new Date().getTime();
      const apiErrorCode = 'Api.Errors.getWebNotifications';
      try {
        const response = await lastValueFrom(this.http.post<WebNotification[]>(url, request, { observe: 'response' }));
        if (response.status === 200) {
          return response.body as WebNotification[];
        }
        if (response.status === 204) {
          return [];
        }
        if (response.status === 502) {
          return [];
        }
      } catch (ex) {
        const timePassedInMilliSecs = new Date().getTime() - startTime;
        console.log(apiErrorCode, { startTime, timePassedInMilliSecs });
      }
    }
    return [];
  }
  
  getSiteIdByAudience = (audience: TargetAudience): Sites => {
    switch (audience) {
      case TargetAudience.Driver:
        return Sites.Driver;
      case TargetAudience.MyGLS:
        return Sites.MyGls;
      case TargetAudience.GLS:
        return Sites.GlsInfo;
      case TargetAudience.Quotation:
        return Sites.Quotation;
      default:
        return Sites.All;
    }
  }
}
