import { TargetAudience } from '../models/common.model';
import {
  ParcelCollectionPoint,
  TimeFrame,
  CreateInstructionRequestModel,
  InstructionResult,
} from '../models/gls-info.model';
import { InstructionValidationRequest } from '../models/instruction-validation-request';
import { InstructionValidationResponse } from '../models/instruction-validation-response';
import { ParcelDetails } from '../models/track-and-trace.model';
import { WidgetType } from './app.model';

export class GetParcelDetailsAction {
  static readonly type = '[GlsSpotlight] Get parcel details';
  constructor(
    public widgetType: WidgetType,
    public parcelNumber: string,
    public zipCode: string,
    public culture: string,
    public captcha: string
  ) {}
}

export class SetParcelDetailsAction {
  static readonly type = '[GlsSpotlight] Set parcel details';
  constructor(
    public widgetType: WidgetType,
    public parcelNumber: string,
    public zipCode: string,
    public isSuccess: boolean,
    public parcelDetails?: ParcelDetails
  ) {}
}

export class RefreshParcelDetailsAction {
  static readonly type = '[GlsSpotlight] Refresh parcel details';
  constructor(public culture: string, public captcha: string) {}
}

export class ResetParcelIdentifierAction {
  static readonly type = '[GlsSpotlight] Reset parcel identifier';
}

export class SetActiveWidgetAction {
  static readonly type = '[GlsSpotlight] Set active widget';
  constructor(public widgetType: WidgetType) {}
}

export class GetEmergencyNotificationsAction {
  static readonly type = '[GlsSpotlight] Get Emergency Notifications';
  constructor(public audience: TargetAudience) {}
}

export class HideEmergencyNotificationAction {
  static readonly type = '[GlsSpotlight] Hide Emergency Notifications';
}

export class ResetSearchParcelErrorAction {
  static readonly type = '[GlsSpotlight] Reset search parcel error';
}

export class ToggleWidgetExpansionAction {
  static readonly type = '[GlsSpotlight] Toggle widget expansion';
}

export class ExpandWidgetExpansionAction {
  static readonly type = '[GlsSpotlight] Expand widget expansion';
}

export class SetConsentAction {
  static readonly type = '[GlsSpotlight] On Consent Change';
  constructor(public tcf: string) {}
}

export class SetMapVisibilityAction {
  static readonly type = '[GlsSpotlight] Set Map Visibility';
  constructor(public isVisibleMap: boolean) {}
}

export class SetInstructionValidationRequestAction {
  static readonly type = '[GlsSpotlight] Set Instruction Validation Request';
  constructor(public instructionValidationRequest?: InstructionValidationRequest) {}
}

export class SetInstructionValidationResultAction {
  static readonly type = '[GlsSpotlight] Set Instruction Validation Result';
  constructor(public instructionValidationResult?: InstructionValidationResponse) {}
}

export class SetSelectedCollectionPointAction {
  static readonly type = '[GlsSpotlight] Set Selected CollectionPoint';
  constructor(public collectionPoint?: ParcelCollectionPoint) {}
}

export class SetInstructionAction {
  static readonly type = '[GlsSpotlight] Set Instruction ';
  constructor(public instruction: CreateInstructionRequestModel) {}
}

export class SetInstructionFlowAction {
  static readonly type = '[GlsSpotlight] Set In InstructionFlow ';
  constructor(public inInstructionFlow: boolean) {}
}

export class SetInstructionResultAction {
  static readonly type = '[GlsSpotlight] Set In Instruction result';
  constructor(public instructionResult: InstructionResult) {}
}