<ng-container *ngIf="primary">
  <div class="card">
    <div class="column-left" [class.fullwidth]="!showPhoto">
      <ul class="collection-point" *ngIf="collectionPoint">
        <li *ngIf="showtitle">
          <div class="spotlight-black title" translate>Instruction.DeliverToCollectionPoint</div>
        </li>

        <li *ngIf="showtime">
          <div class="line">
            <span>
              <mat-icon class="shop-icon">storefront</mat-icon>
              <span
                class="time spotlight-blue"
                *ngIf="
                  collectionPoint.location.distance.kilometers > 0 && collectionPoint.location.distance.minutes > 0
                "
                >{{ collectionPoint.location.distance.kilometers | number : '1.1-1' }} km /
                {{ collectionPoint.location.distance.minutes }} {{ 'Instruction.Minutes' | translate }}</span
              >
            </span>
            <span
              class="time spotlight-blue"
              *ngIf="
                collectionPoint.location.distance.kilometers === 0 || collectionPoint.location.distance.minutes === 0
              "
              translate
              >CollectionPoint.WithinOneKilometer
            </span>
          </div>
        </li>

        <li>
          <div class="spotlight-black name">{{ name }}</div>
        </li>

        <li>
          <div class="line">
            <mat-icon>place</mat-icon>
            <span class="spotlight-black">{{ street }}</span>
          </div>

          <div class="line">
            <div class="spotlight-gray city">{{ city }}</div>
          </div>
        </li>

        <li>
          <div class="line clickable" [matMenuTriggerFor]="listOpeningHours">
            <mat-icon>update</mat-icon>
            <div class="spotlight-black is-closed" [class.is-open]="isOpen">{{ openingsText }}</div>
            <div class="spotlight-gray info">{{ info }}</div>
            <mat-icon>keyboard_arrow_down</mat-icon>
          </div>

          <mat-menu #listOpeningHours="matMenu" yPosition="below">
            <div *ngFor="let item of openingHours">
              <button>
                <div class="business-hours">
                  <div class="dayofweek" [class.bold]="isCurrentOpening(item)">
                    {{ openingDay(item) }}
                  </div>
                  <div class="hours" [class.bold]="isCurrentOpening(item)">
                    {{ openingTime(item) }}
                  </div>
                </div>
              </button>
            </div>
          </mat-menu>
        </li>

        <li *ngIf="remainingDaysAvailable">
          <div class="line">
            <mat-icon>calendar_today</mat-icon>
            <span class="spotlight-gray" translate>CollectionPoint.Available</span>
            <span
              class="spotlight-black days"
              [translateParams]="{
                days: remainingDaysAvailable
              }"
              [translate]="remainingDaysTextId"
            ></span>
          </div>
        </li>

        <li *ngIf="website">
          <div class="line">
            <mat-icon>language</mat-icon>
            <span
              ><a [href]="websiteUrl" target="_blank">{{ website }}</a></span
            >
          </div>
        </li>
      </ul>
      <div *ngIf="allowSelectCollectionPoint">
        <button
          mat-button
          class="btn btn-secondary fullwidth select-collection-point"
          (click)="onSelectedCollectionPoint()"
        >
          {{ selectCollectionPointTitle | translate }}
        </button>
      </div>
    </div>

    <div class="column-right" *ngIf="showPhoto && imageUrl()">
      <div class="photo" [style.background-image]="'url(' + imageUrl() + ')'"></div>
      <div class="spotlight-gray" *ngIf="isApl" translate>Parcel.PickupOnly</div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!primary">
  <ul class="collection-point" *ngIf="collectionPoint">
    <li>
      <div class="spotlight-black name">{{ name }}</div>
    </li>

    <li *ngIf="isMapVisible"><div id="map"></div></li>

    <li *ngIf="imageUrl()">
      <div class="photo" [style.background-image]="'url(' + imageUrl() + ')'"></div>
      <div class="spotlight-gray" *ngIf="isApl" translate>Parcel.PickupOnly</div>
    </li>
  </ul>
</ng-container>
