<div class="progress-container">
  <div class="step">
    <div class="title" [class.active]="isAnnounced" translate>TrackAndTrace.Progress.Announced</div>
    <div class="progress" [class.active]="isAnnounced"></div>
  </div>

  <div class="step">
    <div class="title" [class.active]="isShipped" translate>TrackAndTrace.Progress.Shipped</div>
    <div class="progress" [class.active]="isShipped"></div>
  </div>

  <div class="step">
    <div class="title" [class.active]="isOutForDelivery" translate>TrackAndTrace.Progress.Delivering</div>
    <div class="progress" [class.active]="isOutForDelivery"></div>
  </div>

  <div class="step active">
    <div class="title" [class.active]="isDelivered">{{ deliveredTitle | translate }}</div>
    <div class="progress" [class.active]="isDelivered || isNotDelivered || isRetour"></div>
  </div>
</div>
