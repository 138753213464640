import { ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, OnInit, signal, Signal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { BreakpointObserver } from '@angular/cdk/layout';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CollectionPointClientService } from '../../clients/collectionpoint.client';
import { Constants } from '../../models/constants';
import { ParcelCollectionPoint, LocatorFilter, ParcelShopFilter } from '../../models/gls-info.model';
import { SetActiveWidgetAction } from '../../state/app.actions';
import { WidgetType } from '../../state/app.model';
import { BaseComponentComponent } from '../../base/base-component.component';
import { zip } from 'lodash';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-shop-locator',
  templateUrl: './shop-locator.component.html',
  styleUrls: ['./shop-locator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShopLocatorComponent extends BaseComponentComponent implements OnInit {
  CodeRegex = '[a-zA-Z0-9]+'; // do not allow white spaces or other characters
  form: FormGroup;
  collectionPoints: ParcelCollectionPoint[] = [];
  breakpoint = toSignal(this.breakpointObserver.observe([Constants.BreakPointMobile]));
  showLoadingWidget = signal(false);

  isMobile: Signal<boolean> = computed(() => this.breakpoint()?.matches ?? false);

  isInvalidControl = (name: string): boolean => {
    const control = this.form.controls[name];
    return control.invalid && (control.dirty || control.touched);
  };

  isControlError = (name: string, error: string): boolean => {
    const control = this.form.controls[name];
    return control.errors && control.errors[error];
  };

  get placeholderZipCode(): string {
    return this.translateService.instant('Instruction.ZipCode');
  }

  constructor(
    public store: Store,
    public readonly fb: FormBuilder,
    private readonly translateService: TranslateService,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly collectionPointService: CollectionPointClientService,
    private readonly route: ActivatedRoute,
    private readonly cd: ChangeDetectorRef
  ) {
    super();
    this.form = this.fb.group({
      zipCode: [
        '',
        [Validators.minLength(4), Validators.maxLength(10), Validators.required, Validators.pattern(this.CodeRegex)],
      ],
      showShops: [true],
      showApls: [true],
    });
  }

  async onSubmit(event: Event) {
    event.preventDefault();
    if (!this.form.valid) return;
    await this.loadCollectionPoints();
  }

  goBack() {
    this.store.dispatch(new SetActiveWidgetAction(WidgetType.SearchParcel));
  }

  async ngOnInit() {
    this.register(
      this.form.controls['showShops'].valueChanges.subscribe(async () => {
        await this.loadCollectionPoints();
      })
    );

    this.register(
      this.form.controls['showApls'].valueChanges.subscribe(async () => {
        await this.loadCollectionPoints();
      })
    );
    const zipCode = this.route.snapshot.queryParamMap.get('zipcode');
    if (zipCode) {
      this.form.controls['zipCode'].setValue(zipCode);
      if (!this.form.valid) return;
      await this.loadCollectionPoints();
    }
  }

  async loadCollectionPoints() {
    const zipCode = this.form.controls['zipCode'].value;
    const showShops = this.form.controls['showShops'].value;
    const showApls = this.form.controls['showApls'].value;
    if (!zipCode) return;
    this.collectionPoints = [];

    this.showLoadingWidget.set(true);
    if (showShops) {
      const shops = await this.collectionPointService.searchParcelShops(
        zipCode,
        '1',
        10,
        ParcelShopFilter.ParcelShop | ParcelShopFilter.Depot,
        LocatorFilter.ParcelShopLocator
      );

      if (shops) {
        shops.forEach((shop) => {
          this.collectionPoints.push({
            ...shop,
            isApl: false,
          });
        });
      }
    }

    if (showApls) {
      const apls = await this.collectionPointService.searchApls(
        zipCode,
        '1',
        10,
        ParcelShopFilter.ParcelShop,
        LocatorFilter.InstructionSite
      );

      if (apls) {
        apls.forEach((apl) => {
          this.collectionPoints.push({
            ...apl,
            isApl: true,
          });
        });
      }
    }

    const depot = this.collectionPoints.find((point) => point.isDepot);
    this.collectionPoints.sort((a, b) => a.location.distance.minutes - b.location.distance.minutes);
    this.collectionPoints = this.collectionPoints.slice(0, 10);
    if (depot && !this.collectionPoints.find((point) => point.isDepot)) {
      this.collectionPoints.push(depot);
    }
    this.showLoadingWidget.set(false);
    this.cd.detectChanges();
  }
}
