<div class="spotlight-wrapper default">
  <div class="spotlight-container">
    <img
      alt="spotlight-path"
      class="path"
      loading="lazy"
      width="448"
      height="428"
      decoding="async"
      data-nimg="1"
      style="color: transparent"
      src="/assets/images/path.svg"
    />
    <img
      alt="van"
      class="spotlight-van"
      loading="lazy"
      width="28"
      height="34"
      decoding="async"
      data-nimg="1"
      style="color: transparent"
      src="/assets/images/gls-van.svg"
    />
  </div>
</div>

<div class="spotlight-wrapper" #container>
</div>
