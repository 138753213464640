import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouteAddress } from '../../../../models/track-and-trace.model';
import {
  name,
  city,
  country,
  street,
} from '../../../../services/track-and-trace.utils';

@Component({
  selector: 'app-address-component',
  templateUrl: './address-component.component.html',
  styleUrl: './address-component.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressComponentComponent {
  @Input() label = '';
  @Input() address: RouteAddress | undefined;

  get name(): string | undefined {
    return name(this.address);
  }

  get street(): string | undefined {
    return street(this.address);
  }

  get city(): string | undefined {
    return city(this.address);
  }

  get country(): string | undefined {
    return country(this.address);
  }
}
