import { ChangeDetectionStrategy, Component, computed, OnInit, signal, Signal } from '@angular/core';
import { BaseTrackAndTraceComponent } from '../../track-and-trace/summary/base-track-and-trace.component';
import { ActionTypes, LocatorFilter, ParcelCollectionPoint, ParcelShopFilter } from '../../../models/gls-info.model';
import { Store } from '@ngxs/store';
import { toSignal } from '@angular/core/rxjs-interop';
import { AppState } from '../../../state/app.state';
import { CollectionPointClientService } from '../../../clients/collectionpoint.client';
import {
  SetActiveWidgetAction,
  SetInstructionAction,
  SetSelectedCollectionPointAction,
} from '../../../state/app.actions';
import { WidgetType } from '../../../state/app.model';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Constants } from '../../../models/constants';

@Component({
  selector: 'app-instruction-collectionpoint',
  templateUrl: './instruction-collectionpoint.component.html',
  styleUrls: ['./instruction-collectionpoint.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InstructionCollectionpointComponent extends BaseTrackAndTraceComponent implements OnInit {
  selectedCollectionPoint: Signal<ParcelCollectionPoint | undefined>;
  showLoadingWidget = signal(false);
  breakpoint = toSignal(this.breakpointObserver.observe([Constants.BreakPointMobile]));

  isMobile: Signal<boolean> = computed(() => this.breakpoint()?.matches ?? false);

  get hasMoreDeliveryOptions(): boolean {
    const allowedActions = this.instructionValidateResult()?.allowedActions ?? 0;
    return (
      (allowedActions & ActionTypes.DeliverToAlternateAddress) === ActionTypes.DeliverToAlternateAddress ||
      (allowedActions & ActionTypes.RefusePackage) === ActionTypes.RefusePackage ||
      (allowedActions & ActionTypes.RetryDelivery) === ActionTypes.RetryDelivery
    );
  }

  constructor(
    store: Store,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly collectionPointService: CollectionPointClientService
  ) {
    super(store);
    this.selectedCollectionPoint = toSignal(this.store.select(AppState.selectedCollectionPoint));
  }

  async ngOnInit() {
    if (!this.selectedCollectionPoint()) {
      this.showLoadingWidget.set(true);
      await this.getNearestCollectionPoint();
      this.showLoadingWidget.set(false);
    }
  }

  async getNearestCollectionPoint() {
    const validateResult = this.instructionValidateResult();
    if (!validateResult) return;
    const filter =
      (validateResult.allowedActions & ActionTypes.CollectAtParcelShop) === ActionTypes.CollectAtParcelShop
        ? ParcelShopFilter.ParcelShop
        : ParcelShopFilter.Depot;
    const originalZipCode = validateResult.leadingAddress?.zipCode;
    const targetZipCode = validateResult.leadingAddress?.zipCode;
    if (originalZipCode && targetZipCode) {
      const shops = await this.collectionPointService.searchOpenParcelShopsAndGlsDepots(
        targetZipCode,
        originalZipCode,
        '1',
        1,
        filter,
        LocatorFilter.InstructionSite
      );

      if (shops) {
        const collectionPoint = {
          ...shops[0],
          isApl: false,
        };
        this.store.dispatch(new SetSelectedCollectionPointAction(collectionPoint));
      }
    }
  }

  selectDifferentCollectionPoint() {
    this.store.dispatch(new SetActiveWidgetAction(WidgetType.SelectOtherCollectionPoint));
  }

  goBack() {
    this.store.dispatch(new SetActiveWidgetAction(this.rootWidget));
  }

  confirm() {
    let collectionPoint = this.selectedCollectionPoint();
    if (!collectionPoint) return;

    let instruction = this.createInstructionModel();
    if (!instruction) return;
    const actionType = collectionPoint.isDepot ? ActionTypes.CollectAtDepot : ActionTypes.CollectAtParcelShop;
    instruction.actionType = collectionPoint.isApl ? ActionTypes.CollectAtApl : actionType;
    instruction.aplId = collectionPoint.isApl ? collectionPoint.id : '';
    instruction.psChosenCustNo = collectionPoint.custOpsNo ?? '';

    this.store.dispatch([
      new SetInstructionAction(instruction),
      new SetActiveWidgetAction(WidgetType.InstructionConfirm),
    ]);
  }
}
