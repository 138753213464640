export class AddressFormSettings {
    public static Name = {
      MaxLength: 30,
    };
  
    public static ContactPerson = {
      MaxLength: 30,
    };
  
    public static PhoneNumber = {
      Regex: '^[0-9-\\s()+]*$',
      MaxLength: 25,
    };
  
    public static MobilePhoneNumber = {
      Regex: '^[0-9][6][0-9]{8}$|^[0-9][6][\\s,-][0-9]{8}$',
      MaxLength: 10,
    };
  
    public static Email = {
      Regex: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,10}$',
      MaxLength: 320,
    };
  
    public static Zipcode = {
      Regex: '^[1-9]{1}[0-9]{3}[\\s]{0,1}[a-zA-Z]{2}$', //Nederlandse zipcode voor het initialiseren van de forms
      MaxLength: 8,
      PlaceHolder: '0000AA',
    };
  
    public static HouseNumber = {
      MaxLength: 5,
    };
  
    public static HouseNumberAddition = {
      MaxLength: 5,
    };
  
    public static Street = {
      MaxLength: 40,
    };
  
    public static City = {
      MaxLength: 30,
    };
  
    public static Country = {
      MaxLength: 2,
    };
  
    public static AddressType = {
      MaxLength: 32,
    };
  }
  