<div class="primary-column" [class.mobile]="isMobile()">
  <div class="primary-content">
    <div *ngIf="succeeded">
      <div class="label spotlight-blue" translate>Instruction.Succeeded</div>
      <div class="label spotlight-gray" translate>Instruction.SucceededSubscription</div>
      <div class="party">
        <img src="/assets/images/confetti.gif" />
      </div>
    </div>
    <ng-container *ngIf="!succeeded">
      <div class="label spotlight-black" translate>Instruction.Failed</div>
      <div class="label spotlight-gray footnote">{{ errorDescription | translate }}</div>
    </ng-container>
  </div>

  <div class="primary-footer">
    <button mat-button class="btn btn-primary fullwidth" (click)="confirm()">
      {{ 'Instruction.BackToParcelDetails' | translate }}
    </button>
  </div>
</div>
