<ng-container *ngIf="!isDeliveredToCollectionPoint">
  <div
    *ngIf="showRemainingStops && remainingStops > 1"
    class="stops-title spotlight-black"
    translate
    [translateParams]="{
      remainingStops: remainingStops
    }"
  >
    Eta.RealTimeTracking.Info
  </div>

  <div
    *ngIf="showRemainingStops && remainingStops <= 1"
    class="stops-title spotlight-black"
    translate
    [translateParams]="{
      remainingStops: remainingStops
    }"
  >
    Eta.RealTimeTracking.AlmostArrived
  </div>
</ng-container>

<ng-container *ngIf="isDeliveredToCollectionPoint">
  <div
    *ngIf="showRemainingStops && remainingStops > 1"
    class="stops-title spotlight-black"
    translate
    [translateParams]="{
      remainingStops: remainingStops
    }"
  >
    Eta.RealTimeTracking.InfoCollectionPoint
  </div>

  <div
    *ngIf="showRemainingStops && remainingStops <=1"
    class="stops-title spotlight-black"
    translate
    [translateParams]="{
      remainingStops: remainingStops
    }"
  >
    Eta.RealTimeTracking.CollectionPointAlmostArrived
  </div>
</ng-container>
