<div class="card">
  <div class="primary-column" [class.mobile]="isMobile()">
    <div class="primary-content">
      <div class="header">
        <button class="btn-horizontal-expander size-40 size-40-expander" aria-label="Expand" (click)="goBack()">
          <mat-icon class="expanded">navigate_next</mat-icon>
        </button>
        <span class="spotlight-blue title" translate>Instruction.DepositPermission.WhichLocation</span>
      </div>

      <form [formGroup]="form">
        <!-- depositLocation -->
        <div
          class="search-container form-group form-group_with_icon form-group_with_feedback"
          [class.is-invalid]="isInvalidControl('depositLocation')"
        >
          <div class="invalid-value-missing">
            <input
              id="depositLocation"
              data-cy="tbDepositLocation"
              formControlName="depositLocation"
              type="text"
              class="depositLocation form-control"
              autocomplete="depositLocation"
              [placeholder]="placeholderDepositLocation"
              appTrimInput
              #depositLocation
              minlength="5"
              maxlength="30"
            />
          </div>

          <ng-container *ngIf="isInvalidControl('depositLocation')">
            <div
              *ngIf="isControlError('depositLocation', 'required')"
              class="feedback"
              [translateParams]="{
                fieldName: ('Instruction.DepositPermission.Location' | translate)
              }"
              translate
            >
              Validations.Required.Field
            </div>

            <div
              *ngIf="isControlError('depositLocation', 'minlength')"
              class="feedback"
              [translateParams]="{
                fieldName: ('Instruction.DepositPermission.Location' | translate),
                length: 5
              }"
              translate
            >
              Validations.Required.MinLength
            </div>

            <div
              *ngIf="isControlError('depositLocation', 'maxlength')"
              class="feedback"
              [translateParams]="{
                fieldName: ('Instruction.DepositPermission.Location' | translate),
                length: 30
              }"
              translate
            >
              Validations.Required.MaxLength
            </div>
          </ng-container>
        </div>

        <!--GDPR-->
        <mat-checkbox class="avg" formControlName="avg">
          <div class="spotlight-gray spotlight-footnote avg-note" [class.error] ="showAvgError" translate>Instruction.DepositPermission.GDPR</div>
        </mat-checkbox>
      </form>
    </div>

    <div class="primary-footer">
      <button mat-button class="btn btn-primary fullwidth" (click)="confirm()">
        {{ 'Instruction.ContinueWithNextStep' | translate }}
      </button>
    </div>
  </div>
</div>
