import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  queryParameter,
  root,
  transformParamsWithLoweredKeys,
} from './routing.utils';
import { BaseComponentComponent } from '../../base/base-component.component';

@Component({
  selector: 'app-route-track-and-trace',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RouteTrackAndTraceComponent
  extends BaseComponentComponent
  implements OnInit
{
  constructor(private readonly router: Router, private route: ActivatedRoute) {
    super();
  }

  ngOnInit(): void {
    this.register(
      this.route.queryParams.subscribe((params) => {
        const paramsWithLoweredKeys = transformParamsWithLoweredKeys(params);
        const parcelNumber = queryParameter(paramsWithLoweredKeys, 'parcelno');
        const zipCode = queryParameter(paramsWithLoweredKeys, 'zipcode');
        this.router.navigateByUrl(
          `${root}?trackid=${parcelNumber}&zipcode=${zipCode}`
        );
      })
    );
  }
}
