export enum TargetAudience {
  MyGLS = 1,
  GLS = 2,
  Driver = 3,
  Quotation = 4,
}

export interface WebNotificationRequest {
  siteId: Sites;
}

export interface WebNotification {
  siteId: string;
  sequenceNumber: number;
  culture: string;
  title: string;
  text: string;
  webNotificationType: WebNotificationType;
}

// See TBSYSVALUE for SYSVALUETYPE = WEBNOTIFICATIONTYPE
export enum WebNotificationType {
  Danger = 'DANGER',
  Information = 'INFORMATION',
  Warning = 'WARNING',
}

// See GLSPortals.SharedUtils.Backend.Application.Constants.EnumConstants.cs
export enum Sites {
  All = 0,
  MyGls = 1,
  GlsInfo = 2,
  Driver = 3,
  Quotation = 4,
}

export interface FeedbackRequest {
  source: string;
  userName: string;
  feedback: string;
  session: string;
  happiness: FeedbackHappiness;
}

export interface PilotResultModel {
  isSuccess: boolean;
  message: string;
}

export enum FeedbackHappiness {
  None = 0,
  Hate = 1,
  Dislike = 2,
  Neutral = 3,
  Like = 4,
  Love = 5,
}

export interface LogRequest {
  frontend: string;
  application: string;
  session: string;
  message: string;
  breadcrumbs: string[];
  stack?: string;
  name?: string;
  url?: string | null;
  statusText?: string;
  status?: number;
  userName?: string;
  sha?: string;
  touchScreen?: boolean;
  payload?: string;
  logLevel?: LogLevel;
}

export enum LogLevel {
  Error,
  Warn,
  Info,
}

export interface RequestError {
  url: string;
  status: number;
  message: string;
}
