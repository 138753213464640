import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  ParcelProperty,
  TrackAndTraceProperty,
} from './track-and-trace-details.model';;
import { getInternationalLink } from '../../../../services/track-and-trace.utils';

@Component({
  selector: 'app-track-and-trace-property',
  templateUrl: './track-and-trace-property.component.html',
  styleUrl: './track-and-trace-property.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrackAndTracePropertyComponent {
  @Input() property!: ParcelProperty;

  get label(): string {
    return `Parcel.${this.property.label}`;
  }

  get isGpno(): boolean {
    return this.property.label === TrackAndTraceProperty.GpNo;
  }

  getInternationalLink = (gpNo: string | undefined): string =>
    getInternationalLink(gpNo);
}
