import { ChangeDetectionStrategy, Component, computed, Signal } from '@angular/core';
import { Store } from '@ngxs/store';
import { BaseTrackAndTraceComponent } from '../../track-and-trace/summary/base-track-and-trace.component';
import { BreakpointObserver } from '@angular/cdk/layout';
import { toSignal } from '@angular/core/rxjs-interop';
import { Constants } from '../../../models/constants';
import { SetActiveWidgetAction, SetInstructionAction } from '../../../state/app.actions';
import { WidgetType } from '../../../state/app.model';
import { ActionTypes } from '../../../models/gls-info.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

interface DateAndTimeFrame {
  date: string;
  startTime: string;
  endTime: string;
}

@Component({
  selector: 'app-instruction-deliver-at-neigbours',
  templateUrl: './instruction-deliver-at-neigbours.component.html',
  styleUrls: ['./instruction-deliver-at-neigbours.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InstructionDeliveryAtNeighbours extends BaseTrackAndTraceComponent {
  form: FormGroup;
  breakpoint = toSignal(this.breakpointObserver.observe([Constants.BreakPointMobile]));

  isMobile: Signal<boolean> = computed(() => this.breakpoint()?.matches ?? false);

  isInvalidControl = (name: string): boolean => {
    const control = this.form.controls[name];
    return control.invalid && (control.dirty || control.touched);
  };

  isControlError = (name: string, error: string): boolean => {
    const control = this.form.controls[name];
    return control.errors && control.errors[error];
  };

  constructor(store: Store, private readonly breakpointObserver: BreakpointObserver, public readonly fb: FormBuilder) {
    super(store);

    this.form = this.fb.group({
      houseNo: ['', [Validators.required, Validators.maxLength(10)]],
    });
  }

  goBack() {
    this.store.dispatch(new SetActiveWidgetAction(this.rootWidget));
  }

  confirm() {
    if (!this.form.valid) return;
    const instruction = {
      ...this.createInstructionModel()!,
      actionType: ActionTypes.DeliverAtNeighbours,
      allowNeighbours: true,
      neighboursHouseNo: this.form.get('houseNo')?.value ?? '',
    };
    this.store.dispatch([
      new SetInstructionAction(instruction),
      new SetActiveWidgetAction(WidgetType.InstructionConfirm),
    ]);
  }
}
