import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-webnotification-details',
  templateUrl: './webnotification-details.component.html',
  styleUrl: './webnotification-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebnotificationDetailsComponent {
  html: string;
  title: string;

  constructor(
    public dialogRef: MatDialogRef<WebnotificationDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string; html: string }
  ) {
    this.html = data.html;
    this.title = data.title;
  }
}
