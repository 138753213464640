<div class="header">
  <img class="size40 skeleton-loader" />
  <h1 class="title1 skeleton-loader"></h1>
  <h2 class="title2 skeleton-loader"></h2>
</div>
<div>
  <p class="label skeleton-loader"></p>
  <ul>
    <li class="skeleton-loader"></li>
  </ul>
</div>
<div>
  <p class="label skeleton-loader"></p>
  <ul>
    <li class="skeleton-loader"></li>
    <li class="skeleton-loader"></li>
  </ul>
</div>
<div class="footer">
  <button mat-button class="skeleton-loader"></button>
</div>
