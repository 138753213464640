import { ChangeDetectionStrategy, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import { AppState } from '../../state/app.state';

@Component({
  selector: 'app-bottom-sheet ',
  templateUrl: './bottom-sheet.component.html',
  styleUrl: './bottom-sheet.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BottomSheetComponent {
  @ViewChild('content') content: ElementRef | undefined;
  instructionPanelHeight = 500; // min. hoogte in pixels wanneer in instruction flow
  panelHeight = 30; // Start hoogte in vh
  minHeight = 10; // Minimale hoogte in vh
  maxHeight = 70; // Maximale hoogte in vh
  startY = 0;
  startHeight = 0;

  constructor(private renderer: Renderer2, private el: ElementRef, private store: Store) {
    this.store.select(AppState.inInstructionFlow).subscribe((isInstructionFlowActive) => {
      if (isInstructionFlowActive) {
        this.renderer.setStyle(this.el?.nativeElement, 'height', `${this.instructionPanelHeight}px`);
        this.renderer.setStyle(this.content?.nativeElement, 'height', `${this.instructionPanelHeight - 40}px`);
      }
    });
  }

  onTouchStart(event: TouchEvent) {
    this.startY = event.touches[0].clientY;
    this.startHeight = this.el?.nativeElement.clientHeight;

    // Schakel transition uit tijdens swipe
    this.renderer.setStyle(this.el?.nativeElement, 'transition', 'none');
  }

  onTouchMove(event: TouchEvent) {
    const deltaY = this.startY - event.touches[0].clientY;
    let newHeight = this.startHeight + deltaY;

    // Limiteer de hoogte tussen minHeight en maxHeight in pixels
    const minHeightPx = (this.minHeight / 100) * window.innerHeight;
    const maxHeightPx = (this.maxHeight / 100) * window.innerHeight;

    if (newHeight < minHeightPx) newHeight = minHeightPx;
    if (newHeight > maxHeightPx) newHeight = maxHeightPx;

    // Pas de hoogte van het paneel aan
    this.renderer.setStyle(this.el?.nativeElement, 'height', `${newHeight}px`);
    this.renderer.setStyle(this.content?.nativeElement, 'height', `${newHeight - 40}px`);
  }

  onTouchEnd(event: TouchEvent) {
    const currentHeight = this.el?.nativeElement.clientHeight;
    const minHeightPx = (this.minHeight / 100) * window.innerHeight;
    const maxHeightPx = (this.maxHeight / 100) * window.innerHeight;

    // Snapping drempel (30% van de hoogte van het paneel)
    const snapThreshold = 0.3 * (maxHeightPx - minHeightPx);

    if (currentHeight < minHeightPx + snapThreshold) {
      this.renderer.setStyle(this.el?.nativeElement, 'height', `${minHeightPx}px`);
      this.renderer.setStyle(this.content?.nativeElement, 'height', `${minHeightPx - 40}px`);
    } else if (currentHeight > maxHeightPx - snapThreshold) {
      this.renderer.setStyle(this.el?.nativeElement, 'height', `${maxHeightPx}px`);
      this.renderer.setStyle(this.content?.nativeElement, 'height', `${maxHeightPx - 40}px`);
    } else {
      // Blijf op de huidige hoogte als we niet dichtbij min of max zijn
      this.renderer.setStyle(this.el?.nativeElement, 'height', `${currentHeight}px`);
      this.renderer.setStyle(this.content?.nativeElement, 'height', `${currentHeight - 40}px`);
    }

    // Schakel transition weer in voor de snap animatie
    this.renderer.setStyle(this.el?.nativeElement, 'transition', 'height 0.3s ease-in-out');
  }
}
