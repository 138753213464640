import { ICommonUIConfig } from '../../app/environments/commonui/commonui.config.model';

export const CommonUIConfig: ICommonUIConfig = {
  MyGlsUrl: 'https://gls-portals-account.test.gls.nl',
  ApiUrl: 'https://ocelotgateway.test.gls.nl',
  WebNotificationController: 'api/v1/WebNotification',
  LoggingController: 'api/v1/logging',
  CustomerTrackTraceUrl: 'https://gls-portals-customertracktrace.test.gls.nl',
  PickReturnUrl: 'https://gls-portals-pickreturn.test.gls.nl',
  PrintShipUrl: 'https://gls-portals-labelliteonline.test.gls.nl',
  LandingPageUrl: 'https://gls-portals-landingspage.test.gls.nl',
  PilotController: 'api/pilot/v1',
};
