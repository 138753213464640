import { ITtUIConfig } from '../../app/environments/ttui/ttui.config.model';

export const TtUiConfig: ITtUIConfig = {
  MyGlsUrl: 'https://gls-portals-account.test.gls.nl',
  ApiUrl: 'https://ocelotgateway.test.gls.nl',
  WebNotificationController: 'api/v1/WebNotification',
  CustomerTrackTraceUrl: 'https://gls-portals-customertracktrace.test.gls.nl',
  PickReturnUrl: 'https://gls-portals-pickreturn.test.gls.nl',
  PrintShipUrl: 'https://gls-portals-labelliteonline.test.gls.nl',
  LandingPageUrl: 'https://gls-portals-landingspage.test.gls.nl',
  ParcelController: 'api/tracktrace/v1',
  ParcelInfoController: 'api/tracktrace/v1/parcelinfo',
  PilotController: 'api/pilot/v1',
  AksUrl: 'https://azakstst.d01.gls-netherlands.com',
  AplApi: 'api/apl/v1',
  ParcelShopApi: 'api/parcelshop/v1'
};
