<div class="property">
  <div class="spotlight-gray">{{ label | translate }}</div>
  <ng-container *ngIf="isGpno">
    <div class="spotlight-black">
      <a
        [href]="getInternationalLink(property.value)"
        target="_blank"
        rel="noopener"
        >{{ property.value }}
      </a>
    </div>
  </ng-container>
  <ng-container *ngIf="!isGpno">
    <div class="spotlight-black">{{ property.value }}</div>
  </ng-container>
</div>
