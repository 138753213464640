import { CalculateRouteCommandOutput } from '@aws-sdk/client-location';
import * as maplibregl from 'maplibre-gl';
import { MapIconType } from '../../models/aws-maps.model';
import { AwsMapsService } from '../../services/aws-maps.service';
import { Component, HostListener } from '@angular/core';

@Component({
  template: '',
})
export abstract class AwsMapBaseComponentComponent {
  private map?: maplibregl.Map;
  private currentRoutes?: number[][];
  private destination?: maplibregl.LngLat;

  constructor(protected readonly awsMapsService: AwsMapsService) {}

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.reinitializeMap();
  }

  protected async createMap(): Promise<maplibregl.Map> {
    this.map = await this.awsMapsService.createMap({
      container: 'map',
      center: [0, 0],
      zoom: 0,
      scrollZoom: true,
      dragPan: true,
    });

    return this.map;
  }

  protected async drawTrackAndTraceDirections(
    trackingDirections: CalculateRouteCommandOutput
  ): Promise<void> {
    await this.drawDirections(trackingDirections, MapIconType.TrackAndTrace);
  }

  protected async drawParcelShopDirections(
    trackingDirections: CalculateRouteCommandOutput
  ): Promise<void> {
    await this.drawDirections(trackingDirections, MapIconType.ParcelShop);
  }

  protected async drawParcelShopLocation(
    lattitude: number,
    longitude: number
  ): Promise<void> {
    const map = await this.createMap();
    this.destination = new maplibregl.LngLat(longitude, lattitude);

    this.addDestinationMarker(map, this.destination, MapIconType.ParcelShop);
    this.resizeMap(map);
  }

  protected async drawAplLocation(
    lattitude: number,
    longitude: number
  ): Promise<void> {
    const map = await this.createMap();
    this.destination = new maplibregl.LngLat(longitude, lattitude);

    this.addDestinationMarker(map, this.destination, MapIconType.Apl);
    this.resizeMap(map);
  }

  protected async drawDestination(
    longitude: number,
    lattitude: number
  ): Promise<void> {
    const map = await this.createMap();
    this.destination = new maplibregl.LngLat(longitude, lattitude);

    this.addDestinationMarker(map, this.destination, MapIconType.TrackAndTrace);
    this.resizeMap(map);
  }

  private async drawDirections(
    trackingDirections: CalculateRouteCommandOutput,
    iconType: MapIconType
  ): Promise<void> {
    const map = await this.createMap();

    if (!trackingDirections.Legs) {
      return;
    }

    const leg = trackingDirections.Legs[0];
    this.currentRoutes = leg.Geometry?.LineString;

    if (!leg.StartPosition || !leg.EndPosition) {
      return;
    }

    this.drawRoute(map, this.currentRoutes);
    this.addOriginMarker(
      map,
      [leg.StartPosition[0], leg.StartPosition[1]],
      iconType
    );
    this.addDestinationMarker(
      map,
      [leg.EndPosition[0], leg.EndPosition[1]],
      iconType
    );
    this.resizeMap(map);
  }

  private getIconClassPrefix = (iconType: MapIconType): string => {
    switch (iconType) {
      case MapIconType.Apl:
        return 'apl';
      case MapIconType.ParcelShop:
        return 'parcel-shop';
      case MapIconType.TrackAndTrace:
        return 'tt';
      default:
        return 'tt';
    }
  };

  private drawRoute(map: maplibregl.Map, routes: number[][] | undefined): void {
    map.on('load', () => {
      map.addSource('route_sample', {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: routes,
          },
        },
      });
      map.addLayer({
        id: 'route_sample',
        type: 'line',
        source: 'route_sample',
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': '#0094FF',
          'line-width': 6,
          'line-opacity': 0.5,
        },
      });
    });
  }

  private setRouteBounds(
    map: maplibregl.Map,
    routes: number[][] | undefined
  ): void {
    if (!routes) {
      return;
    }

    const routeBounds = routes.reduce((bounds, coord) => {
      return bounds.extend(new maplibregl.LngLat(coord[0], coord[1]));
    }, new maplibregl.LngLatBounds(routes[0], routes[0]));

    map.fitBounds(routeBounds, { padding: 50, animate: false });
  }

  private addOriginMarker(
    map: maplibregl.Map,
    position: maplibregl.LngLatLike,
    iconType: MapIconType
  ): void {
    const className = `${this.getIconClassPrefix(iconType)}-origin-marker`;

    if (iconType === MapIconType.TrackAndTrace) {
      this.addMarker(map, position, className, false);

      return;
    }

    this.addMarker(map, position, className);
  }

  private addDestinationMarker(
    map: maplibregl.Map,
    position: maplibregl.LngLatLike,
    iconType: MapIconType
  ): void {
    this.addMarker(
      map,
      position,
      `${this.getIconClassPrefix(iconType)}-destination-marker`
    );
  }

  private addMarker(
    map: maplibregl.Map,
    position: maplibregl.LngLatLike,
    elementClassName: string,
    hasOffset = true
  ): void {
    const element = document.createElement('div');
    element.classList.add('marker', elementClassName);

    const offset: maplibregl.PointLike = hasOffset ? [0, -20] : [0, 0];
    new maplibregl.Marker(element)
      .setOffset(offset)
      .setLngLat(position)
      .addTo(map);
  }

  protected reinitializeMap(): void {
    if (!this.map) {
      return;
    }

    this.resizeMap(this.map);
  }

  // We need to resize the map since it's in a hidden overlay and needs to adjust to the stylesheet change
  private resizeMap(map: maplibregl.Map): void {
    window.setTimeout(() => {
      map.resize();

      if (this.currentRoutes) {
        this.setRouteBounds(map, this.currentRoutes);
      } else if (this.destination) {
        map.jumpTo({ center: this.destination, zoom: 14 });
      }
    }, 0);
  }
}
