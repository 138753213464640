import { ChangeDetectionStrategy, Component } from '@angular/core';
import { formatDate } from '@angular/common';
import { BaseTrackAndTraceComponent } from '../base-track-and-trace.component';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { getLocale } from '../../../../services/spotlight.utils';
import { isToday } from 'date-fns/isToday';

@Component({
  selector: 'app-delivery-date',
  templateUrl: './delivery-date.component.html',
  styleUrl: './delivery-date.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveryDateComponent extends BaseTrackAndTraceComponent {
  get deliveryDate(): string | undefined {
    return this.renderFullDate(this.parcel()?.deliveryStatus?.etaTimestamp);
  }

  get earliestDeliveryTime(): string | undefined {
    return this.renderTime(this.parcel()?.deliveryStatus?.etaTimestampMin);
  }

  get latestDeliveryTime(): string | undefined {
    return this.renderTime(this.parcel()?.deliveryStatus?.etaTimestampMax);
  }

  get etaTranslateId(): string {
    return this.parcel()?.deliveryStatus?.etaTimestampMin ? 'Eta.expectedTime' : 'Eta.withoutTime';
  }

  constructor(store: Store, private readonly translateService: TranslateService) {
    super(store);
  }

  renderTime = (timestamp: string | null | undefined) => {
    if (!timestamp) {
      return '';
    }
    return formatDate(timestamp, 'shortTime', this.translateService.currentLang);
  };

  renderFullDate = (timestamp: string | null | undefined): string => {
    if (!timestamp) {
      return '';
    }
    const date = new Date(timestamp);
    if (isToday(date)) {
      if (this.parcel()?.deliveryStatus?.etaTimestampMin) return this.translateService.instant('Eta.today');
      return this.translateService.instant('Eta.deliveryToday');
    }
    return formatDate(date, 'd MMM', getLocale(this.translateService));
  };
}
