var __rest = this && this.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};
import { Popup } from 'maplibre-gl';
import { ACTIVE_MARKER_COLOR, COLOR_WHITE, MARKER_COLOR } from "./constants";
import { createMarker } from "./createMarker";
import { getPopupRenderFunction } from "./popupRender";
import { isCoordinates } from "./utils";
const HIDE_TIP = "amplify-tip";
export function drawUnclusteredLayer(sourceName, map, _a) {
  var {
      showMarkerPopup = false
    } = _a,
    options = __rest(_a, ["showMarkerPopup"]);
  const unclusteredLayerId = `${sourceName}-layer-unclustered-point`;
  let selectedId = null;
  function deselectPoint() {
    if (selectedId !== null) {
      map.setLayoutProperty(unclusteredLayerId, "icon-image", "inactive-marker");
      selectedId = null;
    }
  }
  const popupRender = options.popupRender ? options.popupRender : getPopupRenderFunction(unclusteredLayerId, options);
  addUnclusteredMarkerImages(map, options);
  const defaultUnclusteredPoint = {
    id: unclusteredLayerId,
    type: "symbol",
    source: sourceName,
    filter: ["!", ["has", "point_count"]],
    layout: {
      "icon-image": "inactive-marker"
    }
  };
  map.addLayer(Object.assign({}, defaultUnclusteredPoint));
  /*
   * Add css to header to hide default popup tip
   */
  if (showMarkerPopup) {
    const element = document.getElementById(HIDE_TIP);
    if (!element) {
      const style = document.createElement("style");
      style.setAttribute("id", HIDE_TIP);
      document.head.append(style);
      style.textContent = ".mapboxgl-popup-tip { display: none; }";
    }
  }
  map.on("click", function () {
    deselectPoint();
  });
  /*
   * Set active state on markers when clicked
   */
  map.on("click", unclusteredLayerId, function (e) {
    if (typeof options.onClick === "function") options.onClick(e);
    selectedId = e.features[0].id;
    map.setLayoutProperty(unclusteredLayerId, "icon-image", ["match", ["id"], selectedId, "active-marker", "inactive-marker" // default
    ]);
    // If popup option is set show a popup on click
    if (showMarkerPopup) {
      const selectedFeature = e.features[0];
      const coordinates = selectedFeature.geometry.coordinates;
      if (isCoordinates(coordinates)) {
        const popup = new Popup().setLngLat(coordinates).setHTML(popupRender(selectedFeature)).setOffset(15).addTo(map);
        popup.on("close", function () {
          if (selectedId === selectedFeature.id) deselectPoint();
        });
      }
    }
  });
  /*
   * Set cursor style to pointer when mousing over point layer
   */
  map.on("mouseover", unclusteredLayerId, function () {
    map.getCanvas().style.cursor = "pointer";
  });
  /*
   * Reset cursor style when the point layer
   */
  map.on("mouseleave", unclusteredLayerId, () => {
    map.getCanvas().style.cursor = "";
  });
  return {
    unclusteredLayerId
  };
}
/*
 * Adds marker images to the maplibre canvas to be used for rendering unclustered points
 */
function addUnclusteredMarkerImages(map, {
  selectedColor = ACTIVE_MARKER_COLOR,
  selectedBorderColor = COLOR_WHITE,
  selectedBorderWidth = 4,
  defaultBorderColor = COLOR_WHITE,
  defaultBorderWidth = 4,
  defaultColor: fillColor = MARKER_COLOR,
  markerImageElement,
  activeMarkerImageElement
}) {
  const inactiveMarker = markerImageElement || createMarker({
    fillColor: fillColor,
    strokeColor: defaultBorderColor,
    lineWidth: defaultBorderWidth
  });
  const activeMarker = activeMarkerImageElement || markerImageElement || createMarker({
    fillColor: selectedColor,
    strokeColor: selectedBorderColor,
    lineWidth: selectedBorderWidth
  });
  map.addImage("inactive-marker", inactiveMarker, {
    pixelRatio: 2
  });
  map.addImage("active-marker", activeMarker, {
    pixelRatio: 2
  });
}