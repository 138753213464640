<ng-container *ngIf="showEta">
  <div
    class="date-title spotlight-black"
    [translateParams]="{
      from: earliestDeliveryTime,
      to: latestDeliveryTime,
      date: deliveryDate
    }"
    [translate]="etaTranslateId"
  ></div>
</ng-container>
<ng-container *ngIf="!showEta">
  <app-track-and-trace-date-title></app-track-and-trace-date-title>
</ng-container>
