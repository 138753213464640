import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export const atLeastOne =
  (validator: ValidatorFn, controls: string[] | null = null) =>
  (group: FormGroup): ValidationErrors | null => {
    if (!controls) {
      controls = Object.keys(group.controls);
    }

    const hasAtLeastOne = group && group.controls && controls.some((control) => !validator(group.controls[control]));

    return hasAtLeastOne
      ? null
      : {
          atLeastOne: true,
        };
  };
