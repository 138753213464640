import { WebNotification } from '../models/common.model';
import { CreateInstructionRequestModel, InstructionResult, ParcelCollectionPoint, TimeFrame } from '../models/gls-info.model';
import { InstructionValidationRequest } from '../models/instruction-validation-request';
import { InstructionValidationResponse } from '../models/instruction-validation-response';
import { ParcelDetails } from '../models/track-and-trace.model';

export class AppStateModel {
  widget?: WidgetStateModel;
  searchError?: boolean;
  parcelIdentifier?: ParcelIdentifierModel;
  parcelDetails?: ParcelDetails;
  notification!: NotificationModel;
  consent?: string;
  isMapVisible?: boolean;
  instructionValidationRequest?: InstructionValidationRequest;
  instructionValidationResult?: InstructionValidationResponse;
  selectedCollectionPoint?: ParcelCollectionPoint;
  instruction?: CreateInstructionRequestModel;
  inInstructionFlow?: boolean;
  instructionResult?: InstructionResult;
  allowedOptions!: AllowedOptionType[];
}

export interface NotificationModel {
  visible: boolean;
  notifications: WebNotification[];
}

export interface ParcelIdentifierModel {
  parcelNumber: string;
  zipCode: string;
}

export interface WidgetStateModel {
  widgetType: WidgetType;
  isInProgress: boolean;
  isExpanded: boolean;
}

export enum AllowedOptionType {
  canDeliverWithDepositPermission = 'DeliverWithDepositPermission',
  canDeliverToCollectionPoint = 'DeliverToCollectionPoint',
  canDeliverAtOtherMoment = 'DeliverAtOtherMoment',
  canDeliverAtNeighbours = 'DeliverAtNeighbours',
  canDeliverToAlternateAddress = 'DeliverToAlternateAddress',
  canRefuseParcel = 'RefuseParcel',
}

export enum WidgetType {
  SearchParcel,
  ParcelDetails,
  LoadingSkeleton,
  InstructionValidationFailed,
  EnterZipCode,
  InstructionCollectionPoint,
  SelectOtherCollectionPoint,
  InstructionSelectDeliveryOption,
  InstructionRefuseParcel,
  InstructionDeliverOtherMoment,
  InstructionAlternateAddress,
  InstructionDepostPermission,
  InstructionConfirm,
  InstructionResult,
  AciConfirmAddress,
  AciEnterEmail,
  InstructionDeliverAtNeighbours,
  ShopLocator,
}
