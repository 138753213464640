import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseTrackAndTraceComponent } from '../base-track-and-trace.component';

@Component({
  selector: 'app-eta-stops-left',
  templateUrl: './eta-stops-left.component.html',
  styleUrl: './eta-stops-left.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EtaStopsLeftComponent extends BaseTrackAndTraceComponent {
  get showRemainingStops(): boolean {
    return this.isRealtimeTracking && !this.isDeliveredAtCollectionPoint && this.remainingStops <= 10;
  }

  get isDeliveredToCollectionPoint(): boolean {
    return this.atParcelShop || this.atApl;
  }
}
