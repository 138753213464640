import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseTrackAndTraceComponent } from '../base-track-and-trace.component';
import { ParcelInfo } from '../../../../models/track-and-trace.model';
import { expandCollapse } from '../../../../animations/expand-collapse-animation';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { formatDate } from '@angular/common';
import { getLocale } from '../../../../services/spotlight.utils';

@Component({
  selector: 'app-other-parcels-in-shipment',
  templateUrl: './other-parcels-in-shipment.component.html',
  styleUrls: ['../summary.scss', './other-parcels-in-shipment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [expandCollapse],
})
export class OtherParcelsInShipmentsComponent extends BaseTrackAndTraceComponent {
  isExpanded = false;

  get parcels(): ParcelInfo[] | undefined {
    return this.parcel()?.parcels;
  }

  get currentParcelNo(): string {
    return this.details?.parcelNo ?? '';
  }

  toggleExpansion = () => {
    this.isExpanded = !this.isExpanded;
  };

  constructor(store: Store, private translateService: TranslateService) {
    super(store);
  }

  jobDate = (parcel: ParcelInfo): string | undefined => {
    if (parcel?.jobDate) {
      const jobDate = new Date(parcel?.dateTime);
      const date = formatDate(jobDate, 'd MMMM', getLocale(this.translateService));
      const time = formatDate(jobDate, 'shortTime', getLocale(this.translateService));

      return `${date}, ${time}`;
    }
    return '';
  };

  getUrl(parcel: ParcelInfo): string {
    return `/?trackid=${parcel.parcelNo}&zipcode=${parcel.zipCode}`;
  }
}
