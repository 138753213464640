export interface ParcelProperty {
  label: string;
  value: string;
}

export enum TrackAndTraceProperty {
  Uniqueno = 'uniqueno',
  Reference = 'reference',
  Dimensions = 'dimensions',
  Weight = 'weight',
  GpNo = 'gpNo',
  JobDate = 'jobDate',
}
